import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function DeleteDeviceModal(props) {
  const [disableDeleteButton, setDisableDeleteButton] = useState(true);
  const handleHideModal = () => {
    setDisableDeleteButton(true);
    props.onHide();
  }
  const handleUserInput = (e) => {
    const pattern = /^yes$/i;
    const testValue = pattern.test(e.target.value);
    if(testValue){
      setDisableDeleteButton(false) // opposite because we're setting disabled.
    }
    if(!disableDeleteButton && !testValue){
      setDisableDeleteButton(true); // if someone types something else in or changes mind it will revert/reset.
    }
  }
  return (
    <Modal
      show={props.show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete user?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete {props.firstName} {props.lastName}?
      </Modal.Body>
      <TextField
          required
          id="outlined-required"
          label="Type yes and then click delete"
          autoComplete="off"
          onChange={handleUserInput}
        />
      <Modal.Footer>
        <Button disabled={disableDeleteButton} variant="contained" color="error" onClick={(e) => props.handleDeleteUser(e)}>Delete</Button>
        <Button onClick={handleHideModal}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}


 
import { useState, useEffect } from 'react';
import { listAccountUsers } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useLoadUserInfo(userId) {
  const [allUserAccounts, setAllUserAccounts] = useState(null);
  useEffect(() => {
    if(!localStorage.getItem("userIsLoaded")){ // ensures we don't call this hook over and over on refresh.
      const fetchData = async () =>{
        const result = await API.graphql(graphqlOperation(listAccountUsers, { userId: userId })).catch((error) => console.log("graphQL error: ", error));
        if(result.data.listAccountUsers.items.length === 0){
          localStorage.setItem("currentAccount", "NO ACCOUNT");
        }
        else {
          const userData = result.data.listAccountUsers.items[0]
          localStorage.setItem('accountId', userData.accountId)
          localStorage.setItem('userFirstName', userData.firstName);
          localStorage.setItem('userLastName', userData.lastName);
          localStorage.setItem('mainAccountId', userData.accountId);
          localStorage.setItem('userIsLoaded', true);
          localStorage.setItem('userId', userId);
          if(localStorage.getItem("proxyStatus") !== "active") {
            localStorage.setItem("currentAccount", userData.accountId);
            localStorage.setItem("currentCompanyName", userData.accountName);
          }
          setAllUserAccounts(result.data.listAccountUsers.items);
        };
      }
      userId&&fetchData();
    }
  },[userId]);
  return allUserAccounts;
}
/* eslint-disable */

// these are my customized queries separate from the autogenerated Amplify code. Separating out to prevent overwriting by Amplify.
// getUserNotes is used to query the user sensor notes from the sensor notes table. The query is by sensor. 
// It returns the most recent notes in descending date order. Since it's not anticipated that there will be too many of these notes, 
// querying by date is not needed at this time. Query by date range can be added if needed.

export const getUserNotes = /* GraphQL */ `
  query GetUserNotes(
    $senid: String!
    $limit: Int
    $nextToken: String

  ) {
    getUserNotes(senid: $senid, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        senid
        userId
        userFullName
        timestamp
        userNotes
      }
    }
  }
`;
import React, { useState } from "react";


import { v4 as uuidv4 } from 'uuid';
import { Container } from "react-bootstrap";
import NotificationUserInfo from "./NotificationUserInfo";
export default function NotificationUsersTab (props) {
  const [users, setUsers] = useState(props.assignedUsers)
  
  const handleAddUser = () => {
    const list = props.assignedUsers;
    list.push({
      "name": props.allUsers[0].firstName + " " + props.allUsers[0].lastName,
      "username": props.allUsers[0].username,
      "userId": props.allUsers[0].username,
      "contactMethod": "email",
      "contactValue": props.allUsers[0].email,
      "notificationDelayMinutes": 0,
      "notificationDelaySeconds": 0
    });
    props.handleSetAssignedUsers([...list])
  }
  
  const handleUpdateUser = (e, i) => {
    const userList = props.assignedUsers;
    const index = props.allUsers.findIndex(
      (item) => item.userId === e
    );
    userList[i] = {
      "name": props.allUsers[index].firstName + " " + props.allUsers[index].lastName,
      "username": props.allUsers[index].username,
      "userId": props.allUsers[0].username,
      "contactMethod": "email",
      "contactValue": props.allUsers[index].email
    }
    setUsers(
      [...userList]
    )
    props.handleSetAssignedUsers([...userList])
  }

  const handleUpdateContact = (e, i, contactValue) => {
    const userList = props.assignedUsers;
    userList[i].contactMethod=e;
    userList[i].contactValue=contactValue;
    props.handleSetAssignedUsers([...userList])
  }

  const handleDeleteUser = (index) => {
    const list = props.assignedUsers;
    list.splice(index, 1);
    props.handleSetAssignedUsers([...list])
  }

  const handleSetNotificationDelay = (index, notificationDelay) => {
    const userList = props.assignedUsers;
    userList[index]['notificationDelayMinutes']=notificationDelay;
    userList[index]['notificationDelaySeconds']=notificationDelay*60;
    props.handleSetAssignedUsers([...userList])
  }
  
  if(!users) {
    return(
      <button className="btn btn-primary option-buttons" onClick={() => handleAddUser()}>
        Add User
      </button>
    );
  }

  return(
    <Container fluid style={{padding:"1px", fontSize:"12px", }}>
                    {props.assignedUsers.map((user, index) =>
                      <NotificationUserInfo 
                        allUsers={props.allUsers} 
                        assignedUsers={users} 
                        userId={user.username}
                        cellPhone={user.cellPhone}
                        email={user.email} 
                        deleteUser={ handleDeleteUser }
                        updateUser={ handleUpdateUser }
                        handleUpdateContact={handleUpdateContact}
                        handleSetNotificationDelay={handleSetNotificationDelay}
                        contactMethod={user.contactMethod}
                        contactValue={user.contactValue}
                        notificationDelayMinutes={user.notificationDelayMinutes || 0}
                        notificationDelaySeconds={user.notificationDelayMinutes*60 || 0}
                        i={index}
                        key={uuidv4()}
                        />
                      )
                    }

      {props.assignedUsers.length > 0 ? <>Delay in minutes before message is sent to user<br /></> : <></> }
      <button className="btn btn-primary option-buttons" onClick={() => handleAddUser()}>
        Add User
      </button>
    </Container>
  )
}
import React, { useState } from "react";
import { Button, Container, ButtonToolbar } from 'react-bootstrap'
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DeleteNetwork } from '../Database.jsx'

import AddDeviceModal from "./AddDeviceModal";
import ManageTransferDeviceModal from "./ManageTransferDeviceModal";
import ManageAddNetworkModal from "./ManageAddNetworkModal";
// import DeleteNetworkModal from "./DeleteNetworkModal.jsx";

import "./Manage.css";
import ManageDevices from "./ManageDevices.jsx";

export default function Manage(props) {
  const [selectedNetwork, setSelectedNetwork] = useState( props.allNetworks[0] || [] );
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleNetworkSelect = (event) => {
    // console.log("Manage.jsx", event.target.value);
    setSelectedNetwork(props.allNetworks[event.target.value]);
  }

  const handleDeleteNetwork = () => {
    if(window.confirm("Are you sure you want to delete " + selectedNetwork.name + " network? THIS WILL REMOVE THE NETWORK AND ALL ASSIGNED SENSORS!!!")){
      DeleteNetwork(selectedNetwork.id, localStorage.getItem("currentAccount"))
      .then(
        (result) => {
          console.log("DeleteNetwork", result);
          setSuccess(result);
          alert("Network deleted successfully!");
          // window.location.reload();
        },
        (error) => {
          setError(error);
          alert("Error deleting network: ", error);
          // window.location.reload();
        }
      );
    } 
  }

  const handleSelectDevice = (event) => {
    setSelectedDevices(event);
  }
  
  if(props.allNetworks.length === 0) {
    return(<ManageAddNetworkModal buttonText="New Network" id="addNetwork" accountId={ localStorage.getItem("currentAccount") } />);
  }

  if(!props.allSensors || !props.allGateways) { return <></>}
    
  // get the networks dropdown filter -> todo put in componentDidMount to prevent re-renders over and over
  const networks = props.allNetworks.map((element, index) => (
    <option key={index} value={index}>{element.name}</option>
  )); 


  return(
    <>
      <ButtonToolbar aria-label="Toolbar with button groups" >
        <select id={ "network-select" } title={ "network" } onChange={(e) => handleNetworkSelect(e) }>
          {networks}
        </select>    
        <ManageAddNetworkModal buttonText="New Network" id="addNetwork" accountId={ localStorage.getItem("currentAccount") } />
        <AddDeviceModal
          id={ "addDevice" } 
          accountId={ localStorage.getItem("currentAccount") }
          allNetworks={ props.allNetworks }
          buttonText="Add sensor"
          selectedNetwork={ selectedNetwork.id }
          selectedNetworkName={ selectedNetwork.name }
        />
        {/*TODO: add an error if no devices are selected. */}
        {/* network filter dropdown, filters the view by network */}
        <ManageTransferDeviceModal 
          buttonText="Transfer Device(s)" 
          id="transferDevices"
          devices={ selectedDevices } 
          allGateways={ props.allGateways } 
          allNetworks={ props.allNetworks }
          selectedNetwork={ selectedNetwork.id }
          selectedNetworkName={ selectedNetwork.name }
        />
        {props.userId.includes("@smartbarn.io") ? <Button variant="danger" disabled={!localStorage.getItem("userId").includes("@smartbarn.io")} onClick={() => handleDeleteNetwork()}>Delete Network</Button> : <></>}
      </ButtonToolbar>
      <Container style={{paddingBottom:50, paddingLeft: 0, paddingRight: 0}} >
        <ManageDevices 
          selectedNetwork={selectedNetwork.id} 
          allDevices = { props.allGateways.concat(props.allSensors) } 
          handleSelect={(e) => handleSelectDevice(e)} 
          />
      </Container>
    </>
  )
}
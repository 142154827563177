import React, { useState, useEffect, useRef } from 'react';
import { IOTData } from "../services.js";
import Button from "react-bootstrap/Button";

const iotdata = IOTData();

export default function RangeExtenderEditConfig (props) {
  const [deviceName, setDeviceName] = useState(props.name);
  const [deviceHeartbeat, setDeviceHeartbeat] = useState(props.heartbeat || 10);
  // const [scaleValue1, setScaleValue1] = useState(props.scaleValue1);
  // const [scaleValue2, setScaleValue2] = useState(props.scaleValue2);
  const [pendingChange, setPendingChange] = useState(props.pendingChange);
  const didMount = useRef(false);

  useEffect(() => {
    // Return early, if this is the first render:
    if ( !didMount.current ) {
      didMount.current = true;
    } 
    else {
      setPendingChange(true);
    }
    // Paste code to be executed on subsequent renders:
  }, [deviceHeartbeat]);

  // const handleReform = () => {
  //   console.log("Sending reform command for range extender: " + props.id);
  //   // Send reform message to sensor topic
  //   // const sensorList = props.allSensors.map((sensor) => { 
  //   //   // return sensor.id && (sensor.networkId === props.networkId)
  //   //     if(sensor.networkId === props.networkId) {return (sensor.id) }
  //   // });
  //   // const filteredList = sensorList.filter(sensor => sensor !== undefined)
  //   // console.log(filteredList);

  //   const params = {
  //     topic: "cmd/sensor/" + props.deviceType + "/monnit/reform",
  //     payload: JSON.stringify({ deviceId: props.id, reform: true }),
  //     qos: 0,
  //   };

  //   iotdata.publish(params, function (err, data) {
  //     if (err) console.log(err, err.stack);
  //     else {
  //       console.log("RangeExtenderEditConfig", data);
  //       alert("Successfully updated sensor list.");
  //     }
  //   });
  // }

  const handleSaveSettings = (event) => {
    event.preventDefault();
    const json_string = {
      account: props.account,
      accountId: props.account,
      deviceId: props.id,
      deviceType: props.deviceType,
      heartbeat: deviceHeartbeat,
      heartbeatAssessments: deviceHeartbeat,
      heartbeatAware: deviceHeartbeat,
      id: props.id,
      manufacturer: props.manufacturer || "monnit",
      maxThresh: props.maxThresh,
      minThresh: props.minThresh,
      message: props.message,
      name: deviceName,
      networkId: props.networkId,
      notificationId: props.notificationId,
      notificationStatus:props.notificationStatus || "disarmed",
      pendingChange: pendingChange,
      scaleValue1: props.scaleValue1,
      scaleValue2: props.scaleValue2,
      sensorCode: props.sensorCode,
      version: props.version,
      cmdStatus: "REQUEST",
      cmdTimeStamp: new Date()
    }

    const params = {
      topic: "cmd/sensor/" + props.deviceType + "/monnit/settings"  ,
      payload: JSON.stringify(json_string),
      qos: 0
    };
    iotdata.publish(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        alert("Problem saving settings!");
      }
      else {
        console.log("response", data);
        alert("Settings Saved!");
        window.location.reload();
      }
    });
  }
  
  return (
    <form>
      <div className="form-group">
        <label className="input-label">Name</label>
        <input name= "deviceName" type="text" className="form-control" value={deviceName} onChange={(e) => setDeviceName(e.target.value)}></input>
        <label className="input-label">Heartbeat</label>
        <input name= "deviceHeartbeat" type="number" className="form-control" min="1" value={deviceHeartbeat} onChange={(e) => setDeviceHeartbeat(e.target.value)} ></input>
        <br />
        <Button type="submit" value="Save Settings" onClick={(e) => handleSaveSettings(e)}>Save</Button>
        {/* <Button onClick={() => handleReform()}>
            <SendIcon htmlColor='black' />Load sensors</Button> */}
      </div>
    </form>
  );
}
import { LogNotifications } from '../../Database';
import { IOTData } from "../../services.js";

export default function UpdateNotificationStatus(props){
  const notificationStatus = props.notificationStatus;
  const notificationTopic = "notifications/sensor/" + props.notificationStatus;
  const notificationMessage = 'User ' + notificationStatus + ' notification';
  const notificationResult = 'user ' + notificationStatus;
  const notificationId = props.notificationId;
  const deviceId = props.deviceId;
  const userId = localStorage.getItem('userId');

  const notificationData = {
    notificationId: notificationId,
    timestamp: Math.floor((new Date().valueOf()/1000)),
    contactAddress: userId,
    contactMethod: 'App',
    deviceId: deviceId,
    message: notificationMessage,
    notificationStatus: notificationStatus, 
    userFirstLastName: localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName'),
    result: notificationResult,
  }
  console.log("notification data ", notificationData)
  const params = {
    topic: notificationTopic,
    payload: JSON.stringify(notificationData),
    qos: 0
  };

  LogNotifications(notificationData)
  .then(
    IOTData().publish(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        alert("Problem saving settings!");
      }
      else {
        console.log("response", data);
        alert("Notification " + notificationStatus + "!");
        // window.location.reload();
      }
    })
  )
}
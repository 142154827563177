import React, { useState, useEffect, useRef } from 'react';
import { IOTData } from "../../services.js";
import Button from "react-bootstrap/Button";

const iotdata = IOTData();

export default function BatteryBackupEdit (props) {
  const [deviceName, setDeviceName] = useState(props.name);
  const [pendingChange, setPendingChange] = useState(props.pendingChange);
  const didMount = useRef(false);
  const deviceType = "BatteryBackup";

  useEffect(() => {
    // Return early, if this is the first render:
    if ( !didMount.current ) {
      didMount.current = true;
    } 
    else {
      setPendingChange(true);
    }
    // Paste code to be executed on subsequent renders:
  }, []);

  const handleSaveSettings = (event) => {
    event.preventDefault();
    const json_string = {
      deviceId: props.id,
      deviceType: deviceType,
      manufacturer: "SmartBarn",
      name: deviceName,
      pendingChange: pendingChange,
      version: deviceType,
      cmdStatus: "REQUEST",
      cmdTimeStamp: new Date()
    }

    const params = {
      topic: "cmd/sensor/" + deviceType + "/monnit/settings",
      payload: JSON.stringify(json_string),
      qos: 0
    };
    iotdata.publish(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        alert("Problem saving settings!");
      }
      else {
        console.log("response", data);
        alert("Settings Saved!");
        window.location.reload();
      }
    });
  }
  
  return (
    <form>
      <div className="form-group">
        <label className="input-label">Name</label>
        <input name= "deviceName" type="text" className="form-control" value={deviceName} onChange={(e) => setDeviceName(e.target.value)}></input>
        <Button type="submit" value="Save Settings" onClick={(e) => handleSaveSettings(e)}>Save</Button>
      </div>
    </form>
  );
}
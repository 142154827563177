import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { ListNetworksByAccountId } from '../Database.jsx';
import AccountSelect from './AccountSelect.jsx';
import { IOTData } from "../services.js";

const iotdata = IOTData();

export default function ManageTransferDeviceModal(props) {
  const [show, setShow] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  
  const [account, setAccount] = useState(localStorage.getItem("currentAccount"));

  const handleSetAccount = (e) => setAccount(e.target.value);
  
  const [allNetworks, setAllNetworks] = useState([]);
  const [network, setNetwork] = useState("");

  useEffect(() => {
    // console.log("NETWORK LIST");
    ListNetworksByAccountId(account)
    .then(
      (result) => {
        setNetwork(result.data.listNetworksByAccount.items[0].id);
        setAllNetworks(result.data.listNetworksByAccount.items);
        setIsLoaded(true);
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  },[account]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if(network === "")
    setValidated(true);
    let networkId = network;
      //update settings for each device
    const iot_data = {
      // assignedUsers: assignedUsers,
      devices: props.devices,
      accountId: account,
      networkId: networkId,
      networkName: allNetworks.filter(networkList => networkList.id === network)[0].name
    };
    // console.log("IOT DATA: " + JSON.stringify(iot_data));
    var params = {
      topic: "cmd/devices/transferDevices/monnit",
      payload: JSON.stringify(iot_data),
      qos: 0,
    };
    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        // console.log("response", data);
        // console.log("Sent data ", iot_data);
        alert("Devices transferred!");
        window.location.reload();
      }
    });
    handleClose();
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
      <SyncAltIcon htmlColor="white" style={{ marginRight: "5px" }} />
        {props.buttonText}
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{props.buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form id={props.id} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formParentAccount">
            <Form.Label>Parent Account</Form.Label>
            <Form.Select aria-label="select parent account" disabled >
              <option value="c4bd7b20-3bab-11ea-a975-0523f21e6d1f">Smart Barn</option>
              <option value="a9af7c70-0e1e-11eb-8cb6-eb022084b0e8">Organic Valley</option>
            </Form.Select>
          </Form.Group>
          <AccountSelect setAccount={ handleSetAccount } />      
          <Form.Group className="mb-3" controlId="formNetwork">
            <Form.Label>Network</Form.Label>
            <Form.Select aria-label="select network" onChange={ (e) => setNetwork(e.target.value)} >
              {allNetworks.map(element => (<option key={element.id} value={element.id}>{element.name}</option>))}
            </Form.Select>
            {/* { props.devices } */}
          </Form.Group>
        </Form>
        </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form={props.id} onClick={() => handleSubmit}>Transfer</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


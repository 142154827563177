import React, { useState, useEffect } from "react";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { endOfDay } from 'date-fns'


export default function DatePicker(props) {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate)

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate)
  },[props.startDate, props.endDate]);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={ "Start date"}
          disableFuture={ true }
          maxDate={ props.endDate }
          value={ startDate }
          onChange={ (newValue) => { setStartDate(newValue) }}
          onAccept={ (e) => { props.handleStartDateChange(e.getTime()); }}
          renderInput={(params) => <TextField {...params} size={ "small" } style={{width:"120px", paddingRight:"2px"}} />}
        />

        <MobileDatePicker
          label={ "End date"}
          disableFuture={ true }
          // minDate={ startDate }
          minDate={props.startDate}
          // maxDate={new Date()}
          value={endDate}
          onChange={(newValue) => {
            setEndDate(endOfDay(newValue.getTime()));
          }}
          onAccept={(e) => {
            props.handleEndDateChange(e);
          }}
          renderInput={(params) => <TextField {...params} size={ "small" } style={{width:"120px"}} />}
        />
    </LocalizationProvider>
  );
}
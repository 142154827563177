export default function getCellProvider(data){
  if(!data){ return 'N/A' }
  const cellData = data;
  const cellPhone  = data.cellPhone;
  const MNC = cellData.mobile_network_code;
  console.log("CELL DATA ", cellData);
  switch(MNC){
    case "160":
      return cellPhone + '@tmomail.net';
    case "910":
      return cellPhone + '@msg.fi.google.com';
    case "020":
      return cellPhone + '@vtext.com';
    case "030":
      return cellPhone + '@txt.att.net';
    case "220":
      return cellPhone + "@email.uscc.net";
    default:
      return 'N/A'
  }
}
import React from 'react'
import './Charts.css'

// date-fns
import 'chartjs-adapter-date-fns';
import endOfDay from 'date-fns/endOfDay'
import findHiLowTemperatureByDay from "../../../Utilities/findHiLowTemperatureByDay";
import findHiLowHumidityByDay from '../../../Utilities/findHiLowHumidityByDay';
import convertCToF from '../../../Utilities/convertCToF';

// Charts JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
  TimeScale,
  Decimation
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import Container from 'react-bootstrap/Container';
import { format } from 'date-fns';

// register chartsjs functions and plugins
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
  annotationPlugin,
  TimeScale,
  Decimation
);

export default function HumidityChart(props) {
  const sensorDataMessages = [...props.messages];

  const temperatureDataMessages = sensorDataMessages.map(x => (
    {x: new Date(x.timestamp*1000), y: props.scaleValue1.startsWith('C') ? x.data.toFixed(1) : convertCToF(x.data.toFixed(1))}
    )
  );

  const data = {
    datasets: [
      {
        datalabels: {
          labels: {
            title: null
          }
        },
        id: 1,
        label: 'Temperature',
        type: 'line',
        backgroundColor: 'rgb(116, 170, 67)',
        borderColor: 'rgb(116, 170, 67)',
        data: temperatureDataMessages,
        pointRadius: 0,
        borderWidth: 2,
        animation: false, // set here and not in options? weird...
      },
    ]
  };

  const humidityDataPoints = sensorDataMessages.map(data => ({x: new Date(data.timestamp*1000), y: (data.dataValues !== null)&&JSON.parse(data.dataValues).Humidity }));
  data.datasets.push(
    {
      datalabels: {
        labels: {
          title: null
        }
      },
      id: 2,
      label: 'Humidity',
      type: 'line',
      backgroundColor: 'blue',
      borderColor: 'blue',
      data: humidityDataPoints,
      pointRadius: 0,
      borderWidth: 2,
      animation: false, // set here and not in options? weird...
      // yAxisID: 'y1'
    }
  );
  const formattedHiLowByDay = findHiLowTemperatureByDay(sensorDataMessages, props.startDate, props.endDate, props.scaleValue1);
  const formattedHumidityByDay = findHiLowHumidityByDay(sensorDataMessages, props.startDate, props.endDate, 'Humidity');
  var hiLowValues = [];
  formattedHiLowByDay.forEach((x, i) => {
    hiLowValues.push(x);
    hiLowValues.push(formattedHumidityByDay[i]);
  });
  const yMin = props.scaleValue1.startsWith('C') ? props.maxThresh : convertCToF(props.maxThresh);
  const yMax = props.scaleValue1.startsWith('C') ? props.minThresh : convertCToF(props.minThresh);

  const options = {
    animation: false,
    parsing: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // decimation: decimation,
      tooltip: {
        enabled: true,
      },
      interaction: {
        mode: 'nearest'
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: yMin ,
            yMax: yMin,
            borderColor: 'red',
            borderWidth: 2,
          },
          line2: {
            type: 'line',
            yMin: yMax,
            yMax: yMax,
            borderColor: 'red',
            borderWidth: 2,
          }
        }
      }
    },
    scales: {
      x: {
        adjustScaleRange: false,
        min: props.startDate,
        max: endOfDay(props.endDate),
        type: 'time',
        time: { unit: 'day' }
      },
      x2: {
        labels: hiLowValues,
        position: "top",
        offset: true,
        display: true,
        ticks: {
          font: {
            weight: '500',
            size: '12'
          },
          color: 'green',
        },
        grid: {
          display: false
        }
      },

      y: {
        suggestedMax: 100
      }
    }
  }


  return(
    <Container fluid className='sensor-charts' >
      <Line data = {
          data
        } 
        options = {
            options 
        }
        key={ props.id }
        />
    </Container>
  );
}
import React from 'react';
import Button from 'react-bootstrap/Button';
import Tooltip from '@mui/material/Tooltip';


export default function NotificationToolTip() {
  const toolTipText = {
    name: "<Name>: Display the name of the sensor or gateway that triggered the notification.",
    id: "<ID>: Display the ID of the sensor or gateway that triggered the notification.",
    reading: "<Reading>: Display the device reading that triggered the notification.",
    network: "<Network>: The network / group for the sensor."
  };

  return (
    <>
      <Tooltip title={toolTipText.id + " " + toolTipText.name + " " + toolTipText.reading }>
        <Button size="sm">
          Available Data
        </Button>
      </Tooltip>
    </>
  );
}

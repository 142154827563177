export default function DecodeSensorId(senId) {
  let chars = ["A", "B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
  var num = 0
  while (senId != 0) {
    num = parseInt(num*10) + parseInt(senId%10);
    senId = parseInt(senId /= 10);
  }
  var code = []
  while (num > 0) {
    code.push( chars[parseInt(num%26)]);
    num = parseInt(num /= 26);
  }
  while (code.length < 4) {
    code.push("A");
  }

  if (code.length == 5) {
    code = code.slice(0, 4);
  }
  code.reverse();
  return "IM" + code.join('');
}
import React, { useState } from "react";
import SensorNotification from "./SensorNotification";
import GatewayNotification from "./GatewayNotification";
import { Accordion, Container, ButtonToolbar } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import { v4 as uuidv4 } from 'uuid';
/* GraphQL queries and mutations */
import { DeleteNotification } from "../Database.jsx";
import AddNewModal from './AddNewModal.jsx';
import "./NotificationsPage.css";
import SensorInactivityNotification from "./SensorInactivityNotification";
import TemperatureSensorNotification from "./TemperatureSensorNotification";
import TimedVoltageNotification from "./TimedVoltageNotification";
import TimedDryContactNotification from "./TimedDryContactNotification";
import TimedOpenClosedNotification from "./TimedOpenClosedNotification";
import GatewayPowerNotification from "./GatewayPowerNotification/GatewayPowerNotification";

export default function NotificationsPage (props) {
  const [allNotifications, setAllNotifications] = useState(props.allNotifications);
  // this is the selected network ID.
  const [selectedNetwork, setSelectedNetwork] = useState(localStorage.getItem("selectedNetwork") || "All Networks");

  const handleDeleteNotification = (notificationId) => {
    console.log("handleDelete" + notificationId);
    // var notifications = this.props.allNotifications;
    DeleteNotification({id: notificationId})
    .then((result) => {
      alert("Notification deleted!");
      window.location.reload();
    });
    // // TODO: set each sensor that was on the list to disarmed
  }

  const handleNetworkSelect = (event) => {
      setSelectedNetwork(event.target.value);
      localStorage.setItem("selectedNetwork", event.target.value);
    }

  if(!props.allNotifications) {
    return (
      <Container fluid style={{ maxWidth: "960px", minHeight:"500px" }}>
        <Container className="justify-content-center" style={{paddingTop:'50px', width:"100px"}}><Spinner animation="border" /></Container>
      </Container>
    );
  }

  allNotifications.sort(function(a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
  
  var notificationList = [];
  var allSensors = props.allSensors;
  var allGateways = props.allGateways;
  var notifications = allNotifications;

  if(selectedNetwork !== "All Networks") { notifications = allNotifications.filter(item => selectedNetwork.includes(item.networkId)); }
  
  const selectedNetworkObject = props.allNetworks.filter((network) => network.id === selectedNetwork);
  const selectedNetworkName = selectedNetworkObject[0] ? selectedNetworkObject[0].name : null;


  notifications.forEach(element => {
    // filter out only the sensors that are available in the matching network and match the notification type.
    const sensorList = allSensors.filter(sensor => {
      // TODO: bandaid for voltmeter types
      // const version =  (sensor.version !== 'Voltage_Meter_500' && sensor.version !== 'AC_Voltage_500') ? sensor.networkId === element.networkId && element.deviceType === sensor.version : sensor.networkId === element.networkId;
      const version = sensor.version === 'AC_Voltage_500' ? 'Voltage_Meter_500' : sensor.version.includes("Pulse") ? "Pulse_Counter_1_Input"  : sensor.version;
      return sensor.networkId === element.networkId && element.deviceType === version;
    }); // TODO: change version to deviceType
    const gatewayList = allGateways.filter(gateway => gateway.networkId === element.networkId); // TODO: change version to deviceType

    notificationList.push(
      element.notificationType === "gatewayInactivity" ?
        <GatewayNotification 
          key={uuidv4()}
          notificationId={element.id} 
          allGateways = { gatewayList } 
          allSensors={sensorList}
          allUsers={props.allUsers} 
          onDeleteNotification={handleDeleteNotification}
          selectedNetwork={ selectedNetwork }
          {...element}
        /> : element.notificationType === "BatteryBackup" ?
        <GatewayPowerNotification 
          key={uuidv4()}
          notificationId={element.id} 
          allGateways = { gatewayList } 
          allSensors={sensorList}
          allUsers={props.allUsers} 
          onDeleteNotification={handleDeleteNotification}
          selectedNetwork={ selectedNetwork }
          {...element}
        /> : element.notificationType === "timedVoltage" ?
          <TimedVoltageNotification 
            key={uuidv4()}
            notificationId={element.id} 
            allGateways = { gatewayList } 
            allSensors={ sensorList }
            allUsers={props.allUsers}
            assignedUsers={element.assignedUsers} 
            onDeleteNotification={handleDeleteNotification}
            scaleValue1={ props.scaleValue1 }
            scaleValue2={ props.scaleValue2 }
            selectedNetwork={ selectedNetwork }
            {...element}
          /> : element.notificationType === "timedDryContact" ?
          <TimedDryContactNotification 
              key={uuidv4()}
              notificationId={element.id} 
              allGateways = { gatewayList } 
              allSensors={sensorList}
              allUsers={props.allUsers}
              assignedUsers={element.assignedUsers} 
              onDeleteNotification={handleDeleteNotification}
              scaleValue1={ props.scaleValue1 }
              scaleValue2={ props.scaleValue2 }
              selectedNetwork={ selectedNetwork }
              {...element}
            /> : element.notificationType === "timedOpenClosed" ?
            <TimedOpenClosedNotification 
                key={uuidv4()}
                notificationId={element.id} 
                allGateways = { gatewayList } 
                allSensors={sensorList}
                allUsers={props.allUsers}
                assignedUsers={element.assignedUsers} 
                onDeleteNotification={handleDeleteNotification}
                scaleValue1={ props.scaleValue1 }
                scaleValue2={ props.scaleValue2 }
                selectedNetwork={ selectedNetwork }
                {...element}
            /> : element.notificationType === "sensorInactivity" ?
              <SensorInactivityNotification 
                key={uuidv4()}
                notificationId={ element.id } 
                allSensors={ props.allSensors }
                allUsers={props.allUsers}
                assignedUsers={element.assignedUsers} 
                onDeleteNotification={handleDeleteNotification}
                scaleValue1={ props.scaleValue1 }
                scaleValue2={ props.scaleValue2 }
                selectedNetwork={ selectedNetwork }
                {...element}
              /> : element.notificationType === "Temperature" ?
                <TemperatureSensorNotification 
                  key={uuidv4()}
                  notificationId={element.id} 
                  allGateways = { gatewayList } 
                  allSensors={sensorList}
                  allUsers={props.allUsers}
                  assignedUsers={element.assignedUsers} 
                  onDeleteNotification={handleDeleteNotification}
                  scaleValue1={ props.scaleValue1 }
                  scaleValue2={ props.scaleValue2 }
                  selectedNetwork={ selectedNetwork }
                  {...element}
                /> :
            <SensorNotification 
              key={uuidv4()}
              notificationId={element.id} 
              allGateways = { gatewayList } 
              allSensors={ sensorList }
              allUsers={props.allUsers}
              assignedUsers={element.assignedUsers} 
              onDeleteNotification={handleDeleteNotification}
              scaleValue1={ props.scaleValue1 }
              scaleValue2={ props.scaleValue2 }
              selectedNetwork={ selectedNetwork }
              {...element}
            />
    );
  });

  return (
    <Container fluid>
      <ButtonToolbar aria-label="Toolbar with button groups" >
        <select key={uuidv4()} value={ selectedNetwork }id="network" title={ "network" } onChange={(e) => handleNetworkSelect(e) } >
          <option key={uuidv4()} value="All Networks"  as="button" >All Networks</option>
          {props.allNetworks.map(element => (<option key={uuidv4()} value={element.id}>{element.name}</option>))}
        </select> 
        <AddNewModal 
          allNetworks={props.allNetworks} 
          allNotifications={ allNotifications }
          selectedNetwork={ selectedNetwork }
          selectedNetworkName={ selectedNetworkName }
          setAllNotifications={ setAllNotifications }
          />
      </ButtonToolbar>
      {
        props.allNetworks === null || props.allSensors === null ? null : 
        <Accordion>
          {notificationList}
        </Accordion>
      }
    </Container>
  );
}
import React from "react";
import { Button, Row, Form, Modal, Col } from 'react-bootstrap'

export default function CreateNewAccountForm({ handleSubmit, id }) {
  return (
    <Form id={id} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formParentAccount">
        <Form.Label>Parent Account</Form.Label>
        <Form.Select aria-label="select parent account">
          <option value="c4bd7b20-3bab-11ea-a975-0523f21e6d1f">Smart Barn</option>
          <option value="a9af7c70-0e1e-11eb-8cb6-eb022084b0e8">Organic Valley</option>
        </Form.Select>      
      </Form.Group>
      <Form.Group className="mb-3" controlId="formCompany">
        <Form.Label>Account / Company Name</Form.Label>
        <Form.Control type="text" placeholder="Company Name" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="networkName">
        <Form.Label>Farm / site / network name</Form.Label>
        <Form.Control type="text" placeholder="Farm 1" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formAddress">
        <Form.Label>Address</Form.Label>
        <Form.Control type="text" placeholder="Address" />
      </Form.Group>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-3" controlId="formCity">
          <Form.Label>City</Form.Label>
          <Form.Control type="text" placeholder="City" />
          </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="formState">
          <Form.Label>State</Form.Label>
          <Form.Control type="text" placeholder="State" />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="formZip">
          <Form.Label>Zip Code</Form.Label>
          <Form.Control type="text" placeholder="Zip" />
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="formCountry">
        <Form.Label>Country</Form.Label>
        <Form.Control type="text" defaultValue="USA" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formTimezone">
        <Form.Select defaultValue={"GMT-05:00 America/New_York"} aria-label="select time zone">
          <option value="GMT-04:00 ">(GMT-04:00) Atlantic Time</option>
          <option value="GMT-05:00 America/New_York">(GMT-05:00) Eastern Time</option>
          <option value="GMT-06:00 America/Chicago">(GMT-06:00) Central Time</option>
          <option value="GMT-07:00 ">(GMT-07:00) Mountain Time</option>
          <option value="GMT-08:00">(GMT-08:00) Pacific Time</option>
          <option value="GMT-10:00">(GMT-10:00) Hawaii Time</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formNotes">
        <Form.Label>Notes</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
    </Form>
  );
}
import { Auth, API, Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports.js';
import React, {useState} from 'react';
import { Container, Row, Modal, Button, Form, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { CreateNewAccountUser } from '../Database.jsx';
Amplify.configure(awsmobile);

/**
 * I would like this to be generalized and use children props but having trouble getting that to work.
 * @param {*} props 
 * @returns 
 */

export default function CreateNewUser(props, form) {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    let formdata = event.target;
    let userId = uuidv4();
    const emailAddress = formdata.formEmail.value;
    const username = formdata.formUsername.value.toLowerCase();

    const accountData = {
      "username": username,
      "email": emailAddress,
      "userId": username,
      "userUUID": userId,
      // "userKey": "CognitoIdentityServiceProvider.39jl7hveb7daa2snggu1ce2723." + emailAddress + ".userData", // NOT SURE WHY WE NEED THIS -> THIS IS DEPRICATED ON NEW UI
      "firstName": formdata.formFirstName.value,
      "lastName": formdata.formLastName.value,
      "permissions": formdata.formPermissions.value,
      "accountId": localStorage.getItem("currentAccount"),
      "accounts": localStorage.getItem("currentAccount") + "|", /**deprecate ASAP */
      "parentAccountId": "c4bd7b20-3bab-11ea-a975-0523f21e6d1f", /*TODO: MUST fix this, locked to smart barn for now */
      "accountName": formdata.formCompany.value,
      // "timezone": formdata.formTimezone.value, //TODO: URGENT get timezone from accountData
      "notes": formdata.formNotes.value,
      "dateCreated": Date.now(),
    }
    // console.log("EVENT " +  JSON.stringify(accountData));
    event.preventDefault();
    
    CreateNewAccountUser(accountData)
      .then(
        (result) => {
          // console.log("createAccountUserResult", result);
          CreateUser(accountData.username, "smartbarn", accountData.email);
          setSuccess(result);
          alert("User created successfully!");
          // const password = "smartbarn"
          // Auth.signUp({
          //   username,
          //   password,
          //   attributes: {
          //     email: emailAddress
          //   }
          // },
            
          // )
          // window.location.reload();
        },
        (error) => {
          setError(error);
          alert("Error creating user: ", error);
          // window.location.reload();
        }
      );
  
    
    // Auth.currentAuthenticatedUser().then(user => console.log(user));

    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }

    setValidated(true);
    // handleClose();
    // lambda action here
    // ideally receive a response from the lambda and then show the following alert:
    // then page refreshes. New account should be visible and accessible.
  };


  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {props.buttonText}
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{props.buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewUserForm handleSubmit={handleSubmit} id={props.id} accountName={props.accountName} /></Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form={props.id}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


function NewUserForm({ handleSubmit, id, accountName }) {
  return (
    <Container fluid>
      <Form id={id} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formParentAccount">
          <Form.Label>Parent Account</Form.Label>
          <Form.Control type="text"  defaultValue="Smart Barn" readOnly /> {/* TODO: need to fix this urgently */}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formCompany">
          <Form.Label>Account / Company Name</Form.Label>
          <Form.Control type="text" defaultValue={accountName} readOnly />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label>First Name*</Form.Label>
              <Form.Control type="text" placeholder="First Name" required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formLastName" >
              <Form.Label>Last Name*</Form.Label>
              <Form.Control type="text" placeholder="Last Name" required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email*</Form.Label>
          <Form.Control type="email" placeholder="Email Address" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formUsername">
          <Form.Label>Username*</Form.Label>
          <Form.Control type="text" placeholder="Username" required />
        </Form.Group>
        {/* <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formCellPhone">
              <Form.Label>Cell Phone</Form.Label>
              <Form.Control type="text" placeholder="999-999-9999" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formLandLine">
              <Form.Label>Landline</Form.Label>
              <Form.Control type="text" placeholder="999-999-9999" />
            </Form.Group>
          </Col>
        </Row> */}
        <Form.Group className="mb-3" controlId="formPermissions">
          <Form.Label>Permissions</Form.Label>
          <Form.Select defaultValue="2" aria-label="select permissions">
            <option value="2">Account admin</option>
            <option value="3">Edit device settings only</option>
            <option value="4">Read only</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formNotes">
          <Form.Label>Notes</Form.Label>
          <Form.Control type="text" />
        </Form.Group>
      </Form>
    </Container>

  );
}

// function LambdaFn() {
//   if (lambda == null) {
//     lambda = new Lambda({
//       region: awsmobile.region,
//       secretAccessKey: process.env.AMPLIFY_AMAZON_CLIENT_SECRET,
//       accessKeyId: process.env.AMPLIFY_AMAZON_CLIENT_ID,
//     });
//   }
//   return lambda;
// }

async function CreateUser(username, password, email) { 
  let apiName = 'AdminQueries';
  let path = '/createUser';
  let myInit = {
      body: {
        "username" : username,
        "password" : password,
        "email": email
      }, 
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      } 
  }
  return await API.post(apiName, path, myInit);
}
import React from "react";
import Administration from "./Administration";
import Manage from "./Manage";
import NotificationsPage from "./NotificationsPage";
import Account from "./Account";
import Overview from "./Overview";

import LinkedAccounts from "./LinkedAccounts";
import {
  Route,
  Routes,
} from "react-router-dom";

import Navigation from "./Navigation";

export default function AppRoutes(props) {
  return(
      <Routes>
        <Route path="/" element={<Navigation userId={props.userId} allUserAccounts={ props.allUserAccounts } linkedAccounts={props.linkedAccounts}/>} >
        <Route index element={
          <Overview 
            userId = { props.userId } 
            accountId = { props.accountId } 
            allUsers={ props.allUsers }
            allNetworks={ props.allNetworks } 
            allSensors={ props.allSensors }
            allGateways={ props.allGateways }
          />
          } 
          />
        
          <Route path="account" element={
            <Account accountId = {
              localStorage.getItem("currentAccount") } 
              allUsers= { props.allUsers } 
              allNetworks={ props.allNetworks }
              accountInfo={ props.accountInfo }
              userId={ props.userId }
            />
          }
          
          />
          
          <Route path="notifications" element={
            <NotificationsPage 
              accountId = {localStorage.getItem("currentAccount")} 
              allGateways = {props.allGateways}
              allNetworks={ props.allNetworks } 
              allNotifications={props.allNotifications}
              allSensors={props.allSensors} 
              allUsers={props.allUsers}
              />
            } 
            />
          
          <Route path="manage" element={
            <Manage
              accountId = { props.accountId }
              
              allNetworks={ props.allNetworks } 
              allSensors={ props.allSensors } 
              allGateways={ props.allGateways }
              userId={props.userId} 
              />
              } 
            />
          <Route path = "linked-accounts" element={ <LinkedAccounts allAccounts = { props.linkedAccounts } /> } />
          {props.userId.includes("smartbarn.io") ?      
          <Route path="administration" element={
            <Administration 
              accountId = {localStorage.getItem("currentAccount")}
            />
            } 
          />
          :
          <Route path="administration" element={ // redirects Administration to account if not admin. Not sure if we need this.
            <Account accountId = {
              localStorage.getItem("currentAccount") } 
              allUsers= { props.allUsers } 
              allNetworks={ props.allNetworks }
              accountInfo={ props.accountInfo }
              userId={ props.userId }
            />
            } 
          />
          }
        </Route>
      </Routes>
  );
}
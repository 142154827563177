import React, {useState} from 'react';
import { Col, Modal, Form, Row } from 'react-bootstrap'
import { UpdateAccount } from '../Database.jsx';

import Button from '@mui/material/Button';

/**
 * I would like this to be generalized and use children props but having trouble getting that to work.
 * @param {*} props 
 * @returns 
 */

export default function AccountEditModal(props) {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSubmit = (event) => {
    const formdata = event.target;
    console.log(formdata)
    const accountData = {
      "id": props.accountId,
      "accountId": props.accountId,
      "name": formdata.formCompany.value,
      "accountName": formdata.formCompany.value,
      "address": formdata.formAddress.value,
      "city": formdata.formCity.value,
      "state": formdata.formState.value,
      "zipCode": formdata.formZip.value,
      "country": formdata.formCountry.value,
      "timezone": formdata.formTimezone.value,
      "notes": formdata.formNotes.value,
    }

    setValidated(true);
    // handleClose();
    // lambda action here
    // ideally receive a response from the lambda and then show the following alert:
    // var alertData = ("EVENT " + JSON.stringify(accountData));
    handleClose();
    // console.log(accountData);
    UpdateAccount(accountData)
      .then(
        (result) => {
          // console.log("createAccountResult", result);
          setSuccess(result);
          alert("Account updated!");
          setSuccess(result);
          window.location.reload();
            // (error) => {
            //   setError(error);
            //   alert("Error creating network: ", error);
            //   // window.location.reload();
            // }
        },
        (error) => {
          setError(error);
          alert("Error updating account ", error);
          // window.location.reload();
        });
    // then page refreshes. New account should be visible and accessible.
  }
  if(error){
    console.log(error);
  }

  return (
    <>
      <Button variant="outlined" size="small" onClick={handleShow}>
        {props.buttonText}
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{props.buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={props.id} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formParentAccount">
              <Form.Label>Parent Account</Form.Label>
              <Form.Select disabled aria-label="select parent account">
                <option value="c4bd7b20-3bab-11ea-a975-0523f21e6d1f">Smart Barn</option>
                <option value="a9af7c70-0e1e-11eb-8cb6-eb022084b0e8">Organic Valley</option>
              </Form.Select>      
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCompany">
              <Form.Label>Account / Company Name</Form.Label>
              <Form.Control type="text" placeholder="Company Name" defaultValue={ props.accountName } required/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" placeholder="Address" defaultValue={props.address}/>
            </Form.Group>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3" controlId="formCity">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" placeholder="City" defaultValue={props.city}/>
                </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="formState" >
                <Form.Label>State</Form.Label>
                <Form.Control type="text" placeholder="State" defaultValue={props.state}/>
              </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="formZip">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control type="text" placeholder="Zip" defaultValue={props.zipCode}/>
              </Form.Group>
            </Row>
            <Form.Group className="mb-3" controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" defaultValue={props.country} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTimezone">
              <Form.Select defaultValue={props.timezone} aria-label="select time zone">
                <option value="GMT-04:00 ">(GMT-04:00) Atlantic Time</option>
                <option value="GMT-05:00 America/New_York">(GMT-05:00) Eastern Time</option>
                <option value="GMT-06:00 America/Chicago">(GMT-06:00) Central Time</option>
                <option value="GMT-07:00 ">(GMT-07:00) Mountain Time</option>
                <option value="GMT-08:00">(GMT-08:00) Pacific Time</option>
                <option value="GMT-10:00">(GMT-10:00) Hawaii Time</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </Form>
        </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form={"editAccountForm"} >Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
import React from "react";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Button from '@mui/material/Button';
import "./Administration.css";


import CurrentAccountInfo from "../Account/CurrentAccountInfo";

export default class AdministrationAccountsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: this.props.account !== null ? [this.props.account] : [],
      searchFilter : "",
      searchList : []
    };
    this.childList = React.createRef();
  }

  componentDidMount() {
    this.parseAccounts();
  }

  parseAccounts() {
    var accounts = this.props.allAccounts;
    accounts.sort(function(a,b){
      if(a.name > b.name){
        return 1
      }else if(a.name === b.name){
        return 0
      }else{
        return -1
      }
    })

    this.setState({
      accounts: accounts,
      searchList : accounts
    });
  }

  filterAccounts = (event) => {
    var accounts = this.state.searchList;
    if(event.target.value  !== ""){
      var valtest = event.target.value.toLowerCase().trim();
      accounts = this.state.searchList.filter(function(elm){
        var totest = elm.name.toLowerCase().trim()
        return totest.includes(valtest)
      })
    }

    this.setState({
      accounts: accounts,
      searchFilter : event.target.value
    });
  }

  render() {
    return (
      <div id="Accounts">
        <Container fluid>
          <input
            type="text"
            className="form-control"
            value={ this.state.searchFilter }
            onChange={ this.filterAccounts }
            placeholder="Search..."
          />
        </Container>
        <Container fluid id="AccountsTable" style={{ width: "100%" }}>
            {this.state.accounts.length !== 0 ? 
              <DisplayAccounts accounts={this.state.accounts} parent={this} user={this.props.user} /> : 
              <Container className="justify-content-center" style={{width:"100px"}}><Spinner animation="border" /></Container>}
          {/* </tbody> */}
        </Container>
      </div>
    );
  }
}

function DisplayAccounts(props) {
  const accountInfo = props.accounts.map((account) => <Account key={ account.name } account={ account } user={ props.user } />);
  return accountInfo;
}

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // parent: props.parent,
      account: props.account,
      allUsers: null
    };
  }
  
  displayContactInfo() {
    var contactInfo = "";
    if (this.state.account.pointOfContact !== null) {
      contactInfo += this.state.account.pointOfContact;
      if (this.state.account.pointOfContactPhone !== null) {
        contactInfo += ": " + this.state.account.pointOfContactPhone;
      }
    } else {
      contactInfo = "None Available";
    }
    return contactInfo;
  }

  render() {
    return (
      <Card style={{textAlign:"left", padding:"2px"}}>
        <Row>
          <Col lg="4">
            <PageLink location="/" title={this.props.account.name} account={this.props.account.id} />
          </Col>
          <Col>
            <Button varient='text' >
              {this.displayContactInfo()}
            </Button>
          </Col>
          <Col>
            <Button variant="text">
              {this.state.account.timezone}
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }
}

const PageLink = (props) => (
  <Button variant="text" href="/"
    onClick={() => {
      localStorage.setItem("currentAccount", props.account);
      localStorage.setItem("selectedNetwork", "All Networks");
      // Check if proxying into an account.
      if (
        props.title !== "Overview" &&
        props.title !== "Notifications" &&
        props.title !== "Account" &&
        props.title !== "Administration" &&
        props.title !== "Manage Networks"
      ) {
        localStorage.setItem("proxyStatus", "active");
        CurrentAccountInfo.currentCompanyName = props.title;
        localStorage.setItem("currentCompanyName", props.title);
        // localStorage.setItem("currentAccount", props.account);
        CurrentAccountInfo.currentAccount = props.account;
        CurrentAccountInfo.allAccounts = [props.account];
        localStorage.setItem("allAccounts", props.account + "|");
      } else {
        CurrentAccountInfo.allAccounts = CurrentAccountInfo.mainAccounts;
        // var accountStr = "";
        // for (var account of CurrentAccountInfo.allAccounts) {
        //   accountStr += account + "|";
        // }
        localStorage.setItem("proxyStatus", "unactive");
        CurrentAccountInfo.currentCompanyName = CurrentAccountInfo.mainCompanyName;
        localStorage.setItem("currentCompanyName", CurrentAccountInfo.mainCompanyName);
        // localStorage.setItem("currentAccount", CurrentAccountInfo.mainAccounts[0]);
        CurrentAccountInfo.currentAccount = CurrentAccountInfo.mainAccounts[0];
      }
      if (props.title === CurrentAccountInfo.mainCompanyName) {
        CurrentAccountInfo.currentCompanyName = props.title;
        localStorage.setItem("CurrentCompanyName", props.title);
        // localStorage.setItem("currentAccount", CurrentAccountInfo.mainAccounts[0]);
        CurrentAccountInfo.currentAccount = CurrentAccountInfo.mainAccounts[0];
        localStorage.setItem("proxyStatus", "unactive");
      }
    }}
  >
    {props.title}
  </Button>
);
import React from 'react';

import Gateway from './Gateway';
import RangeExtender from './RangeExtender';
import BatteryBackup from './BatteryBackupComponents';
import Sensor from './Sensor';
import Container from 'react-bootstrap/Container';
import ErrorBoundary from './ErrorBoundary';
import Accordion from "react-bootstrap/Accordion";

export default function DisplayDevices(props) {
  props.allGateways.sort((a,b)=> ((a.networkName&&a.networkName.localeCompare(b.networkName)) || a.name.localeCompare(b.name))); // group by network and sort by name
  props.allSensors.sort((a,b)=> ((a.networkName&&a.networkName.localeCompare(b.networkName)) || a.name.localeCompare(b.name))); // group by network and sort by name
  const batteryBackups = props.allSensors.filter((sensor) => sensor.deviceType === "BatteryBackup"); // battery backups are shown just after gateways since they are related and we don't want them lost in the sensors.
  const sensors = props.allSensors.filter(sensor => sensor.deviceType !== "BatteryBackup");
  
  return(
    <Container fluid style={{padding:"0px"}}>
      <Accordion defaultActiveKey="history" style={{padding:"0px"}}>
        <ErrorBoundary>
          { (props.currentView === "All")&&props.allGateways.map((gateway) => {
              return <Gateway
                      // filters out the battery backups because bad things happen when we add them to a gateway sensor list. There is also a server side catch.
                      allSensors={ props.allSensors.filter(({deviceType}) => deviceType !== "BatteryBackup" )} 
                      allUsers={ props.allUsers }          
                      key={ gateway.id }
                      sensorsInNetwork={ props.allSensors.filter(({networkId}) => (networkId === gateway.networkId)) }
                      {...gateway}
                      timezone={localStorage.getItem("timezone")}
              />
            }) 
          }

          { (props.currentView === "All")&&batteryBackups.map(batteryBackup => <BatteryBackup key={batteryBackup.id} {...batteryBackup} timezone={localStorage.getItem("timezone")} />)}
          
          { sensors.map( (sensor) => {
              switch(sensor.deviceType){
                case "Ranger_Extender":
                  return <RangeExtender key={sensor.id} {...sensor} timezone={localStorage.getItem("timezone")} />;
                default:
                  return <Sensor key={sensor.id} {...sensor} timezone={localStorage.getItem("timezone")}/>;
              }
            }) 
          }
        </ErrorBoundary>
      </Accordion>
    </Container>
  );
}
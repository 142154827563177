import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { SensorDictionary } from '../SensorTypes.jsx';
import { ListNetworksByAccountId } from '../Database.jsx';
import AccountSelect from './AccountSelect.jsx';
import { IOTData } from "../services.js";
import DecodeSensorId from '../Utilities/DecodeSensorId.js';

const iotdata = IOTData();

export default function CreateNewDevice(props, form) {
  const [accountId, setAccountId] = useState(localStorage.getItem("currentAccount"));
  const handleSetAccount = (e) => setAccountId(e.target.value);
  const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = useState(false);
  
  const handleShow = () => setShow(true);
  
  /* handle sensor types */
  const [deviceType, setDeviceType] = useState("Temperature");
  const deviceTypeChange = (event) => setDeviceType(event.target.value)

  /* handle sensor ID */
  const [deviceId, setDeviceId] = useState("");
  const deviceIdChange = (event) => setDeviceId(event.target.value);
  
  /* handle device code change */
  const [deviceCode, setDeviceCode] = useState("");
  const deviceCodeChange = (event) => setDeviceCode(event.target.value.toUpperCase());
  
  /* handle modem serial number */

  const [modemSerialNum, setModemSerialNum] = useState("");

  const [allNetworks, setAllNetworks] = useState([]);
  const [networkId, setNetworkId] = useState("");
  const [networkName, setNetworkName] = useState("");

  useEffect(() => {
    ListNetworksByAccountId(accountId)
    .then(
      (result) => {
        setNetworkId(result.data.listNetworksByAccount.items[0].id);
        setNetworkName(result.data.listNetworksByAccount.items[0].name);
        setAllNetworks(result.data.listNetworksByAccount.items);
        setIsLoaded(true);
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  },[accountId]);
  // NEW BUT NOT TESTED:
  const handleClose = () => {
    setShow(false);
    // setDeviceType("Temperature");
    setDeviceId("");
    setDeviceCode("");
    setModemSerialNum("");
  }

  const handleSetNetwork = (e) => {
    setNetworkId(e.target.value);
    const sel = document.getElementById("select-network");
    setNetworkName(sel.options[sel.selectedIndex].text);
  }
  
  // const [validated, setValidated] = useState(false);
  // const [error, setError] = useState(null);
  // const [result, setResult] = useState(null);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    var device_data = {};
    // const lambda = LambdaFn();
    console.log(deviceType);
    
    if(deviceType === "Ethernet_3_0") {
      device_data = {
        id: deviceId,
        account: accountId, // hardcoded to main account for now.
        accountId: accountId, // hardcoded to main account for now
        deviceType: "Ethernet_3_0",
        deviceId: deviceId,
        heartbeat: 5,
        manufacturer: "monnit",
        modemSerialNum: modemSerialNum,
        name: SensorDictionary[deviceType].name,
        networkId: networkId, // hard coded to sensor registration network
        networkName: networkName, // hard coded to sensor registration network
        notificationStatus: "disarmed",
        pendingChange: true,
        resetInterval: 3600,
        sensorCode: deviceCode,
        type: "Ethernet_3_0",
        version: "Ethernet_3_0",

      }
    }
    else if(deviceType === "Ethernet_Gateway_4") {
      device_data = {
        id: deviceId,
        account: accountId, // hardcoded to main account for now.
        accountId: accountId, // hardcoded to main account for now
        deviceType: "Ethernet_Gateway_4",
        deviceId: deviceId,
        heartbeat: 5,
        manufacturer: "monnit",
        modemSerialNum: modemSerialNum,
        name: SensorDictionary[deviceType].name,
        networkId: networkId, // hard coded to sensor registration network
        networkName: networkName, // hard coded to sensor registration network
        notificationStatus: "disarmed",
        pendingChange: true,
        resetInterval: 3600,
        sensorCode: deviceCode,
        type: "Ethernet_Gateway_4",
        version: "Ethernet_Gateway_4",
      }
      
    }
    else if(deviceType === "USBService") {
      device_data = {
        id: deviceId,
        account: accountId, // hardcoded to main account for now.
        accountId: accountId, // hardcoded to main account for now
        deviceType: "USBProService",
        deviceId: deviceId,
        heartbeat: 5,
        manufacturer: "monnit",
        modemSerialNum: modemSerialNum,
        name: "SensorAdapter",
        networkId: networkId, // hard coded to sensor registration network
        networkName: networkName, // hard coded to sensor registration network
        notificationStatus: "disarmed",
        pendingChange: true,
        resetInterval: 3600,
        sensorCode: deviceCode,
        type: "USBProService",
        version: "USBProService",
      }
      
    }
    else {
      device_data = {
        account: accountId, // hardcoded to main account for now.
        accountId: accountId, // hardcoded to main account for now.
        deviceId: deviceId,
        deviceType: deviceType, // this is confusing
        heartbeat: 10,
        heartbeatAssessments: 10,
        heartbeatAware: 10, // always matches normal heartbeat, here for ref.
        id: deviceId,
        manufacturer: "monnit",
        maxThresh:SensorDictionary[deviceType].maxThresh,
        minThresh: SensorDictionary[deviceType].minThresh,
        name: SensorDictionary[deviceType].name,
        networkId: networkId, // hard coded to sensor registration network
        networkName: networkName, // hard coded to sensor registration network
        notificationId: "N/A",
        notificationStatus: "N/A",
        pendingChange: false,
        scaleValue1: SensorDictionary[deviceType].scaleValue1,
        scaleValue2: SensorDictionary[deviceType].scaleValue2,
        sensorCode: deviceCode,
        // topic:  "cmd/" + props.accountId + "/" + 408068671 + "/" + deviceId + "/" + version + "/monnit/NewSensor",
        version: deviceType,
        cmdStatus: "REQUEST",
        cmdTimeStamp: (new Date())
      }
    }

    if(deviceCode !== DecodeSensorId(parseInt(deviceId))) {
      
      alert("Incorrect code entered!");
    }
    else { 
      if(deviceType === "Ethernet_Gateway_4" || deviceType === "Ethernet_3_0" || deviceType === "USBService") {
          const params = {
          topic: "cmd/gateway/monnit/NewGateway",
          payload: JSON.stringify(device_data),
          qos: 0,
        };
    
        iotdata.publish(params, function (err, data) {
          if (err) console.log(err, err.stack);
          else {
            console.log("response", data);
            console.log("Sent data ", device_data);
            alert("Gateway successfully added!");
            handleClose();
          }
        });

      }
      else {
        const params = {
          topic: "cmd/sensor/monnit/NewSensor",
          payload: JSON.stringify(device_data),
          qos: 0,
        };
    
        iotdata.publish(params, function (err, data) {
          if (err) console.log(err, err.stack);
          else {
            console.log("response", data);
            console.log("Sent data ", device_data);
            alert("Sensor successfully added!");
            handleClose();
          }
        });
      }
    }
  };

  const SensorTypes =
    <>
      <option key={"Temperature"} value="Temperature">Temperature</option>
      <option key={"CurrentZeroToTwentyAmp"} value="CurrentZeroToTwentyAmp">0-20 Amp Current Meter</option>
      <option key={"Dry_Contact"} value="Dry_Contact">Dry Contact</option>
      <option key={"Voltage_Meter_500"} value="Voltage_Meter_500">Voltage Meter 500</option>
      <option key={"Low_Temperature"} value="Low_Temperature">Low Temperature Sensor</option>
      <option key={"Open_Closed"} value="Open_Closed">Door Open/Closed</option>
      <option key={"Pulse_Counter_1_Input"} value="Pulse_Counter_1_Input">Pulse counter</option>
      <option key={"Pressure300PSI"} value="Pressure300PSI">Pressure Sensor - 300 PSI</option>
      {/* <option key={"Pulse_Counter"} value="Pulse_Counter">Single Input Pulse Counter</option> */}
      <option key={"Humidity"} value="Humidity">Humidity</option>
      <option key={"Ranger_Extender"} value="Ranger_Extender">Range Extender</option>
      <option key={"Button"} value="Button">Button</option>
      <option key={"Current_20mA"} value="Current_20mA">0-20 mA Sensor</option>
      <option key={"DC_Voltage_5V"} value="DC_Voltage_5V">0-5V Sensor</option>
      <option key={"Ethernet_Gateway_4"} value="Ethernet_Gateway_4">Ethernet Gateway 4</option>
      <option key={"Ethernet_3_0"} value="Ethernet_3_0">Ethernet Gateway 3</option>
      <option key={"USBService"} value="USBService">SensorAdapter</option>

      <option disabled key={"SmartBarnDairySensor"} value="SmartBarnDairySensor">SmartBarnDairySensor</option>
    </>

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {props.buttonText}
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{props.buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={props.id} onSubmit={handleSubmit}>
            <AccountSelect setAccount={ handleSetAccount } />  
            <Form.Group className="mb-3" controlId="formNetwork">
            <Form.Label>Network</Form.Label>
            <Form.Select id="select-network" aria-label="select network" value={ networkId } onChange={ (e) => handleSetNetwork(e)} >
              {allNetworks.map(element => (<option key={element.id} value={element.id}>{element.name}</option>))}
            </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Device Type</Form.Label>        
              <Form.Select value={ deviceType } onChange={deviceTypeChange}>
                {SensorTypes}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Device ID:</Form.Label>
                <Form.Control type="number" value={deviceId} onChange={deviceIdChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Device Code:</Form.Label>
                <Form.Control type="text" value={deviceCode} onChange={deviceCodeChange} required />
                {deviceType.startsWith("Ethernet") ? <>Cradlepoint SN: <Form.Control type="text" value={modemSerialNum} onChange={(e) => setModemSerialNum(e.target.value)} /> </>: <></>}
            </Form.Group>
          </Form>
        </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form={props.id}>Register Device</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
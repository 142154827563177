import React, {useState} from "react";
import { Auth } from "aws-amplify";

/* Bootstrap imports */
import RefreshIcon from '@mui/icons-material/Refresh';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { Link, Outlet } from "react-router-dom";
import "./Navigation.css";

/* Font Awesome Icons */
import WarningIcon from '@mui/icons-material/Warning';


export default function Navigation (props) {

  const [location, setLocation] = useState(window.location.pathname);
  const logOff = () => {
    Auth.signOut()
      .then(
        forceSWupdate()
        .then(
          localStorage.clear(),
          // window.location.reload()
        )
      )
  }


  //TODO manage better and pull administration permissions from cognito.
  const header = localStorage.getItem("proxyStatus") === "active" ? <>{localStorage.getItem("currentCompanyName")}<WarningIcon htmlColor="orange" style={{ marginBottom: "4px" }} /></> : localStorage.getItem("currentCompanyName");
  // hide administration if not a SmartBarn user. TODO: Restrict via authentication resource.
  if(!props.userId.includes("@smartbarn.io")) {
    return (
      <Container fluid style={{maxWidth:"960px", minWidth: "none"}}>
        <Navbar collapseOnSelect bg="light" expand="md" className="smartbarnnavbar" >
            <Navbar.Brand href="/">
            <RefreshIcon style={{ marginBottom: "4px" }}/>{header}
            </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
              <Nav className="me-auto" variant="pills" activeKey={location}>
                <Nav.Item as="li">
                  <Nav.Link as={Link} to="/" eventKey="/" onClick={() => setLocation("/")}>Overview</Nav.Link>
                </Nav.Item >
                <Nav.Item as="li">
                  <Nav.Link as={Link} to="/notifications" eventKey="/notifications" onClick={() => setLocation("/notifications")}>Notifications</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link as={Link} to="/manage" eventKey="/manage" onClick={() => setLocation("/manage")}>Manage</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link as={Link} to="/account" eventKey="/account" onClick={() => setLocation("/account")}>Account</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  {props.linkedAccounts&&props.linkedAccounts.length > 1 ? <Nav.Link as={Link} to="/linked-accounts" eventKey="/linked-accounts" onClick={() => setLocation("/linked-accounts")}>Linked Accounts</Nav.Link> : <></>}
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link as={Link} to="/" eventKey="logoff" onClick={() => logOff()}>Log out</Nav.Link>
                </Nav.Item>
              </Nav>                
            </Navbar.Collapse>
          </Navbar>
          <Outlet />
      </Container>
    );
  }
  // if a smartbarn.io user then show all navigation.
  return (
    <Container fluid style={{maxWidth:"960px", minWidth: "none"}}>
      <Navbar collapseOnSelect bg="light" expand="md" className="smartbarnnavbar"> 
          <Navbar.Brand href="/">
            <RefreshIcon style={{ marginBottom: "4px" }}/>{header}
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="me-auto" variant="pills" activeKey={location}>
              <Nav.Item as="li">
                <Nav.Link as={Link} to="/" eventKey="/" onClick={() => setLocation("/")}>Overview</Nav.Link>
              </Nav.Item >
              <Nav.Item as="li">
                <Nav.Link as={Link} to="/notifications" eventKey="/notifications" onClick={() => setLocation("/notifications")}>Notifications</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={Link} to="/manage" eventKey="/manage" onClick={() => setLocation("/manage")}>Manage</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={Link} to="/account" eventKey="/account" onClick={() => setLocation("/account")}>Account</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={Link} to="/administration" eventKey="/administration" onClick={() => setLocation("/administration")}>Admin</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link as={Link} to="/" eventKey="logoff" onClick={() => logOff()}>Log out</Nav.Link>
              </Nav.Item>
          </Nav>                
        </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </Container>
  );
}

async function forceSWupdate() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        console.log("UNREGISTERING SERVICE WORKER: " + registration); //TODO: DELETE THIS
        registration.unregister()
      }
    })
  }
}
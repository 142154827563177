import { useState, useEffect } from 'react';
import { getUserNotes } from '../graphql/customQueries';
import { API, graphqlOperation } from "aws-amplify";

export default function useGetUserNotes(senid, limit=100, nextToken=null) { // defaults for limit and nextToken can be changed by the querying component.
  const [userNotes, setUserNotes] = useState(null);
  console.log("senid", senid);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(getUserNotes, { senid: senid, limit: limit, nextToken: nextToken })).catch((error) => console.log(error));
      setUserNotes(result);
    }
    senid&&fetchData();
  },[senid, limit, nextToken]);
  console.log("userNotes", userNotes);
  if(userNotes===null) return []; // return an empty array if the query fails or returns nothing.
  return userNotes.data.getUserNotes.items;
}
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isSameDay from 'date-fns/isSameDay';

export default function findHiLowTemperatureByDay(sensorDataMessages, startDate, endDate, temperatureUnitsCorF) {
  const days = eachDayOfInterval({ start: startDate, end: endDate });
  var formattedHiLowByDay = [];
  days.forEach(function (day) {
    var maxTemperature = -Infinity;
    var minTemperature = Infinity;
    sensorDataMessages.forEach(function (items) {
      const dateFromUnixTimestamp = new Date(items.timestamp * 1000);
      if (isSameDay(day, dateFromUnixTimestamp)) {
        if (maxTemperature < parseFloat(items.data) && parseFloat(items.data) > -200) {
          maxTemperature = parseFloat(items.data);
        };
        if (minTemperature > parseFloat(items.data) && parseFloat(items.data) > -200) {
          minTemperature = parseFloat(items.data);
        }
      }
    });
    maxTemperature = (maxTemperature === -Infinity) ? "" : !temperatureUnitsCorF.startsWith('C') ? (maxTemperature !== null) && ((maxTemperature * 9 / 5) + 32).toFixed(1) : maxTemperature.toFixed(1);
    minTemperature = (minTemperature === Infinity) ? "" : !temperatureUnitsCorF.startsWith('C') ? (minTemperature !== null) && ((minTemperature * 9 / 5) + 32).toFixed(1) : minTemperature.toFixed(1);
    formattedHiLowByDay.push(["Hi: " + maxTemperature + temperatureUnitsCorF + " Low: " + minTemperature + temperatureUnitsCorF]);
  });
  return formattedHiLowByDay;
}

import React, { useState } from "react";

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { IOTData } from "../services.js";

const iotdata = IOTData();

export default function NotificationScheduleTab(props) {
  const [hoursSelected, setHoursSelected] = useState(props.notificationSchedule || [ true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true ]);
  const [selectAllButton, setSelectAllButton] = useState(!hoursSelected.includes(false) || false)
  const hoursOfDay =  [ "12A", "1A", "2A", "3A", "4A", "5A","6A", "7A", "8A", "9A", "10A", "11A", "12P", "1P", "2P", "3P", "4P", "5P","6P", "7P", "8P", "9P", "10P", "11P" ]
  const date = new Date();
  const offset = date.getTimezoneOffset();
  console.log(offset/60);    // -300
  const handleCheckBox = (e) => {
    // console.log(e.target.name)
    // console.log(e.target.checked)
    var items = [...hoursSelected]
    items[parseInt(e.target.name)] = e.target.checked;
    setHoursSelected(items)
  }

  const handleSelectAll = (e) => {
    // console.log(e.target.checked)
    let trueArray = [ true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true ];
    let falseArray = [ false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false ];
    e.target.checked ? setHoursSelected(trueArray) : setHoursSelected(falseArray);
    setSelectAllButton(e.target.checked)
  }

  const timeZoneTemp = offset/60;
  console.log("TimeZoneTemp: " + timeZoneTemp)
  const checkBoxes = hoursOfDay.map((e, i) => {
    const hour = (i + timeZoneTemp) <= 23 ? i + timeZoneTemp : i + timeZoneTemp -24;
    // console.log("UTC " + i + " local: " + hour)
    return  <FormControlLabel key={i} labelPlacement="top" control={<Checkbox key={i} checked={hoursSelected[hour]} onChange={handleCheckBox} name={hour.toString()} />} label={e} />
  })

  const handleSaveSettings = (event) => {
    event.preventDefault();
    const json_string = {
      deviceId: props.deviceId,
      notificationSchedule: hoursSelected,
      cmdStatus: "REQUEST",
      cmdTimeStamp: new Date()
    }

    const params = {
      topic: "cmd/sensor/" + props.deviceType + "/monnit/notificationSettings/notificationSchedule"  ,
      payload: JSON.stringify(json_string),
      qos: 0
    };
    iotdata.publish(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        alert("Problem saving settings!");
      }
      else {
        console.log("response", data);
        alert("Settings Saved!");
        // window.location.reload();
      }
    });
  }

  // const selectAllButton = !hoursSelected.includes(false);
  return(
    <>
    <FormGroup>
      Sensor Notification Schedule
      <div><FormControlLabel key={123} labelPlacement="end" control={<Checkbox key={123} checked={selectAllButton} onChange={handleSelectAll} name={"Select all"} />} label={"Select all"} /></div>
      <div>{checkBoxes}</div>
    </FormGroup>
    <div><Button variant="contained" onClick={handleSaveSettings}>Save</Button></div>
    </>
  );
}
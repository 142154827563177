import React, { useState, useEffect, useRef } from 'react';

// MUI imports
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
// bootstrap imports
import { Accordion, Col, Row, Container, Button, Tabs, Tab } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import NotificationToolTip from '../Components/Buttons/Notifications/NotificationToolTip';


// smart barn imports
import { ListFirstNotifications } from "./NotificationQueries";
import NotificationDevicesTab from './NotificationDevicesTab';
import NotificationUsersTab from './NotificationUsersTab';
import NotificationHistory from './NotificationHistory';
import { UpdateNotification } from '../Database.jsx';

import { format } from 'date-fns'

import { IOTData } from "../services.js";

//TODO: update sensor settings / status
const iotdata = IOTData();

export default function SensorInactivityNotification(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [assignedDevices, setAssignedDevices] = useState(!props.assignedDevices ? [] : props.assignedDevices);
  const [assignedUsers, setAssignedUsers] = useState(!props.assignedUsers ? [] : props.assignedUsers);
  const [tabKey, setTabKey] = useState('history');
  const [inactiveThresh, setInactiveThresh] = useState(props.inactiveThresh);
  const [loadNotificationHistory, setLoadNotificationHistory] = useState(false);
  const [notificationName, setNotificationName] = useState(props.name);
  const [notificationStatus, setNotificationStatus] = useState(props.notificationStatus)
  const [message, setMessage] = useState(props.message);
  const [returnToNormalMessage, setReturnToNormalMessage] = useState(props.returnToNormalMessage || "<Name> <ID> is now <Reading>");
  const [lastSentDate, setLastSentDate] = useState("N/A");
  const didMount = useRef(false);

  // TOPIC FOR NOTIFICATION HERE
  //cmd/sensor/+/monnit/notificationSettings/timedDryContact
  const cmdSensorNotificationTopic = "cmd/sensor/" + props.deviceType + "/monnit/notificationSettings/sensorInactivity";


  const handleLoadNotificationHistory = () => {
    setLoadNotificationHistory(true);
  }

  useEffect(() => {
    // GetFirstSensorMessage(props.id, null, 1)
    ListFirstNotifications(props.id, null, 1)
    .then(
      (result) => {
        if(result.data.listFirstNotifications.items.length > 0) {
          setLastSentDate(format(new Date(result.data.listFirstNotifications.items[0].timestamp*1000), "MM/dd/yyyy h:mma"));
        }
        setIsLoaded(true)
      }, error => {
        setIsLoaded(true)
        setError(error) 
      }
    );
  });

  useEffect(() => {
    
    const handleToggle = () => {
      const iot_data = {
        assignedDevices: assignedDevices,
        inactivityNotificationStatus: notificationStatus,
        inactivityNotificationId: props.id
      }

      const dbase_data = {
        assignedDevices: assignedDevices,
        notificationStatus: notificationStatus,
        notificationId: props.id
      }
  
      const topic = cmdSensorNotificationTopic;
      var params = {
        topic: topic,
        payload: JSON.stringify(iot_data),
        qos: 0,
      };
  
      UpdateNotification(dbase_data)
      .then(
        iotdata.publish(params, function (err, data) {
          if (err) console.log(err, err.stack);
          else {
            // console.log("response", data);
            // console.log("Sent data ", iot_data);
              // alert("Notification Settings Saved!");
            }
          })
        )
      // console.log("ToggleUpdateNotification", iot_data)
    }
    if ( !didMount.current ) {
      didMount.current = true;
    }
    else {
      handleToggle();
    }
  }, [assignedDevices, cmdSensorNotificationTopic, notificationStatus, props.id]);

  if(!isLoaded) { return <></> }
  const handleDelete = () => {
    // reset the notification states on notification toggle / updates GUI appearance based on flags
    setNotificationStatus("disarmed");
    // if the notification is toggle, it resets all the status flags of the notification as well.
    // var json_data = {
    //   "id": props.id,
    //   "notificationStatus": (checked ? "armed" : "disarmed")
    // }
    console.log("handleSwitchToggle", "disarmed");
    // updates the status in the database.

    // sensor status data for IoT core.
    const iot_data = {
      // assignedUsers: assignedUsers,
      notificationId: props.id,
      assignedDevices: assignedDevices,
      message: message,
      inactiveThresh: inactiveThresh,
      notificationName: notificationName,
      notificationStatus: "disarmed",
      notificationType: "sensorInactivity",
    }

    var params = {
      topic: cmdSensorNotificationTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log("response", data);
        console.log("Sent data ", iot_data);
        // alert("Notification Status Updated!");
      }
    });

    props.onDeleteNotification(props.notificationId);
  }

  // TODO: if a device is removed from the list of devices then update / remove the sensor from the notification
  
  const handleSave = () => {
    const iot_data = {
      account: props.account,
      accountId: props.account,
      assignedDevices: assignedDevices,
      assignedUsersInactivityNotification: assignedUsers,
      inactiveThresh: inactiveThresh,
      inactivityNotificationMessage: message,
      inactivityNotificationId: props.id,
      inactivityNotificationName: notificationName,
      inactivityNotificationStatus: notificationStatus,
      inactivityReturnToNormalMessage: returnToNormalMessage,
    }

    const dbase_data = {
      account: props.account,
      accountId: props.account,
      assignedDevices: assignedDevices,
      assignedUsers: assignedUsers,
      inactiveThresh: inactiveThresh,
      message: message,
      notificationId: props.id,
      notificationName: notificationName,
      notificationStatus: notificationStatus,
      returnToNormalMessage: returnToNormalMessage,
    }

    const topic = cmdSensorNotificationTopic;
    var params = {
      topic: topic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };
    UpdateNotification(dbase_data)
    .then(
      iotdata.publish(params, function (err, data) {
        if (err) console.log(err, err.stack);
        else {
          // console.log("response", data);
          // console.log("Sent data ", iot_data);
            alert("Notification Settings Saved!");
          }
        })
      );
    console.log("UpdateNotification", iot_data)
  }

  // TODO: fix the redundancies here, would like to use the handleSave only to simplify.
  const handleSwitchToggle = (event) => {
    // reset the notification states on notification toggle / updates GUI appearance based on flags
    // (Timed notifications) Timed notifications work differently than normal ones. To keep the notification from setting all the time set here as "timerActive"
    // (cont.) instead of "armed" The server will set the status to "armed" when the sensor has been "running" for a certain amount of time. Then the alert will send right away.
    setNotificationStatus(event.target.checked ? "armed" : "disarmed");
    console.log("Checked", notificationStatus);
  }

  // set the status icon based on the state of the notification
  var notificationStatusIcon=<CheckCircleIcon htmlColor="green" />;
  // check sensors for active notifications, then set status here.
  if(notificationStatus === "disarmed") {
    notificationStatusIcon=<CancelIcon htmlColor="gray" />;
  }
  else if(notificationStatus === "alerting") {
    notificationStatusIcon=<ErrorIcon htmlColor="red" />;
  }
  else if(notificationStatus === "acknowledged") {
    notificationStatusIcon=<PauseCircleIcon htmlColor="orange" />;
  }

  const allSensors = props.allSensors.filter(sensor => sensor.networkId === props.networkId); // TODO: change version to deviceType

  return (
    <Accordion.Item key={props.id} eventKey={props.id}>
      <Container fluid style={{paddingLeft: "0px"}}>
        <Switch 
          onChange={handleSwitchToggle} 
          checked = {(notificationStatus !== "disarmed")} 
          className="dynamic-class-name"
        />
        <b style={{textAlign:"left"}}>{ notificationName }</b>&nbsp;Network: {props.networkName}
        <Accordion.Header onClick={ handleLoadNotificationHistory }>
          <Col style={{padding:"0px"}}>
            <Row className="align-items-center">
              <Col xs="1" lg="auto" style={{minWidth:"10px"}}>
                <Row><Container fluid>{notificationStatusIcon}</Container></Row>
              </Col>
              <Col xs="3" lg="1" >
                <Card style={{minHeight:"44px", borderRadius:"3px", fontSize:"8px", padding:"0px", borderColor: "black", borderThickness: "2px", backgroundColor:"#ECE7BE" }} ><NotificationsPausedIcon fontSize="large" sx={{ color:"#414142" }}/><b>INACTIVITY</b></Card>
              </Col>
              <Col><Container fluid style={{textAlign: "left"}}>Last sent: {lastSentDate}</Container></Col>
            </Row>
          </Col>
        </Accordion.Header>
      </Container>
      <Accordion.Body style={{padding:"0px"}}>
      { loadNotificationHistory === true && <Tabs
          id="controlled-tab"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
          className="mb-3"
        >
          <Tab eventKey="history" title="History">
            <NotificationHistory id={ props.id } tabKey={ tabKey }/> 
          </Tab>
          <Tab eventKey="edit" title="Edit">
            <Container style={{paddingLeft: 0, paddingRight: 0}} >
              <Form>
                <Form.Group className="mb-3" controlId="formNotificationName">
                  <Form.Label>Notification Name</Form.Label>
                  <Form.Control type="text" defaultValue={ notificationName}  onChange={ (e) => setNotificationName(e.target.value) } />
                  <Form.Label>Notification Message &nbsp; </Form.Label>
                  <NotificationToolTip />
                  <Form.Control type="text" defaultValue={ props.message } onChange={ (e) => setMessage(e.target.value) } />
                    <Form.Label>Alert when inactive for longer than:</Form.Label>
                    <Form.Control 
                      type="number" 
                      defaultValue={ inactiveThresh || 15 }
                      min={ 15 }
                      max={ 720 }
                      onChange={ (e) => setInactiveThresh(e.target.value) }
                    />
                    <Form.Text>Time inactive in minutes before an alert is sent</Form.Text>
                </Form.Group>
              </Form>
            </Container>
          </Tab>
          <Tab eventKey="devices" title="Devices">
            <NotificationDevicesTab
              allSensors={ allSensors }
              assignedDevices={ assignedDevices } 
              setAssignedDevices={ setAssignedDevices }
              deviceType = { props.deviceType }
              tabKey={ tabKey }
            />
          </Tab>
          <Tab eventKey="users" title="Users">
          <Container style={{paddingBottom:5, paddingLeft: 0, paddingRight: 0}}>
            {tabKey === "users" ? <NotificationUsersTab 
                    allUsers={ props.allUsers }
                    assignedUsers= { assignedUsers }
                    handleSetAssignedUsers={ setAssignedUsers }
                  /> : <></>}
                  NOTE: PHONE CALLS ARE CURRENTLY NOT ACTIVE FOR SENSOR INACTIVITY NOTIFICATIONS AND WILL NOT SEND.
          </Container>
          </Tab>
        </Tabs> }
        { tabKey === "history" ? <></> : 
          <><Button onClick={handleSave}>Save</Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <DeleteIcon htmlColor="black" />Delete</Button>
          </> }
      </Accordion.Body>
    </Accordion.Item>
  );
}
import React from "react";
import Container from "react-bootstrap/Container";

import { DataGrid } from '@mui/x-data-grid';


export default function NotificationDevicesTab(props) {
  // const [assignedDevices, setAssignedDevices ] = useState(props.assignedDevices);
  const handleCheckBox = (event) => {
    // TODO: this probably needs to go in a useEffect in the parent prop.
    props.setAssignedDevices(event);
    // setAssignedDevices(event);
    // props.setAssignedDevices(event);
  }

  const columns = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 100 },
    {
      field: 'name',
      headerName: 'Device Name',
      width: 300,
    },
    { 
      field: 'version', 
      headerName: 'type', 
      width: 200},
  ];
  // if(props.tabKey === "devices"){
  //   return <></>
  // }
  return(
    // TODO: FIX DATAGRID ERROR HERE
    <Container fluid style={{ height: 600, width: '100%' }}>
      {props.tabKey === "devices" && <DataGrid
          rows={ props.deviceType === "gateway" ? props.allGateways : props.allSensors }
          columns={columns}
          pageSize={15}
          rowHeight={25} 
          checkboxSelection
          // disableSelectionOnClick
          rowsPerPageOptions={[15]}
          autoHeight
          onSelectionModelChange={(assignedDevices) => {
            handleCheckBox(assignedDevices);
          }}
          selectionModel={props.assignedDevices}
          // {...data}
      />}
    </Container>
  )
}



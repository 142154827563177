 
import { useState, useEffect } from 'react';
import { getAccounts } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useLoadAccountInfo(accountId) {
  const [accountInfo, setAccountInfo] = useState(null);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(getAccounts, { id: accountId })).catch((error) => console.log("graphQL error: ", error));
      localStorage.setItem("timezone", result.data.getAccounts.timezone);
      setAccountInfo(result.data.getAccounts);
    }
    accountId&&fetchData();
  },[accountId]);
  return accountInfo;
}
import React from "react";
import FormatHumidityDataValues from "../Utilities/FormatHumidityDataValues";


export default function FormatLatestSensorMessage(props) {
  if (typeof (props.data) === 'undefined' || props.data == null) {
    return (<>--</>);
  }
  var messageText = "";
  const version = props.version.toLowerCase();
  const dataValues = JSON.parse(props.dataValues);
  switch (version) {
    case "currentzerototwentyamp":
      const maxCurrent = parseFloat(dataValues["Maximum Current"]).toFixed(1);
      return <b>{maxCurrent + " amps"} </b>;
    case "dc_voltage_5v":
      return <b>{(props.data * props.scaleValue1 + props.scaleValue2 * 1).toFixed(1) + 'mph'}</b>;
    case "open_closed":
    case "button":
    case "dry_contact":
      messageText = props.data == 1 ? props.scaleValue1 : props.scaleValue2;
      return <b>{messageText}</b>;
    case "humidity":
      return <b>{FormatHumidityDataValues(dataValues, props.scaleValue1)}</b>;
    case "temperature":
      if (props.data < -40) { return 'ERROR'; }
      return <b>{props.scaleValue1.charAt(0) === "F" ? ((props.data * 9.0 / 5.0) + 32.0).toFixed(1) + "F" : (props.data * 1.0).toFixed(1) + "C"}</b>;
    case "ranger_extender":
      return props.data;
    case "ac_voltage_500":
    case "voltage_meter_500":
      return <b>{props.data.toFixed(0) + props.scaleValue1}</b>;
    case "pulse_counter_1_input":
    case "filteredpulsecounter64":
    case "filtered_pulse_counter_1_input":
    case "count":
    default:
      return <b>{props.data + " " + props.scaleValue1}</b>;
  }
}

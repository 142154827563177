/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccounts = /* GraphQL */ `
  query GetAccounts($id: String!) {
    getAccounts(id: $id) {
      id
      accountId
      parentAccountId
      name
      accountName
      address
      city
      state
      zipCode
      country
      timezone
      adminName
      pointOfContact
      pointOfContactPhone
      administrators
      notes
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: TableAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        parentAccountId
        name
        accountName
        address
        city
        state
        zipCode
        country
        timezone
        adminName
        pointOfContact
        pointOfContactPhone
        administrators
        notes
      }
      nextToken
    }
  }
`;
export const getNetwork = /* GraphQL */ `
  query GetNetwork($account: String!, $id: String!) {
    getNetwork(account: $account, id: $id) {
      account
      id
      name
    }
  }
`;
export const listNetworks = /* GraphQL */ `
  query ListNetworks(
    $filter: TableNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNetworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account
        id
        name
      }
      nextToken
    }
  }
`;
export const listNetworksByAccount = /* GraphQL */ `
  query ListNetworksByAccount(
    $account: String!
    $limit: Int
    $nextToken: String
  ) {
    listNetworksByAccount(
      account: $account
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        account
        id
        name
      }
      nextToken
    }
  }
`;
export const getGateways = /* GraphQL */ `
  query GetGateways($id: String!) {
    getGateways(id: $id) {
      id
      account
      networkId
      networkName
      name
      type
      heartbeat
      notificationStatus
      pendingChange
    }
  }
`;
export const listGateways = /* GraphQL */ `
  query ListGateways(
    $filter: TableGatewaysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        networkId
        name
        heartbeat
        inactiveThresh
        notificationRestartTime
        notifyStatus
        notifyInactive
        ackInactive
        awareStateInactive
        resetInterval
        linkModeTrans
        pendingChange
        type
        version
      }
      nextToken
    }
  }
`;
export const listGatewaysByAccount = /* GraphQL */ `
  query ListGatewaysByAccount(
    $account: String!
    $limit: Int
    $nextToken: String
  ) {
    listGatewaysByAccount(
      account: $account
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        account
        accountId
        id
        heartbeat
        deviceId
        deviceList
        deviceType
        manufacturer
        maxThresh
        message
        modemSerialNum
        name
        networkId
        networkName
        notificationStatus
        powerStatus
        powerStatusPinEnabled
        assignedUsers {
          name
          username
          contactMethod
          contactValue
          notificationDelayMinutes
          notificationDelaySeconds
        }
        resetInterval
        pendingChange
        sensorCode
        type
        version: type #workaround until add this field later.
      }
      nextToken
    }
  }
`;
export const listGatewaysByNetwork = /* GraphQL */ `
  query ListGatewaysByNetwork(
    $networkId: Int!
    $limit: Int
    $nextToken: String
  ) {
    listGatewaysByNetwork(
      networkId: $networkId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        networkId
        name
        version
        heartbeat
        inactiveThresh
        notificationRestartTime
        notifyStatus
        notifyInactive
        ackInactive
        awareStateInactive
        resetInterval
        linkModeTrans
        pendingChange
      }
      nextToken
    }
  }
`;
export const getGatewayMessages = /* GraphQL */ `
  query GetGatewayMessages($gwid: String!, $timestamp: Int!) {
    getGatewayMessages(gwid: $gwid, timestamp: $timestamp) {
      gwid
      timestamp
      battery
      date
      messageCount
      messageType
      power
    }
  }
`;
export const listGatewayMessages = /* GraphQL */ `
  query ListGatewayMessages(
    $filter: TableGatewayMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGatewayMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        gwid
        timestamp
        battery
        date
        messageCount
        messageType
        power
      }
      nextToken
    }
  }
`;
export const queryGatewayMessagesByGwidTimestampIndex = /* GraphQL */ `
  query QueryGatewayMessagesByGwidTimestampIndex(
    $gwid: String!
    $first: Int
    $after: String
  ) {
    queryGatewayMessagesByGwidTimestampIndex(
      gwid: $gwid
      first: $first
      after: $after
    ) {
      items {
        gwid
        timestamp
        battery
        date
        messageCount
        messageType
        power
      }
      nextToken
    }
  }
`;
export const listGatewayMessagesByDay = /* GraphQL */ `
  query ListGatewayMessagesByDay(
    $gwid: String!
    $startTime: Int!
    $endTime: Int!
    $limit: Int
    $nextToken: String
  ) {
    listGatewayMessagesByDay(
      gwid: $gwid
      startTime: $startTime
      endTime: $endTime
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gwid
        timestamp
        battery
        date
        messageCount
        messageType
        power
        pendingChange
      }
      nextToken
    }
  }
`;
export const listFirstGatewayMessages = /* GraphQL */ `
  query ListFirstGatewayMessages(
    $gwid: String!
    $limit: Int
    $nextToken: String
  ) {
    listFirstGatewayMessages(
      gwid: $gwid
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gwid
        timestamp
        battery
        date
        messageCount
        messageType
        power
      }
      nextToken
    }
  }
`;
export const getSensors = /* GraphQL */ `
  query GetSensors($id: String!) {
    getSensors(id: $id) {
      id
      account
      accountId
      codeMatch
      deviceId
      deviceName
      deviceType
      networkId
      sensorCode
      sensorType
      name
      version
      heartbeat
      linkModeTrans
      heartbeatAware
      heartbeatAssessments
      maxThresh
      maxTimeThresh
      minThresh
      minTimeThresh
      networkName
      sync
      hysteresis
      powerOptions
      rearmTime
      showDewPoint
      awareBuffer
      awareStateMode
      scaleValue1
      scaleValue2
      scaleLabel
      pulseEdgeDetection
      stabilizationDelay
      accumulate
      averagingInterval
      batteryThresh
      notifyStatus
      pendingChange
      sensitivity
      notificationId
      notificationName
      notificationStatus
      manufacturer
    }
  }
`;
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: TableSensorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        accountId
        codeMatch
        deviceId
        deviceName
        deviceType
        gatewayId
        networkId
        sensorCode
        sensorType
        name
        version
        heartbeat
        linkModeTrans
        heartbeatAware
        heartbeatAssessments
        maxThresh
        minThresh
        sync
        hysteresis
        powerOptions
        rearmTime
        showDewPoint
        awareBuffer
        awareStateMode
        scaleValue1
        scaleValue2
        scaleLabel
        pulseEdgeDetection
        stabilizationDelay
        accumulate
        averagingInterval
        batteryThresh
        notifyStatus
        inactiveThresh
        notifyMeasurement
        notifyInactive
        notifyBattery
        ackMeasurement
        ackBattery
        ackInactive
        awareStateMeasurement
        awareStateBattery
        awareStateInactive
        notificationRestartTime
        lastMeasurementNotify
        lastBatteryNotify
        lastInactiveNotify
        inactiveNotificationId
        filter
        pendingChange
        sensitivity
        notificationId
        notificationName
        notificationAck
        notificationStatus
        manufacturer
      }
      nextToken
    }
  }
`;
export const querySensorsByAccountIndex = /* GraphQL */ `
  query QuerySensorsByAccountIndex(
    $account: String!
    $limit: Int
    $nextToken: String
  ) {
    querySensorsByAccountIndex(
      account: $account
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        deviceId
        message
        maxThresh
        maxThresh2
        maxTimeThresh
        manufacturer
        linkModeTrans
        account
        accumulate
        accountId
        assignedUsers {
          name
          username
          contactMethod
          contactValue
          notificationDelayMinutes
          notificationDelaySeconds
        }
        codeMatch
        dataValueOffset
        deviceName
        deviceType
        firmwareVersion
        freezeAlarmStatus
        freezeAlarmThresh
        gatewayId
        heartbeat
        heartbeatAssessments
        heartbeatAware
        id
        minThresh
        minThresh2
        minTimeThresh
        monnitGeneration
        name
        networkId
        networkName
        notificationId
        notificationName
        notificationPausedUntilDateTime
        notificationStatus
        notificationSchedule
        notificationType
        pendingChange
        scaleLabel
        scaleValue1
        scaleValue2
        sensorCode
        sensorType
        version

      }
      nextToken
    }
  }
`;
export const listSensorsByNetwork = /* GraphQL */ `
  query ListSensorsByNetwork(
    $networkId: Int!
    $limit: Int
    $nextToken: String
  ) {
    listSensorsByNetwork(
      networkId: $networkId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        accountId
        codeMatch
        deviceId
        deviceName
        deviceType
        networkId
        sensorCode
        sensorType
        name
        version
        heartbeat
        linkModeTrans
        heartbeatAware
        heartbeatAssessments
        maxThresh
        minThresh
        sync
        hysteresis
        powerOptions
        rearmTime
        showDewPoint
        awareBuffer
        awareStateMode
        scaleValue1
        scaleValue2
        scaleLabel
        pulseEdgeDetection
        stabilizationDelay
        accumulate
        averagingInterval
        batteryThresh
        notifyStatus
        inactiveThresh
        notifyMeasurement
        notifyInactive
        notifyBattery
        ackMeasurement
        ackBattery
        ackInactive
        awareStateMeasurement
        awareStateBattery
        awareStateInactive
        notificationRestartTime
        lastMeasurementNotify
        lastBatteryNotify
        lastInactiveNotify
        inactiveNotificationId
        filter
        pendingChange
        sensitivity
        notificationId
        notificationName
        notificationAck
        notificationStatus
        manufacturer
      }
      nextToken
    }
  }
`;
export const getSensorMessages = /* GraphQL */ `
  query GetSensorMessages($senid: String!, $timestamp: Int!) {
    getSensorMessages(senid: $senid, timestamp: $timestamp) {
      senid
      timestamp
      SignalPercent
      battery
      dataType
      dataValue
      dataValues
      date
      gwid
      sensorType
      data
      version
    }
  }
`;
export const listSensorMessages = /* GraphQL */ `
  query ListSensorMessages(
    $filter: TableSensorMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensorMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        senid
        timestamp
        SignalPercent
        battery
        dataType
        dataValue
        dataValues
        date
        gwid
        sensorType
        data
      }
      nextToken
    }
  }
`;
export const listSensorMessagesByDay = /* GraphQL */ `
  query ListSensorMessagesByDay(
    $senid: String!
    $startTime: Int!
    $endTime: Int!
    $limit: Int
    $nextToken: String
  ) {
    listSensorMessagesByDay(
      senid: $senid
      startTime: $startTime
      endTime: $endTime
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date
        senid
        timestamp
        battery
        data
        dataType
        dataValue
        dataValues
        date
        gwid
        name
        pendingChange
        sensorType
        SignalPercent
        signal
        scaleValue1
        scaleValue2
        version
      }
      nextToken
    }
  }
`;
export const listFirstSensorMessages = /* GraphQL */ `
  query ListFirstSensorMessages(
    $senid: String!
    $limit: Int
    $nextToken: String
  ) {
    listFirstSensorMessages(
      senid: $senid
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        battery
        data
        dataValue
        dataValues
        dataType
        date
        gwid
        name
        networkId
        notificationStatus
        pendingChange
        scaleValue1
        scaleValue2
        senid
        sensorState
        sensorType
        SignalPercent
        signal
        timestamp
        topic
        version
      }
      nextToken
    }
  }
`;

export const listFirstNotifications = /* GraphQL */ `
  query listFirstNotifications (
    $notificationId: String!
    $limit: Int
    $nextToken: String
  ) {
    listFirstNotifications(
      notificationId: $notificationId
      limit: $limit
      nextToken: $nextToken
      ) {
      items {
        notificationId
        timestamp
        result
        message
        userFirstLastName
        deviceId
      }
      nextToken
    }
  }
`;

export const listNotificationsByDay = /* GraphQL */ `
  query ListNotificationsByDay (
    $notificationId: String!
    $startTime: Int!
    $endTime: Int!
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByDay(
      notificationId: $notificationId
      startTime: $startTime
      endTime: $endTime
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        notificationId
        timestamp
        result
        message
        userFirstLastName
        deviceId
      }
      nextToken
    }
  }
`;

export const getDeviceList = /* GraphQL */ `
  query GetDeviceList($deviceType: String!, $deviceId: String!) {
    getDeviceList(deviceType: $deviceType, deviceId: $deviceId) {
      deviceId
      deviceType
      account
      name
      networkId
      version
      heartbeat
      linkModeTrans
      heartbeatAware
      sync
      heartbeatAssessments
      maxThresh
      minThresh
      powerOptions
      showDewPoint
      rearmTime
      awareBuffer
      awareStateMode
      hysteresis
      scaleValue1
      scaleValue2
      scaleLabel
      pulseEdgeDetection
      stabilizationDelay
      accumulate
      averagingInterval
      batteryThresh
      notifyStatus
      inactiveThresh
      notifyMeasurement
      notifyInactive
      notifyBattery
      ackMeasurement
      ackBattery
      ackInactive
      awareStateMeasurement
      awareStateBattery
      awareStateInactive
      notificationRestartTime
      lastMeasurementNotify
      lastBatteryNotify
      lastInactiveNotify
      filter
      resetInterval
      pendingChange
      sensitivity
    }
  }
`;
export const listDeviceLists = /* GraphQL */ `
  query ListDeviceLists(
    $filter: TableDeviceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        deviceId
        deviceType
        account
        name
        networkId
        version
        heartbeat
        linkModeTrans
        heartbeatAware
        sync
        heartbeatAssessments
        maxThresh
        minThresh
        powerOptions
        showDewPoint
        rearmTime
        awareBuffer
        awareStateMode
        hysteresis
        scaleValue1
        scaleValue2
        scaleLabel
        pulseEdgeDetection
        stabilizationDelay
        accumulate
        averagingInterval
        batteryThresh
        notifyStatus
        inactiveThresh
        notifyMeasurement
        notifyInactive
        notifyBattery
        ackMeasurement
        ackBattery
        ackInactive
        awareStateMeasurement
        awareStateBattery
        awareStateInactive
        notificationRestartTime
        lastMeasurementNotify
        lastBatteryNotify
        lastInactiveNotify
        filter
        resetInterval
        pendingChange
        sensitivity
      }
      nextToken
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($id: String!, $timestamp: Int) {
    getNotifications(id: $id, timestamp: $timestamp) {
      id
      timestamp
      ack
      date
      sendTypes
      deviceType
      sentMessage
      notificationType
      deviceId
      notificationGroupId
      sendType
      notificationActive
      userFirstLastName
      result
      message
      contactAddress
      contactMethod
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: TableNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        ack
        date
        sendTypes
        receiverName
        deviceMessage
        deviceType
        sentMessage
        notificationType
        deviceId
        notificationGroupId
        sendType
        notificationActive
        userFirstLastName
        result
        message
        contactAddress
        contactMethod
      }
      nextToken
    }
  }
`;
export const listNotificationsByDevice = /* GraphQL */ `
  query ListNotificationsByDevice(
    $deviceId: String!
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByDevice(
      deviceId: $deviceId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        ack
        date
        sendTypes
        receiverName
        deviceMessage
        deviceType
        sentMessage
        notificationType
        deviceId
        notificationGroupId
        sendType
        notificationActive
        userFirstLastName
        result
        message
        contactAddress
        contactMethod
      }
      nextToken
    }
  }
`;

export const getDeviceShadows = /* GraphQL */ `
  query GetDeviceShadows($deviceId: String!) {
    getDeviceShadows(deviceId: $deviceId) {
      deviceId
      Payload
    }
  }
`;
export const listDeviceShadows = /* GraphQL */ `
  query ListDeviceShadows(
    $filter: TableDeviceShadowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceShadows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        deviceId
        Payload
      }
      nextToken
    }
  }
`;
export const getNotificationUsers = /* GraphQL */ `
  query GetNotificationUsers($username: String!, $notificationId: String!) {
    getNotificationUsers(username: $username, notificationId: $notificationId) {
      notificationId
      username
      notificationDetails
    }
  }
`;
export const listNotificationUsers = /* GraphQL */ `
  query ListNotificationUsers(
    $filter: TableNotificationUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        notificationId
        username
        notificationDetails
      }
      nextToken
    }
  }
`;
export const queryNotificationUsersByNotificationIdIndex = /* GraphQL */ `
  query QueryNotificationUsersByNotificationIdIndex(
    $notificationId: String!
    $limit: Int
    $nextToken: String
  ) {
    queryNotificationUsersByNotificationIdIndex(
      notificationId: $notificationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        notificationId
        username
        notificationDetails {
          email
          text
          push
          phone
        }
      }
      nextToken
    }
  }
`;
export const queryNotificationsByNotificationGroup = /* GraphQL */ `
  query QueryNotificationsByNotificationGroup(
    $notificationGroupId: String!
    $limit: Int
    $nextToken: String
  ) {
    queryNotificationsByNotificationGroup(
      notificationGroupId: $notificationGroupId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceId
        deviceType
        timestamp
        ack
        date
        sendTypes
        receiverName
        deviceMessage
        sentMessage
        notificationType
        notificationGroupId
        sendType
        notificationActive
        userFirstLastName
        result
        message
        contactAddress
        contactMethod
      }
      nextToken
    }
  }
`;
export const queryNotificationGroupsByAccount = /* GraphQL */ `
  query QueryNotificationGroupsByAccount(
    $account: String!
    $first: Int
    $after: String
    $limit: Int
  ) {
    queryNotificationGroupsByAccount(
      account: $account
      first: $first
      after: $after
      limit: $limit
    ) {
      items {
        id
        account
        freezeAlarmStatus
        freezeAlarmThresh
        sendType
        inactiveThresh
        maxThresh
        maxThresh2
        maxTimeThresh
        minThresh
        minThresh2
        minTimeThresh
        deviceType
        notificationType
        name
        message
        resetLimit
        networkId
        networkName
        units
        awareStateMode
        dataType
        alarmOnExitAlert
        alertWhen
        resendAttempts
        assignedDevices
        assignedUsers {
          name
          username
          contactMethod
          contactValue
          notificationDelayMinutes
          notificationDelaySeconds
        }
        notificationStatus
        notificationChain
        scaleValue1
        scaleValue2
        returnToNormalMessage
        sendReturnToNormalMessage
        }
      nextToken
    }
  }
`;
export const getAccountUser = /* GraphQL */ `
  query GetAccountUser($userId: String!, $accountId: String!) {
    getAccountUser(userId: $userId, accountId: $accountId) {
      userId
      accountId
      username
      email
      landLine
      cellPhone
      cellPhoneProvider
      cellPhoneProviderData
      firstName
      lastName
      accounts
      permissions
      emailVerified
      phoneVerified
      cellVerified
      timezone
      parentAccountId
      accountName
      notes
      dateCreated
      createdAt
      updatedAt
      useEmailToText
    }
  }
`;
export const listAccountUsers = /* GraphQL */ `
  query ListAccountUsers(
    $userId: String
    $accountId: ModelStringKeyConditionInput
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccountUsers(
      userId: $userId
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        accountId
        username
        email
        landLine
        cellPhone
        cellPhoneProvider
        cellPhoneProviderData
        emailToTextAddress
        firstName
        lastName
        accounts
        permissions
        emailVerified
        phoneVerified
        cellVerified
        timezone
        parentAccountId
        accountName
        notes
        dateCreated
        useEmailToText
      }
      nextToken
    }
  }
`;

export const getUserAccountId = /* GraphQL */ `
  query GetUserAccountId(
    $userId: String
  ) {
    listAccountUsers(userId: $userId) {
      nextToken
      items {
        accountId
        accountName
        accounts
        cellPhone
        cellVerified
        dateCreated
        email
        emailVerified
        firstName
        landLine
        lastName
        notes
        parentAccountId
        permissions
        phoneVerified
        timezone
        userId
        username
      }
    }
  }
`;
import React, {useState, useEffect} from 'react';

import { Container, Row, Form, Col } from 'react-bootstrap';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import getCellProvider from './CellProviderLookup.js'

export default function UserInfoForm(props) {
  const [showProgress, setShowProgress] = useState(false);
  // I don't like this weirdness with cellPhone / cellPhone1, but it's my workaround for the issues w/ hook for getting the cell number.
  const [cellPhone, setCellPhone] = useState(null);
  const [cellPhone1, setCellPhone1] = useState(props.cellPhone);

  useEffect(() => {
    const fetchData = async () =>{
      const result = await httpPost(cellPhone);
      // setCellData(result);
      handleSetCellPhoneProvider(result);
    }
    cellPhone&&fetchData();
  },[cellPhone]);

  const handleSetCellPhoneProvider = (data) => {
    console.log(data);
    props.setCellPhoneProvider(data.carrier_name);
    props.setCellPhoneProviderData(JSON.stringify(data));
    props.setEmailToTextAddress(getCellProvider(data));
    setShowProgress(false);
  }

  const handleGetCellPhoneProviderData = () => {
    setShowProgress(true);
    setCellPhone(cellPhone1); 
  }

  // TODO: change this to a controlled form instead of an uncontrolled HTML form
  return (
    <Container fluid>
      <Form id={props.id} onSubmit={props.handleSubmit} >
        <Form.Group className="mb-3" controlId="formCompany">
          <Form.Label>Account / Company Name</Form.Label>
          <Form.Control type="text" defaultValue={props.accountName} readOnly />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label>First Name*</Form.Label>
              <Form.Control type="text" defaultValue={props.firstName} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control type="text" defaultValue={props.lastName} required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email*</Form.Label>
          <Form.Control type="email" defaultValue={props.email} required />
        </Form.Group>
        <Row>
          <Col>
          <Form.Group className="mb-3" controlId="formCellPhone">
            <Form.Label>Cell Phone</Form.Label>
            <Form.Control type="text" placeholder="999-999-9999" defaultValue={props.cellPhone} maxLength={12} onChange={(e) => setCellPhone1(e.target.value)}name="cellPhone" />
          </Form.Group>
          </Col>
          <Form.Group className="mb-3" controlId="formCellPhoneProvider">
            <Row>
              <Col>
                <Form.Label>Provider</Form.Label>
                <Button onClick={handleGetCellPhoneProviderData}>
                    Check provider {showProgress&&<CircularProgress // check the cell phone provider here when you press the button.
                    variant="indeterminate"
                    disableShrink
                    size="small"
                    thickness={4}
                />}
              </Button>
            </Col>
            </Row>
            <Form.Control type="text" placeholder="N/A" defaultValue={props.cellPhoneProvider} readOnly />
            <Form.Check 
                type="checkbox"
                id="checkbox-email"
                label="Email to Text?"
                onChange={ (e) => props.setUseEmailToText(e.target.checked)}
                checked={ props.useEmailToText }
                disabled={!props.cellPhoneProvider}
              />
              {props.emailToTextAddress}
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formLandLine">
              <Form.Label>Landline</Form.Label>
              <Form.Control type="text" placeholder="9999999999" defaultValue={props.landLine} />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="formPermissions">
          <Form.Label>Permissions</Form.Label>
          <Form.Select defaultValue="2" aria-label="select permissions">
            <option value="2">Account admin</option>
            <option value="3">Edit device settings only</option>
            <option value="4">Read only</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formNotes">
          <Form.Label>Notes</Form.Label>
          <Form.Control type="text" defaultValue={props.notes} />
        </Form.Group>
      </Form>
    </Container>
  );
}


async function httpPost(cellPhone) {
  if(!cellPhone) { return;}

  try {
    const url = 'https://3ineqi3mdsjh4xsfbzbks3mnma0colem.lambda-url.us-east-1.on.aws/';
    const response = await fetch(url, {
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "phoneNumber": cellPhone }) // Replace with the data you want to send in the request body
    });

    if (!response) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    const cellForRegex = cellPhone;
    responseData.cellPhone = cellForRegex.replace(/[-()]*/g, ''); // remove all the special characters.
    return responseData;
  } catch (error) {
    console.error('Error occurred:', error);
  }
}


import { API, graphqlOperation } from "aws-amplify";
import { 
  listFirstNotifications,
  listNotificationsByDay 
} from "../graphql/queries";


export async function ListFirstNotifications(notificationId, nextToken, limit) {
  const apiData = await API.graphql(graphqlOperation(listFirstNotifications, { notificationId: notificationId, limit: limit || 1, nextToken: nextToken }));
  return apiData;
}

export async function ListNotificationsByDay(id, startTime, endTime, nextToken, limit) {
  //const apiData = await API.graphql(graphqlOperation(listSensorMessages, {filter: {senid: {eq: id}}, nextToken: nextToken, limit: 1000}));
  const apiData = await API.graphql(
    graphqlOperation(listNotificationsByDay, { notificationId: id, startTime: startTime, endTime: endTime, limit: limit, nextToken: nextToken })).catch((err) => console.log("graphQL error: ", err));
  return apiData;
}
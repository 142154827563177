import React from 'react';
import { ListAccounts } from '../Database.jsx';
import { Form } from 'react-bootstrap';

export default class AccountSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allAccounts: null,
      isLoaded: false,
      error: null,
    }
  }

  componentDidMount() {
    if(!localStorage.getItem("userId").includes("@smartbarn.io")) {
      let account=[{id: localStorage.getItem("currentAccount"), name: localStorage.getItem("currentCompanyName")}]
      this.setState({
        allAccounts: account,
        isLoaded: true,
      });
    }
    else {
      ListAccounts(200, null)
      .then(
        (result) => {
          // console.log("allAccounts", result.data.listAccounts.items)
          this.setState({
            allAccounts: result.data.listAccounts.items,
            isLoaded: true,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
        });
        }
      )
    }
  }

  render() {
    if(!this.state.isLoaded){
      return(null);
    }
    // this.state.allAccounts.sort((a, b) => {
    //   const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    //   const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }
    // });

    this.state.allAccounts.sort((a,b)=> ((a.name.localeCompare(b.name))));


    var accounts = this.state.allAccounts.map(element => (
      <option key={element.id} value={element.id}>{element.name}</option>));
    // console.log("ACCOUNTS", accounts);

    return (
      <Form.Group className="mb-3" controlId="formUserAccount">
        <Form.Label>User Account</Form.Label>
        <Form.Select aria-label="select user account" onChange={ (e) => this.props.setAccount(e) } defaultValue={localStorage.getItem("currentAccount")} >
          {accounts}
        </Form.Select>
      </Form.Group>
    );
  }
}
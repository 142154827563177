import React from "react";
import "./Administration.css";
import LinkedAccountsTable from "./LinkedAccountsTable"
import { Container } from "react-bootstrap";

export default function LinkedAccounts (props) {
  if(!props.allAccounts){ return <></>}
  return (
    <Container fluid>
      <LinkedAccountsTable allAccounts = { props.allAccounts } />
    </Container>
  );
}

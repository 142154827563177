import React from 'react'
// import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './Charts.css'

// date-fns
import 'chartjs-adapter-date-fns';
import endOfDay from 'date-fns/endOfDay'

import { Line } from 'react-chartjs-2';
import Container from 'react-bootstrap/Container';

export default function CurrentZeroToTwentyAmpChart(props) {
  if(!props.isLoaded){
    return null;
  }
  var maxDataPoint;
  const datapoints = props.messages.map(x => {
    const dataValues = JSON.parse(x.dataValues);
    const data = parseFloat(dataValues["Maximum Current"]).toFixed(1)
    maxDataPoint = Math.max(maxDataPoint, data);
    return {
      x: new Date(x.timestamp*1000),
      y: data
    }
  })

  const data = {
    datasets: [
      {
        datalabels: {
          labels: {
            title: null
          }
        },
        id: 1,
        label: "amps",
        type: 'line',
        backgroundColor: 'rgb(116, 170, 67)',
        borderColor: 'rgb(116, 170, 67)',
        data: datapoints,
        pointRadius: 0,
        borderWidth: 2,
        animation: false, // set here and not in options? weird...
      },
    ]
  };

  var options = {
    animation: false,
    parsing: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // decimation: decimation,
      tooltip: {
        enabled: true,
      },
      interaction: {
        mode: 'nearest'
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: props.minThresh ,
            yMax: props.minThresh,
            borderColor: 'red',
            borderWidth: 2,
          },
          line2: {
            type: 'line',
            yMin: props.maxThresh,
            yMax: props.maxThresh,
            borderColor: 'red',
            borderWidth: 2,
          }
        }
      }
    },
    scales: {
      x: {
        adjustScaleRange: false,
        min: props.startDate,
        max: endOfDay(props.endDate),
        type: 'time',
        time: { unit: 'day' },
      },
      y: {
        suggestedMin: 0,
        suggestedMax: 20,
        type: 'linear',
        labels: null,
      }
    }
  }

  return(
    <Container fluid className='sensor-charts' >
      <Line data = {
          data
        } 
        options = {
            options 
        }
        key={ props.id }
        />
    </Container>
  );
}
import React, {useState} from 'react';
import AccountUserEditModal from "./AccountUserEditModal";
// import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Stack from 'react-bootstrap/Stack'

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from '@mui/material/Typography';

import { DeleteAccountUser } from "../Database.jsx";
import DeleteUserModal from "./DeleteUserModal.jsx";

export default function AccountUser (props) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const handleDeleteUser = (e) => {
    DeleteAccountUser(
      {
        accountId: props.accountId, userId: props.userId
      }
    )
    .then(
      (response) => (
        (!response.data.deleteAccountUser) ? alert("Error deleting user!") : alert("User Deleted Successfully!"), window.location.reload()
      )
    )
  }

  var permissions = "";
  switch(props.permissions){
    case 1:
      permissions="Super User";
      break;

    case 2:
      permissions="Account Admin";
      break;
    
    case 3:
      permissions="Edit Device Settings Only";
      break;
    
    case 4:
      permissions="Read only";
      break;
    default:
      permissions="Read only";
  }

  //TODO: URGENT: NEED RESET PW BUTTON ON ACCOUNTS PAGE
  return (
    <Col className='account-user-card'>
      <Card variant="outlined" key={ props.userId } >
        <CardContent sx={{ height: 200 }} >
        <Typography sx={{ fontSize: 18 }} color="text.secondary" >{props.firstName} {props.lastName}</Typography>
          <Stack >
            <div><b>ID:</b> {props.userId}</div>
            <div><b>Email:</b> {props.email}</div>
            <div><b>Cell Phone:</b> {props.cellPhone ? props.cellPhone : "N/A"}</div>
            <div><b>Cell Provider: </b>{props.cellPhoneProvider ? props.cellPhoneProvider : "N/A"}</div>
            <div><b>Landline:</b>{props.landLine ? props.landLine : "N/A"}</div>
            <div><b>Permissions:</b> {permissions} </div>
            <Typography variant="body2" color="text.secondary" component="div">{props.notes}</Typography>
          </Stack>
        </CardContent>
        <AccountUserEditModal 
          accountID={props.accountId} 
          accountName={localStorage.getItem("currentCompanyName")} 
          firstName={props.firstName} 
          lastName={props.lastName}
          email={props.email}
          cellPhone={props.cellPhone}
          cellPhoneProvider={props.cellPhoneProvider}
          cellPhoneProviderData={props.cellPhoneProviderData}
          landLine={props.landLine}
          notes={props.notes}
          showEditModal={showEditModal}
          id="updateAccountUser"
          userId={props.userId}
          useEmailToText={props.useEmailToText}
          emailToTextAddress={props.emailToTextAddress}
          setShowEditModal={setShowEditModal}
        />
        <CardActions>
          <Button variant="contained" size="small" onClick={() => setShowEditModal(true)}>Edit<EditIcon/></Button>
          <Button variant="contained" color="error" size="small" onClick={() => setShowDeleteUserModal(true)}>Delete<DeleteForeverIcon style={{ marginRight: "5px" }} /></Button>
          <DeleteUserModal
            show={showDeleteUserModal}
            onHide={() => setShowDeleteUserModal(false)}
            firstName={props.firstName}
            lastName={props.lastName}
            handleDeleteUser={handleDeleteUser}
          />
        </CardActions>
      </Card>
    </Col>
  );
}
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./App.js";
import * as serviceWorker from "./serviceWorkerRegistration";
import Header from "./Header";

const container = document.getElementById('app');
const root = createRoot(container);
const isLocalhost = window.location.hostname === 'localhost';
root.render(
  <div style={{ minHeight: "675px", backgroundColor:"white", padding: "0px" }}>
    {isLocalhost && (
      <div style={{ background: 'red', padding: '10px', color: 'white', textAlign: 'center' }}>
        LOCALHOST TEST INSTANCE
      </div>
    )}
    <Header />
    <App />
  </div>
);

serviceWorker.register();

import React, { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Spinner from 'react-bootstrap/Spinner'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';

import SensorChart from "./SensorComponents/Charts/SensorChart";

import SensorEditConfiguration from "./SensorEditConfiguration";
import SensorNotificationTab from './SensorNotificationTab';
import NotificationScheduleTab from './NotificationScheduleTab';

import { startOfDay, format} from 'date-fns'

import { GetSensorMessagesByDay, DeleteSensor } from "../Database.jsx";
import useGetUserNotes from "../hooks/useGetUserNotes";

import DatePicker from "./DatePicker";
import CalculateBatteryPercent from "../Utilities/CalculateBatteryPercent";

import { DataGrid, GridToolbarExport, GridToolbarContainer, gridClasses } from '@mui/x-data-grid';

import FormatHumidityDataValues from "../Utilities/FormatHumidityDataValues";
import DeleteDeviceModal from "./DeleteDeviceModal";
import UserNotesTab from "./SensorComponents/UserNotesTab/UserNotesTab.jsx";

export default function SensorBody(props) {
  const [isLoaded, setIsLoaded] = useState(null);
  const [error, setError] = useState(null);
  const [tabKey, setTabKey] = useState('charts');
  const [sensorMessages, setSensorMessages] = useState([]);
  
  const handleSensorMessages = (data) => {
    setSensorMessages(sensorMessages => [...sensorMessages, ...data]);
  }
  
  const [startDate, setStartDate] = useState(startOfDay(new Date(new Date().setDate(new Date().getDate()-1))));
  const [endDate, setEndDate] = useState(new Date());

  const [result, setResult] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleStartDateChange = (e) => {
    setSensorMessages([]);
    setIsLoaded(false);
    setStartDate(e);
  }

  const handleEndDateChange = (e) => {
    setSensorMessages([]);
    setIsLoaded(false);
    setEndDate(e);
  }

  const handleDeleteSensor = () => {
    // e.preventDefault();

      DeleteSensor(props.id)
      .then(
        (result) => {
          alert("Sensor deleted!"); //TODO: alert popover
          setResult(result);
          window.location.reload();
      },
      (error) => {
        setError(error);
        alert("Error deleting sensor");
      }
    );
  }

  function getLastSensorMessageForTable(parameters) {
    const data = parameters.data;
    const dataValues = JSON.parse(parameters.dataValues);
    const scaleValue1 = props.scaleValue1;
    const scaleValue2 = props.scaleValue2;
    const sensorType = props.version;
    switch (sensorType.toLowerCase()) {
      case "currentzerototwentyamp":
        return parseFloat(dataValues["Maximum Current"]).toFixed(1) + " amps";
      case "dc_voltage_5v":
        const correctedValue = data*scaleValue1+scaleValue2*1
        return (correctedValue).toFixed(1);
      case "dry_contact":
      case "open_closed":
      case "button":
      case "magnet":
      case "open":
        return data == 1 ? scaleValue1 : scaleValue2;
      case "ac_voltage_500":
      case "voltage_meter_500":
      case "voltage":
        return data.toFixed(0) + ' ' + scaleValue1;   
      case "temperature":
        if(data < -40) { return "ERROR"}
        return scaleValue1.startsWith("F") ? ((data * 9.0/5.0) + 32.0).toFixed(1) + " F" : data.toFixed(1) + " C";
      case "humidity":
        return FormatHumidityDataValues(dataValues, scaleValue1);
      case "ranger_extender":
        return data + " sensors";
      default:
        return data + " " + scaleValue1;
    }
  }

  
  const dataGridColumns = 
    [
    { field: 'date', headerName: 'Date', width: '155', valueFormatter: ({ value }) => format(Date.parse(value), 'MM/dd/yyyy h:mma') },
    { field: 'data', headerName: 'Data', width: '125', valueGetter: ({row}) => getLastSensorMessageForTable(row) },
    { field: 'SignalPercent', width: '100', valueFormatter: ({value}) => value + "%", headerName: "Signal" },
    { field: 'battery', width: '75', valueFormatter: ({value}) => CalculateBatteryPercent(value) + "%" },
    { field: 'pendingChange', width: '10', renderCell: ({value}) => value ? <Tooltip title="Pending settings" placement="right"><BuildCircleIcon htmlColor="orange"/></Tooltip> : <></>, headerName: "" }
    ] 

    useEffect(() => {
    const getMoreSensorMessages = (nextToken) => {
      GetSensorMessagesByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), nextToken, 1440)
      .then(
        (result) => {
          handleSensorMessages(result.data.listSensorMessagesByDay.items);
          if(result.data.listSensorMessagesByDay.nextToken) {
            getMoreSensorMessages(result.data.listSensorMessagesByDay.nextToken);
          }
          else {
            setIsLoaded(true)
          }
        }, error => {
          setIsLoaded(true)
          setError(error)
        }
      );
    }

    GetSensorMessagesByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), null, 1440)
    .then(
      (result) => {
        setSensorMessages(result.data.listSensorMessagesByDay.items);
        if(result.data.listSensorMessagesByDay.nextToken) {
          getMoreSensorMessages(result.data.listSensorMessagesByDay.nextToken);
        }
        else {
          setIsLoaded(true)
        }
      }, error => {
        setIsLoaded(true)
        setError(error)
        
      }
    );
  }, [startDate, endDate, props.id]);  
  const userNotes = useGetUserNotes(props.id);

  return (
        <Tabs
          id="controlled-tab"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
          className="mb-3"
        >
          <Tab eventKey="charts" title="Chart">
            <Container fluid className="sensor-chart">
              <DatePicker 
                endDate={endDate} 
                startDate={startDate} 
                handleStartDateChange={(date) => handleStartDateChange(date)} 
                handleEndDateChange={(date) => handleEndDateChange(date)}
              />
              {(isLoaded) ? 
                <SensorChart 
                  key={ props.id }
                  deviceType={ props.deviceType }
                  version={ props.version }
                  messages={ sensorMessages } 
                  isLoaded={ isLoaded } 
                  deviceId={ props.deviceId } 
                  deviceName={ props.name } 
                  scaleValue1={ props.scaleValue1 } 
                  scaleValue2={ props.scaleValue2 }
                  minThresh={ props.minThresh }
                  maxThresh={ props.maxThresh }  
                  manufacturer={ props.manufacturer }
                  startDate={ startDate }
                  endDate={ endDate }
                /> : 
                <Container className="justify-content-center" style={{width:"100px"}}><Spinner animation="border" /></Container> }  
            </Container>
          </Tab>
          <Tab eventKey="history" title="History">
            <Container fluid style={{ height: '600', width: '100%' }}>
              <DatePicker endDate={endDate} startDate={startDate} handleStartDateChange={(date) => handleStartDateChange(date)} handleEndDateChange={(date) => handleEndDateChange(date)}/>
              {(isLoaded && tabKey === 'history') ? 
              <DataGrid 
                 components={{
                  Toolbar: CustomToolbar,
                }}
                pageSize={ 15 } 
                rows={ sensorMessages } 
                rowHeight={ 25 } 
                columns={ dataGridColumns } 
                getRowId={(row) => row.timestamp}
                rowsPerPageOptions={[15]}
                autoHeight
                disableSelectionOnClick 
              /> : 
              <Container className="justify-content-center" style={{width:"100px"}}><Spinner animation="border" /></Container> }
            </Container>
          </Tab>
          
          <Tab eventKey="edit" title="Edit">
            <Container fluid style={{paddingTop:"0px"}}>
            <Button variant="danger" onClick={() => setShowModal(true)}>
                Delete
                <DeleteForeverIcon htmlColor="black" style={{ marginRight: "5px" }} />
              </Button>

              <DeleteDeviceModal
                show={showModal}
                onHide={() => setShowModal(false)}
                deviceId={props.deviceId}
                deviceName={props.name}
                handleDeleteDevice={handleDeleteSensor}
              />

              <SensorEditConfiguration 
                assignedUsers={props.assignedUsers} //TODO: FIX JSON PARSE CRAP
                key={props.id}
                account={props.account} 
                accountId={props.accountId}
                dataValueOffset={props.dataValueOffset}
                deviceId={props.id}
                deviceType={props.deviceType}
                heartbeat={props.heartbeat}
                id={props.id}
                manufacturer={props.manufacturer}
                maxThresh={props.maxThresh}
                minThresh={props.minThresh}
                message={props.message}
                setPendingChange={ props.setPendingChange }
                sensorName={ props.sensorName }
                setSensorName={ props.setSensorName }
                networkId={props.networkId}
                notificationId={props.notificationId}
                notificationName={props.notificationName}
                notificationStatus={props.notificationStatus}
                scaleLabel={ props.scaleLabel }
                scaleValue1={props.scaleValue1}
                scaleValue2={props.scaleValue2}
                sensorCode={props.sensorCode}
                sensorMessage={ props.sensorMessage }
                version={props.version}
                />
            </Container>
          </Tab>
          <Tab eventKey="notifications" title="Notifications">
            <SensorNotificationTab 
              assignedUsers={props.assignedUsers} //TODO: FIX JSON PARSE CRAP
              account={props.account} 
              accountId={props.accountId}
              deviceId={props.id}
              deviceType={props.deviceType}
              heartbeat={props.heartbeat}
              id={props.id}
              manufacturer={props.manufacturer}
              maxThresh={props.maxThresh}
              maxThresh2={props.maxThresh2}
              minThresh={props.minThresh}
              minThresh2={ props.minThresh2 }
              message={props.message}
              name={props.sensorName}
              networkId={props.networkId}
              notificationId={props.notificationId}
              notificationName={props.notificationName}
              notificationPausedUntilDateTime={props.notificationPausedUntilDateTime}
              notificationStatus={props.notificationStatus}
              scaleValue1={props.scaleValue1}
              scaleValue2={props.scaleValue2}
              sensorCode={props.sensorCode}
              sensorMessage={ props.sensorMessage }
              version={props.version}
            />
          </Tab>
          {hasNotificationScheduleTab(props.deviceType)&&<Tab eventKey="schedule" title="Schedule">
            <Container style={{paddingBottom:5, paddingLeft: 0, paddingRight: 0}}>
              {tabKey === "schedule" ? <NotificationScheduleTab deviceId={props.id} notificationSchedule={props.notificationSchedule} /> : <></>}
            </Container>
          </Tab>}
          {/* only show calibration for temperature sensors for now  */}
          {/* {props.version === ("Temperature") ? <Tab eventKey = "calibration" title="Calibration">Calibration</Tab> : <></>} */}
          <Tab eventKey="userNotes" title="Notes">
           {tabKey === "userNotes"  && <UserNotesTab
              key={props.id}
              senid={props.id}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              endDate={endDate}
              startDate={startDate}
              userNotes={userNotes}
            />
            }
          </Tab>
        </Tabs>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function hasNotificationScheduleTab(deviceType){
  switch(deviceType){
    case "Dry_Contact":
    case "Pulse_Counter_1_Input":
    case "Filtered_Pulse_Counter_1_Input":
    case "FilteredPulseCounter64":
    case "Open_Closed":
      return true;
    default:
      return false;

  }
}
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { IOTData } from "../../services.js";
const iotdata = IOTData();
const iotCommandTopic = "cmd/gateway/battery/settings/deployed_MineServer";
// const iotCommandTopic = "cmd/gateway/battery/settings/akk_MineServer";


export default function GatewayAdminSettings(props) {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleCheckBoxChange = (e) => {props.setPowerStatusPin(e.target.checked)}
  const handleModemSerialNumChange = (e) => {props.setModemSerialNum(e.target.value)}

  const handleSaveAdminSettings = (event) => {
    event.preventDefault();
    const iot_data = {
      accountId: props.accountId,
      command: "adminSettings",
      deviceId: props.modemSerialNum.replace('MM',''), // delete MM because on the server, sensorsItem can only handle "Longs".
      deviceType: "BatteryBackup",
      gatewayId: props.gwid,
      gwid: props.gwid,
      name: props.deviceName + " battery backup - " + props.gwid,
      networkId: props.networkId,
      networkName: props.networkName,
      manufacturer: "SmartBarn",
      modemSerialNum: props.modemSerialNum,
      powerStatusPinEnabled: props.powerStatusPin,
      pendingChange: false,
      topic: iotCommandTopic,
      version: "Dry_Contact" // treat as a dry contact since it's just an open / closed.
    }
    // console.log("iot_data", iot_data);
    const params = {
      topic: iotCommandTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    
    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log(data);
        alert("Successfully updated settings.");
        handleCloseModal();
      }
    })
    // )
  }

  if(!localStorage.getItem('userId').includes('smartbarn.io')){
    return <></>
  }
  return(
    <>
      <Button size="small" onClick={handleShowModal} variant="contained">Admin Settings</Button>
      <Modal show={showModal} onHide={handleCloseModal} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>Admin Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.powerStatusPin} onChange={handleCheckBoxChange} />} label="Gateway power pin?" />
          <TextField id="outlined-basic" label="Gateway Serial Number" variant="outlined" defaultValue={props.modemSerialNum} onChange={handleModemSerialNumChange}/>
        </FormGroup>      
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveAdminSettings}>
              Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

import React from "react";
import CurrentAccountInfo from "../Account/CurrentAccountInfo";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

export default class LinkedAccountsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      searchFilter : "",
      searchList : []
    };
    this.childList = React.createRef();
  }

  componentDidMount() {
    this.parseAccounts();
  }

  parseAccounts() {
    var accounts = this.props.allAccounts;
    accounts.sort(function(a,b){
      if(a.name > b.name){
        return 1
      }else if(a.name === b.name){
        return 0
      }else{
        return -1
      }
    })

    this.setState({
      accounts: accounts,
      searchList : accounts
    });
  }

  filterAccounts = (event) => {
    var accounts = this.state.searchList;
    if(event.target.value !== ""){
      var valtest = event.target.value.toLowerCase().trim();
      accounts = this.state.searchList.filter(function(elm){
        var totest = elm.accountName.toLowerCase().trim()
        return totest.includes(valtest)
      })
    }

    this.setState({
      accounts: accounts,
      searchFilter : event.target.value
    });
  }

  render() {
    return (
      <DisplayAccounts accounts={this.state.accounts} user={this.props.user} />
    );
  }
}

function DisplayAccounts(props) {
  if(!props.accounts){
    return null;
  }
  var accountInfo = props.accounts.map(function (account) {
    return account !== null ? 
    <Account 
      key={account.accountId} 
      accountId={ account.accountId }
      accountName={ account.accountName }
      user={props.user} /> : null;
  });
  return accountInfo;
}

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
    };
  }

  displayContactInfo() {
    var contactInfo = "";
    if (this.state.account.pointOfContact !== null) {
      contactInfo += this.state.account.pointOfContact;
      if (this.state.account.pointOfContactPhone !== null) {
        contactInfo += ": " + this.state.account.pointOfContactPhone;
      }
    } else {
      contactInfo = "None Available";
    }
    return contactInfo;
  }

  render() {
    return (
      <Container>
      <Row>
        <Col className="accounts-table-td" data-label="Company">
          <PageLink className="btn btn-link black-link-color proxy-link" location="/" title={this.props.accountName} account={this.props.accountId} />
        </Col>
        {/* <td className="accounts-table-td" data-label="Timezone">
          {this.state.account.timezone}
        </td> */}
        <Col className="accounts-table-td" data-label="Primary Contact">
          {/* {this.displayContactInfo()} */}
        </Col>
      </Row>
      </Container>
    );
  }
}

const PageLink = (props) => (
        <a
        href="/"
          type="button"
          className={typeof props.class === "undefined" ? "btn btn-link black-link-color" : props.class}
          onClick={() => {
            localStorage.setItem("currentAccount", props.account);
            // Check if proxying into an account.  Very different procedures if a proxy is called
            if (
              props.title !== "Overview" &&
              props.title !== "Notifications" &&
              props.title !== "Account" &&
              props.title !== "Administration" &&
              props.title !== "Manage Networks"
            ) {
              localStorage.setItem("proxyStatus", "active");
              CurrentAccountInfo.currentCompanyName = props.title;
              localStorage.setItem("currentCompanyName", props.title);
              // localStorage.setItem("currentAccount", props.account);
              CurrentAccountInfo.currentAccount = props.account;
              CurrentAccountInfo.allAccounts = [props.account];
              localStorage.setItem("allAccounts", props.account + "|");
            } else {
              CurrentAccountInfo.allAccounts = CurrentAccountInfo.mainAccounts;
              // var accountStr = "";
              // for (var account of CurrentAccountInfo.allAccounts) {
              //   accountStr += account + "|";
              // }
              localStorage.setItem("proxyStatus", "unactive");
              CurrentAccountInfo.currentCompanyName = CurrentAccountInfo.mainCompanyName;
              localStorage.setItem("currentCompanyName", CurrentAccountInfo.mainCompanyName);
              // localStorage.setItem("currentAccount", CurrentAccountInfo.mainAccounts[0]);
              CurrentAccountInfo.currentAccount = CurrentAccountInfo.mainAccounts[0];
            }
            if (props.title === CurrentAccountInfo.mainCompanyName) {
              CurrentAccountInfo.currentCompanyName = props.title;
              localStorage.setItem("CurrentCompanyName", props.title);
              // localStorage.setItem("currentAccount", CurrentAccountInfo.mainAccounts[0]);
              CurrentAccountInfo.currentAccount = CurrentAccountInfo.mainAccounts[0];
              localStorage.setItem("proxyStatus", "unactive");
            }
          }}
        >
          {props.title}
        </a>
);
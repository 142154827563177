import React, { useState, useEffect } from 'react';


/* GraphQL queries and mutations */
import { ListNotificationsByDay } from "./NotificationQueries";
import { Container } from "react-bootstrap";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { DataGrid, GridToolbarExport, GridToolbarContainer, gridClasses } from '@mui/x-data-grid';

import { format, startOfDay } from 'date-fns'

export default function NotificationHistory(props) {

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [notificationMessages, setNotificationMessages] = useState([]);

  const [startDate, setStartDate] = useState(startOfDay(new Date(new Date().setDate(new Date().getDate()-7))));
  const [endDate, setEndDate] = useState(new Date());

  const handleNotificationMessages = (data) => {
    setNotificationMessages(notificationMessages => [...notificationMessages, ...data]);
  }

  useEffect(() => {
    
    const getMoreNotifications = (nextToken) => {
      ListNotificationsByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), nextToken, 100)
      .then(
        (result) => {
          handleNotificationMessages(result.data.listNotificationsByDay.items);
          if(result.data.listNotificationsByDay.nextToken) {
            getMoreNotifications(result.data.listNotificationsByDay.nextToken);
          }
          else {
            setIsLoaded(true)
          }
        }, error => {
          setIsLoaded(true)
          setError(error)
        }
      );
    }

    ListNotificationsByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), null, 100)
    .then(
      (result) => {
        setNotificationMessages(result.data.listNotificationsByDay.items);
        if(result.data.listNotificationsByDay.nextToken) {
          getMoreNotifications(result.data.listNotificationsByDay.nextToken);
        }
        else {
          setIsLoaded(true)
        }
      }, error => {
        setIsLoaded(true)
        setError(error) 
      }
    );
  }, [startDate, endDate, props.id]);

  

  
  const columns = [
    { field: 'timestamp', width: '170',  
      valueFormatter: ({ value }) => (format(new Date(value*1000), "MM/dd/yyyy h:mma")), headerName: "Date" },
    { field: 'deviceId'},
    { field: 'message', width: '350'},
    { field: 'userFirstLastName', width: '150',
      headerName: "User" },
    { field: 'result', width: '200', headerName: "Result"},
  ];

  const handleStartDateChange = (e) => {
    // console.log("Start date", e)
    setStartDate(e);
    setIsLoaded(false);
    // setDateRange[0] = e;
  }

  const handleEndDateChange = (e) => {
    setEndDate(e);
    setIsLoaded(false);
  }
  
  // gets the last send time and converts it to a string so the user then can visually see the timestamp
  // show NA if the notification was never sent and is invalid.
  // set the status icon based on the state of the notification
  // check sensors for active notifications, then set status here.
  
  return (
    <Container fluid style={{ height: 600, width: '100%' }}>
      <DatePicker endDate={endDate} startDate={startDate} handleStartDateChange={(date) => handleStartDateChange(date)} handleEndDateChange={(date) => handleEndDateChange(date)}/>
        {(isLoaded) ? 
      <DataGrid 
        components={{
          Toolbar: CustomToolbar,
        }}
        pageSize={15} 
        rows={notificationMessages} 
        rowHeight={25} 
        columns={columns} 
        getRowId={(row) => row.timestamp}
        rowsPerPageOptions={[15]}
        autoHeight
        disableSelectionOnClick 
      /> : <Container><CircularProgress /></Container> }
    </Container>
  );
}

function DatePicker(props) {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate)

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate)
  },[props.startDate, props.endDate]);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={ "Start date"}
          value={startDate}
          onChange={(newValue) => {
            setStartDate(newValue)
          }}
          onAccept={(e) => {
            props.handleStartDateChange(e.getTime());
          }}
          renderInput={(params) => <TextField {...params} size={ "small" } style={{width:"120px", paddingRight:"2px"}} />}
        />

        <MobileDatePicker
          label={ "End date"}
          maxDate={new Date()}
          value={endDate}
          onChange={(newValue) => {
            setEndDate(newValue.getTime())
          }}
          onAccept={(e) => {
            props.handleEndDateChange(e);
          }}
          renderInput={(params) => <TextField {...params} size={ "small" } style={{width:"120px"}}/>}
        />
    </LocalizationProvider>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

// TODO: signal strength from router
//TODO: sensors that checked in to gateway / parent gateway

import React, { useState, useEffect } from "react";
import { Tabs, Tab, Button, Accordion, Row, Col, Container, Alert, Badge } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid'; //updated uuid method
import { format } from 'date-fns';
import GatewayHistory from "./GatewayHistoryTab";
import GatewayNotificationTab from "./GatewayComponents/GatewayNotificationTab";
import GatewaySettingsTab from "./GatewayComponents/GatewaySettingsTab";

import { ListRecentGatewayMessages } from "../Database.jsx";
import { IOTData } from "../services.js";

/* MUI ICON IMPORTS */
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import HelpIcon from '@mui/icons-material/Help';
import WarningIcon from '@mui/icons-material/Warning';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';


import "./Gateway.css"
import GatewaySensorListTab from "./GatewaySensorListTab";
import GatewayPowerStatus from "./GatewayComponents/GatewayPowerStatus";

const iotdata = IOTData();
const cmdTopic = "cmd/gateway/monnit/settings";

export default function Gateway (props) {
  const [latestGatewayMessage, setLatestGatewayMessage] = useState(null);
  const [latestMessageTime, setLatestMessageTime] = useState("--");
  const [loadGatewayMessagesFlag, setLoadGatewayMessagesFlag] = useState(false);
  const [gatewayName, setGatewayName] = useState(props.name);
  const [notificationStatus, setNotificationStatus] = useState(props.notificationStatus);
  const [tabKey, setTabKey] = useState('history');
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // gets the most recent gateway message
    ListRecentGatewayMessages(props.id, null, 1)
    .then(
        (result) => {
          // console.log("ListRecentGatewayMessages", result)
          if(result.data.listFirstGatewayMessages.items.length === 0){
            setLatestGatewayMessage([]);
            setIsLoaded(true);
          }
          else {
            setLatestGatewayMessage(result.data.listFirstGatewayMessages.items[0]);
            setLatestMessageTime(format(new Date(result.data.listFirstGatewayMessages.items[0].timestamp*1000), "MM/dd/yyyy h:mma"));
            setIsLoaded(true);
          }
        },
        (error) => {
         setIsLoaded(true);
         setError(error);
      }
    );
  }, [props.id]);
  
  if(!isLoaded){
    return null;
  }
  if(error) { console.log(error)}

  const handleAcknowledgeNotification = () => {
    // const json_string = {deviceId: props.deviceId, notificationStatus: "armed"}
    // const params = {
    const iot_data = {
      gwid: props.id,
      name: gatewayName,
      notificationStatus: "acknowledged"
    }
    // Send reform message to sensor topic
    const params = {
      topic: cmdTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log(data);
      }
    });
  
    setNotificationStatus("acknowledged");
    alert("Successfully acknowledged notification.");
  }

  const handleResetNotification = () => {
    // const json_string = {deviceId: props.deviceId, notificationStatus: "armed"}
    // const params = {
    const iot_data = {
      gwid: props.id,
      name: gatewayName,
      notificationStatus: "armed",
    }
    // Send reform message to sensor topic
    const params = {
      topic: cmdTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log(data);
      }
    });
    setNotificationStatus("armed");
    alert("Successfully reset notification.");

  }


  const alertOverlay = notificationStatus === "alerting" ?
  <><Alert className={"in"} key={uuidv4()} deviceid={props.id} variant={"warning"}>Gateway Notification Alerting!<Button className="ml-1" size="sm" onClick={() => handleAcknowledgeNotification()} variant="danger">Acknowledge</Button></Alert></>
  : notificationStatus === "acknowledged" ? <><Alert style={{padding:"2px"}} className={"in"} key={uuidv4()} deviceid={props.id} variant={"warning"}><Button className="ml-1" size="sm" onClick={() => handleResetNotification()} variant="warning">Reset Notification</Button></Alert></>
  : <></>;

  const afterExpectedCheckinTime = latestGatewayMessage.timestamp*1000 - Date.now()+(1000*60*(props.heartbeat+10)) < 0 ? true : false; // heartbeat + 10 minutes = inactive.
  
  return (
    <Accordion.Item eventKey={props.id}>
        <b style={{textAlign:"left"}}>{gatewayName} - {props.id}</b> - <p style={{fontSize:"small", display:"inline", fontStyle:"italic" }}>{props.networkName} </p>
        {/* <Badge pill>PAUSE</Badge> */}
        {alertOverlay}
      <Accordion.Header onClick={() => setLoadGatewayMessagesFlag(true)}> {/** TODO: onclick gateway history load */}
        <Col style={{padding:"0px"}}>
        <Row className="align-items-center" style={{filter: afterExpectedCheckinTime || latestMessageTime==='--' ? 'grayscale(100%)' : 'none'}}>
            <Col xs="1" lg="auto" style={{minWidth:"10px"}}>
              <StatusIcons
                  expectedCheckInTime={ Date.now()-(1000*60*10) }
                  notificationStatus={ notificationStatus } 
                  pendingChange={props.pendingChange}
                  timestamp={latestGatewayMessage.timestamp}
                  message={latestGatewayMessage}
                />
            </Col>
            <Col xs="3" lg="1">
              <img 
                title={"Gateway Icon" }
                className="gateway-image-column" 
                src="/Images/SensorImages/Smart Barn gateway.png" 
                alt="Smart Barn Gateway"            
              />
            </Col>
            <Col xs="1" lg="auto">
              <Row><Container fluid><SignalCellular4BarIcon sx={{ fontSize: 18 }} htmlColor="green"/></Container></Row>
              <GatewayPowerStatus />
              {/* <Row><Container fluid><PowerIcon sx={{ fontSize: 18 }} htmlColor="green"/></Container></Row> */}
            </Col>
            <Col xs="4" lg="4" style={{ paddingLeft:"10px", paddingRight:"0px", textAlign:"center" }}>
                <b>{latestMessageTime === '--' ? <>--</> : ((latestGatewayMessage.timestamp*1000+60*10*1000)+(1000*60*6)-Date.now()) < 0 ? "Offline!" : "Online"}</b>
            </Col>
            <Col style={{padding:"0px", textAlign:"center"}}>
                {latestMessageTime}
            </Col>
            <Col xs="3" sm="5" style={{ textAlign:"center", paddingLeft:"5px" }}>
              <Badge pill bg={ notificationStatus === "disarmed" || notificationStatus === "N/A" ? "secondary" : (latestGatewayMessage.timestamp*1000)+(1000*60*10)-Date.now() < 0 ? "danger" : notificationStatus === "armed" ? "success" : "secondary"} style={{width:"170px"}}>Notification: {notificationStatus}</Badge>
            </Col>
          </Row>
        </Col>
      </Accordion.Header>
      <Accordion.Body style={{padding:"0px"}}>
        <Container fluid>
          <Tabs 
            id="gateway-tabs" 
            activeKey={tabKey} 
            onSelect={(k) => setTabKey(k)} className="mb-3"
          >
            <Tab eventKey="history" title="History">
              <Container fluid style={{ height: 600, width: '100%' }}>
                { loadGatewayMessagesFlag && (<GatewayHistory id={props.id}></GatewayHistory>) }
              </Container>
            </Tab>
            <Tab eventKey="settings" title="Settings">
              <GatewaySettingsTab
                id={ props.id }
                account={ props.account }
                allSensors={ props.allSensors }
                deviceList={ props.deviceList }
                gatewayHeartbeat={ props.heartbeat }
                gatewayName={ gatewayName }
                networkId={ props.networkId }
                networkName={props.networkName}
                setGatewayName={ setGatewayName }
                version={ props.version }
              />
            </Tab>
            <Tab eventKey="notifications" title="Notifications">
              <GatewayNotificationTab 
                assignedUsers={props.assignedUsers} //TODO: FIX JSON PARSE CRAP
                account={props.account} 
                accountId={props.accountId}
                deviceId={props.id}
                deviceName={props.name}
                deviceType={props.deviceType}
                heartbeat={props.heartbeat}
                id={props.id}
                manufacturer={props.manufacturer}
                maxThresh={props.maxThresh}
                maxThresh2={props.maxThresh2}
                minThresh={props.minThresh}
                minThresh2={ props.minThresh2 }
                message={props.message}
                modemSerialNum={props.modemSerialNum}
                name={props.name}
                networkId={props.networkId}
                notificationId={props.notificationId}
                notificationName={props.notificationName}
                notificationStatus={props.notificationStatus}
                powerStatusPinEnabled={props.powerStatusPinEnabled}
                scaleValue1={props.scaleValue1}
                scaleValue2={props.scaleValue2}
                sensorCode={props.sensorCode}
                sensorMessage={ props.sensorMessage }
                version={props.version}
              />
            </Tab>
            <Tab eventKey="sensorList" title="Sensor list">
              <GatewaySensorListTab 
                id={props.id} 
                allSensors={props.allSensors} 
                deviceList={props.deviceList}
                networkId={props.networkId} 
                sensorsInNetwork={ props.sensorsInNetwork }
                tabKey={tabKey}
              />
            </Tab>  
          </Tabs>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function StatusIcons(props) {
  var statusChangeIcon = null;
  var statusIcon = null;
  var notificationIcon = null;

  if (props.pendingChange) {
    statusChangeIcon = <BuildCircleIcon sx={{ fontSize: 18 }} title={"Pending Settings Change"} htmlColor="orange" />;
  }
  if (props.message.length === 0) {
    statusIcon = <PowerSettingsNewIcon sx={{ fontSize: 18 }} title={"Off/No Messages Received"} htmlColor="gray" />;
  } 
  else if (props.timestamp*1000-props.expectedCheckInTime < 0) {
    statusIcon = <HelpIcon sx={{ fontSize: 18 }} title={"Unknown Status"} htmlColor="gray" />;
  } 
  else {
    statusIcon = <CheckCircleIcon sx={{ fontSize: 18 }} htmlColor="green"  />;
  }

  if(props.notificationStatus === "acknowledged" ) {
    notificationIcon = <WarningIcon sx={{ fontSize: 18 }} htmlColor="red" />;
  }
  else if (props.notificationStatus === "alerting") {
    notificationIcon = <WarningIcon sx={{ fontSize: 18 }} title={"alerting!"} htmlColor="red" />;
  }
  else if (props.notificationStatus === "disarmed") {
    notificationIcon = <DangerousIcon sx={{ fontSize: 18 }} title={"disarmed"} htmlColor="orange" />;
  }
  else if (props.notificationStatus === "armed") {
    notificationIcon = <AlarmOnIcon sx={{ fontSize: 18 }} title={"armed"} htmlColor="green" />;
  }
  return (
    <>
        <Row><Container fluid>{statusIcon}</Container></Row>
        <Row><Container fluid>{notificationIcon}</Container></Row>
        <Row><Container fluid>{statusChangeIcon}</Container></Row>   
    </>
  );
}


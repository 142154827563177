export default function GetDeviceIcon(deviceType) {
  switch (deviceType) {
    case "CurrentZeroToTwentyAmp":
      return "/Images/SensorImages/current0to20.png";
    case "Current_20mA":
      return "/Images/SensorImages/current_20ma.png";
    case "DC_Voltage_5V":
      return "/Images/SensorImages/dc_voltage_5v.png";
    case "Dry_Contact":
      return "/Images/SensorImages/dry_contact.png";
    case "Temperature":
      return "/Images/SensorImages/TEMPERATURE-BOX.png";
    case "Low_Temperature":
      return "/Images/SensorImages/low-temp-box.png";
    case "Humidity":
      return "/Images/SensorImages/Humidity-blue.png";
    case "Pressure300PSI":
      return "/Images/SensorImages/pressure.png";
    case "Filtered_Pulse_Counter_1_Input":
      return "/Images/SensorImages/PULSE-COUNTER-BOX.png";
    case "Pulse_Counter_1_Input":
      return "/Images/SensorImages/PULSE-COUNTER-BOX.png";
      case "AC_Voltage_500":
      case "Voltage_Meter_500":
      return "/Images/SensorImages/voltage_meter.png";
    case "Open_Closed": //TODO: check that this is the door open/closed sensor type vs. dry contact? does it matter?
      return "/Images/SensorImages/door.png";
    case "Pulse_Counter": //TODO: are there several subtypes? How to handle? -> maybe just update the SensorType when it checks in?
    case 'FilteredPulseCounter64':
      return "/Images/SensorImages/pulse_counter.png";
    case "Ranger_Extender":
      return "/Images/SensorImages/Smart Barn gateway.png";
    case "SmartBarnDairySensor":
      return "/Images/SensorImages/TEMPERATURE.png";
    default:
      return "/Images/SensorImages/smart-barn-hex-icon.png";
  }
}

import React, { useState, useEffect } from "react";
import format from 'date-fns/format';

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from 'react-bootstrap/Spinner'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';

import { startOfDay, endOfDay } from 'date-fns'

import { GetSensorMessagesByDay, DeleteSensor } from "../Database.jsx";
import DatePicker from "./DatePicker";

import { DataGrid, GridToolbarExport, GridToolbarContainer, gridClasses } from '@mui/x-data-grid';

import { GetThresholds } from "../SensorTypes.jsx";
import RangeExtenderEditConfig from "./RangeExtenderEditConfig";
import DeleteDeviceModal from "./DeleteDeviceModal.jsx";

export default function RangeExtenderBody(props) {

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [tabKey, setTabKey] = useState('history');
  const [sensorMessages, setSensorMessages] = useState([]);
  // const [deleteButton, setDeleteButton] = useState(true);
  
  const handleSensorMessages = (data) => {
    setSensorMessages(sensorMessages => [...sensorMessages, ...data]);
  }
  
  const [startDate, setStartDate] = useState(startOfDay(new Date(new Date().setDate(new Date().getDate()-1))));
  const [endDate, setEndDate] = useState(new Date());

  const [result, setResult] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleStartDateChange = (e) => {
    // console.log("Start date", e)
    setStartDate(e);
    setIsLoaded(false);
    // setDateRange[0] = e;
  }

  const handleEndDateChange = (e) => {
    setEndDate(e);
    setIsLoaded(false);
    // setDateRange[1] = e;
  }

  const handleDeleteSensor = () => {
    // e.preventDefault();

      DeleteSensor(props.id)
      .then(
        (result) => {
          alert("Sensor deleted!"); //TODO: alert popover
          setResult(result);
          window.location.reload();
      },
      (error) => {
        setError(error);
        alert("Error deleting sensor");
      }
    );
  }
  
  // const handleDeleteButton = (event) => {
  //   setDeleteButton(!(event.target.value.toLowerCase() === "yes"));
  // }

  const columns = [
    { field: 'date', width: '150', valueFormatter: ({ value }) => format(Date.parse(value), 'MM/dd/yy h:mm aa'), headerName: "Date" },
    { field: 'data', width: '125', valueFormatter: ({value}) => GetLastSensorMessageForTable(value, props.version, props.scaleValue1, props.scaleValue2)},
    { field: 'dataValues', width: '175', valueFormatter: ({value})=> JSON.parse(value).RxMsgBySubnet + " rec'd / " + JSON.parse(value).MSGQueueCount + " stored", headerName: "Messages" },
    { field: 'SignalPercent', width: '100', valueFormatter: ({value}) => value + "%", headerName: "Signal %" },{ field: 'battery', width: '150', valueFormatter: ({value}) => CalculateBatteryPercent(value) + "%" },
    { field: 'pendingChange', width: '10', renderCell: ({value}) => value ? <Tooltip title="Pending settings" placement="right"><BuildCircleIcon htmlColor="orange"/></Tooltip> : <></>, headerName: "" }
  ];

  useEffect(() => {
    const getMoreSensorMessages = (nextToken) => {
      GetSensorMessagesByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), nextToken, 1440)
      .then(
        (result) => {
          // console.log("ListFirstSensorMessagesBodyNextToken", result)
          console.log((startDate/1000).toFixed(0) + " " + (result.data.listSensorMessagesByDay.items[0].date));
          handleSensorMessages(result.data.listSensorMessagesByDay.items);
          if(result.data.listSensorMessagesByDay.nextToken) {
            getMoreSensorMessages(result.data.listSensorMessagesByDay.nextToken);
          }
          else {
            setIsLoaded(true)
          }
        }, error => {
          setIsLoaded(true)
          setError(error)
        }
      );
    }
    // console.log("StartDate", (startDate/1000).toFixed());
    GetSensorMessagesByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), null, 1440)
    .then(
      (result) => {
        // console.log("ListFirstSensorMessagesBody", result)
        setSensorMessages(result.data.listSensorMessagesByDay.items);
        if(result.data.listSensorMessagesByDay.nextToken) {
          getMoreSensorMessages(result.data.listSensorMessagesByDay.nextToken);
        }
        else {
          setIsLoaded(true)
        }
      }, error => {
        setIsLoaded(true)
        setError(error)
        
      }
    );
  }, [startDate, endDate, props.id]);



  // if(!isLoaded){
  //   return null;
  // }

  // const maxData = Math.max(...sensorMessages.map(o => o.data))
  // const minData = Math.min(...sensorMessages.map(o => o.data))
  // console.log("MAX", maxData);
  // console.log("MAX", minData);
  

  return (
        <Tabs
          id="controlled-tab"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
          className="mb-3"
        >
          <Tab eventKey="history" title="History">
            <Container fluid style={{ height: '600', width: '100%' }}>
              <DatePicker endDate={endDate} startDate={startDate} handleStartDateChange={(date) => handleStartDateChange(date)} handleEndDateChange={(date) => handleEndDateChange(date)}/>
              {(isLoaded && tabKey === 'history') ? 
              <DataGrid 
                 components={{
                  Toolbar: CustomToolbar,
                }}
                pageSize={15} 
                rows={sensorMessages} 
                rowHeight={25} 
                columns={columns} 
                getRowId={(row) => row.timestamp}
                rowsPerPageOptions={[15]}
                autoHeight
                disableSelectionOnClick 
              /> : 
              <Container className="justify-content-center" style={{width:"100px"}}><Spinner animation="border" /></Container> }
            </Container>
          </Tab>
          
          <Tab eventKey="edit" title="Edit">
            <Container fluid style={{paddingTop:"0px"}}>
            <Button variant="danger" onClick={() => setShowModal(true)}>
                Delete
                <DeleteForeverIcon htmlColor="black" style={{ marginRight: "5px" }} />
              </Button>

              <DeleteDeviceModal
                show={showModal}
                onHide={() => setShowModal(false)}
                name={props.name}
                handleDeleteDevice={handleDeleteSensor}
              />

              <RangeExtenderEditConfig 
                assignedUsers={props.assignedUsers} //TODO: FIX JSON PARSE CRAP
                key={props.id}
                account={props.account} 
                accountId={props.accountId}
                deviceId={props.id}
                deviceType={props.deviceType}
                heartbeat={props.heartbeat}
                id={props.id}
                manufacturer={props.manufacturer}
                maxThresh={props.maxThresh}
                minThresh={props.minThresh}
                message={props.message}
                name={props.name}
                networkId={props.networkId}
                notificationId={props.notificationId}
                notificationName={props.notificationName}
                notificationStatus={props.notificationStatus}
                scaleValue1={props.scaleValue1}
                scaleValue2={props.scaleValue2}
                sensorCode={props.sensorCode}
                version={props.version}
                />
            </Container>
          </Tab>
          <Tab eventKey="notifications" title="Notifications">
            <Container>
              <Col style={{textAlign:"left"}}>
                <Row>
                  Notification: {props.notificationStatus}
                </Row>
                <Row>
                  <GetThresholds 
                    maxThresh={ props.maxThresh }
                    minThresh={ props.minThresh }
                    scaleValue1={ props.scaleValue1 }
                    scaleValue2={ props.scaleValue2 }
                    version={ props.version }
                  />
                </Row>
              </Col>
            </Container>
          </Tab>
          {/* only show calibration for temperature sensors for now  */}
          {/* {props.version === ("Temperature") ? <Tab eventKey = "calibration" title="Calibration">Calibration</Tab> : <></>} */}
        </Tabs>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function GetLastSensorMessageForTable(data, sensorVersion, scaleValue1, scaleValue2) {
  return data + " sensors"
}

// the following functions are temporary fixes to restructure / separate some methods into functions.

function CalculateBatteryPercent(batteryLevel) {
  var percentValue;
  if (batteryLevel <= 2.3) {
    percentValue = 0;
  } else if (batteryLevel > 2.3 && batteryLevel < 2.55) {
    percentValue = LinearInterpolation(batteryLevel, 2.4, 0, 2.55, 10);
  } else if (batteryLevel > 2.55 && batteryLevel < 2.6) {
    percentValue = LinearInterpolation(batteryLevel, 2.55, 10, 2.6, 25);
  } else if (batteryLevel > 2.6 && batteryLevel < 2.8) {
    percentValue = LinearInterpolation(batteryLevel, 2.6, 25, 2.8, 50);
  } else if (batteryLevel > 2.8 && batteryLevel < 2.9) {
    percentValue = LinearInterpolation(batteryLevel, 2.8, 50, 2.9, 75);
  } else {
    percentValue = batteryLevel > 2.9 && batteryLevel <= 3 ? LinearInterpolation(batteryLevel, 2.9, 75, 3, 100) : 100;
  }

  return percentValue.toFixed(0);
}

function LinearInterpolation(value, lowX, lowY, highX, highY) {
  var slope = (highY - lowY) / (highX - lowX);
  var intercept = slope * lowX - lowY;
  return slope * value - intercept;
}


import React, { useState, useRef, useEffect } from "react";
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fromUnixTime, getUnixTime, roundToNearestMinutes, format} from "date-fns";
import { IOTData } from "../services.js";
import TextField from '@mui/material/TextField';

const iotdata = IOTData();

export default function SensorNotificationPauseUntilDate(props) {
  // 4102444800 is 2099 Dec 31, max date allowed by MUI.
  const [selectedDate, setSelectedDate] = useState(props.notificationStatus !== "paused" ? roundToNearestMinutes(new Date(), { nearestTo: 5, roundingMethod: 'ceil' } ) : (props.notificationPausedUntilDateTime >= 4102444800 || !props.notificationPausedUntilDateTime ) ? null : fromUnixTime(props.notificationPausedUntilDateTime));
  const [acceptedDate, setAcceptedDate] = useState(null);
  const handleChange = (value) => { setSelectedDate(value)};
  const didMount = useRef(false);
  useEffect(() => {
    const handlePause = () => {
      const acceptedDateString = format(fromUnixTime(acceptedDate), "MMM dd, yyyy@p");
      const json_string = {
        deviceId: props.id,
        id: props.id,
        notificationStatus: "paused",
        cmdStatus: "REQUEST",
        cmdTimeStamp: new Date(),
        notificationPausedUntilDateTime: acceptedDate
      }
      console.log(json_string);
      const params = {
        topic: "notifications/sensor/setPauseUntilDate"  ,
        payload: JSON.stringify(json_string),
        qos: 0
      };
      iotdata.publish(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
          alert("Problem saving settings!");
        }
        else {
          console.log("response", data);
          alert("Notifications paused until " + acceptedDateString );
          window.location.reload();
        }
      });
    }
  
    if ( !didMount.current ) {
      didMount.current = true;
    } 
    else {
      handlePause(true);
    }
  }, [props.id, acceptedDate]);

  const handleAccept = (acceptedDate) => {
    console.log("Pause until " + acceptedDate)
    setAcceptedDate(getUnixTime(acceptedDate));
    // TODO: need to set / save the "pause until" date
  }


  // props.notificationStatus !== "paused" ? "Pause Until" : props.notificationPausedUntilDateTime === 10413792000 ? "Paused indefinitely" : "Paused Until";
  const getLabelText = () => {
    if(props.notificationStatus === "paused"){
      return props.notificationPausedUntilDateTime >= 4102444800 ? "Paused Forever" : !props.notificationPausedUntilDateTime ? "Paused Forever" : "Paused Until";
    }
    return "Pause Until";
  }
  const labelText = getLabelText();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDateTimePicker 
        label={ labelText }
        value={ selectedDate }
        disablePast={ true }
        onChange={ handleChange }
        onAccept={ handleAccept }
        renderInput={(params) => <TextField {...params} size={ "small" } />}
        minDateTime={new Date()}
        minutesStep={5}
      />
  </LocalizationProvider>
  );
}
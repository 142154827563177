import React from "react";
//TODO: change all the option value= to match the Java program?
//TODO: is there a better way to keep all my settings in sync?
// TODO: add the other sensor types
export const SensorDictionary = {
  "Button":
  {
    "name": "Button sensor", // the name the user will assign later
    "option": <option key={"Button"} value="Button">Button</option>,
    "heartbeat": 10,
    "heartbeatAssessments": 10,
    "minThresh": 0, // set to default value on creation
    "maxThresh": 0, // set to default value on creation
    "scaleValue1": "Pressed in",
    "scaleValue2": "Checking in",
    "pendingChange": false,
    "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
    "deviceType": "Button", // will eventually replace "version"
    "deviceName": "Button", //device friendly name
    "version": "Button",
    "deviceImage": "/Images/SensorImages/smart-barn-hex.jpg"
  },
  "CurrentZeroToTwentyAmp": 
    { 
      "name": "0-20 Amp Current Meter", // the name the user will assign later
      "option": <option key={"CurrentZeroToTwentyAmp"} value="CurrentZeroToTwentyAmp">0-20 Amp Current Meter</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 0, // set to default value on creation
      "maxThresh": 20, // set to default value on creation
      "scaleValue1": "amps",
      "scaleValue2": "amps",
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "CurrentZeroToTwentyAmp", // will eventually replace "version"
      "deviceName": "0-20 Amp Current Meter", //device friendly name
      "version": "CurrentZeroToTwentyAmp",
      "deviceImage": "/Images/SensorImages/current0to20.png"
    },
  "Current_20mA": 
    { 
      "name": "0-20 mA Sensor", // the name the user will assign later
      "option": <option key={"Current_20mA"} value="Current_20mA">0-20 mA Sensor</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 0, // set to default value on creation
      "maxThresh": 5, // set to default value on creation
      "scaleValue1": "mA",
      "scaleValue2": "mA",
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Current_20mA", // will eventually replace "version"
      "deviceName": "0-20 mA Sensor", //device friendly name
      "version": "Current_20mA",
      "deviceImage": "/Images/SensorImages/current_20ma.png"
    },
    "DC_Voltage_5V": 
    { 
      "name": "0-5 V Sensor", // the name the user will assign later
      "option": <option key={"DC_Voltage_5V"} value="DC_Voltage_5V">0-5 V Sensor</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 0, // set to default value on creation
      "maxThresh": 20, // set to default value on creation
      "scaleLabel": "V",
      "scaleValue1": 0,
      "scaleValue2": 5,
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "", // will eventually replace "version"
      "deviceName": "0-5 V Sensor", //device friendly name
      "version": "DC_Voltage_5V",
      "deviceImage": "/Images/SensorImages/dc_voltage_5v.pngn"
    },
  "Dry_Contact": {
      "name": "Dry Contact Sensor",
      "option": <option key={"Dry_Contact"} value="Dry_Contact">Dry Contact</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 1, // set to default value on creation
      "maxThresh": 1, // set to default value on creation
      "scaleValue1": "Closed",
      "scaleValue2": "Open",
      "pendingChange": true,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Dry_Contact", // will eventually replace "version"
      "deviceName": "Dry Contact",
      "version": "Dry_Contact",
      "deviceImage": "/Images/SensorImages/dry_contact.png"
    },
  "Temperature": 
    { 
      "name": "Temperature Sensor",
      "option": <option key={"Temperature"} value="Temperature">Temperature Sensor</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": -40, // set to default value on creation
      "maxThresh": 125, // set to default value on creation
      "scaleValue1": "F",
      "scaleValue2": 0,
      "pendingChange": true,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Temperature", // will eventually replace "version"
      "deviceName": "Temperature",
      "version": "Temperature",
      "deviceImage": "/Images/SensorImages/TEMPERATURE-BOX.png"
    },
  "Low_Temperature": 
    { 
      "name": "Low Temperature Sensor",
      "option": <option key={"Low_Temperature"} value="Low_Temperature">Ultra Low Temperature Sensor</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": -200, // set to default value on creation
      "maxThresh": 100, // set to default value on creation
      "scaleValue1": "C",
      "scaleValue2": 0,
      "pendingChange": true,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Low_Temperature", // will eventually replace "version"
      "deviceName": "Low_Temperature",
      "version": "Low_Temperature",
      "deviceImage": "/Images/SensorImages/low-temp-box.png"
    },
  "Humidity": 
    { 
      "name": "Humidity Sensor",
      "option": <option key={"Humidity"} value="Humidity">Humidity Sensor</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": -40, // set to default value on creation //TODO: may use an array to combine humidity sensor settings. [-40, 25%]
      "maxThresh": 257, // set to default value on creation
      "scaleValue1": "F",
      "scaleValue2": "%",
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Humidity", // will eventually replace "version"
      "deviceName": "Humidity",
      "version": "Humidity",
      "deviceImage": "/Images/SensorImages/Humidity-blue.png"
    },
    "low_temperature":
    {
      //TODO: settings/defaults
    },
    "light_meter":
    {
      //TODO: settings/defaults
    },
    "Pressure300PSI":
    { 
      "name": "300 PSI pressure",
      "option": <option key={"Pressure300PSI"} value="Pressure300PSI">300 PSI pressure</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 0, // set to default value on creation //TODO: may use an array to combine humidity sensor settings. [-40, 25%]
      "maxThresh": 300, // set to default value on creation
      "scaleValue1": "PSI",
      "scaleValue2": "PSI",
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Pressure300PSI", // will eventually replace "version"
      "deviceName": "Pressure300PSI",
      "version": "Pressure300PSI",
      "deviceImage": "/Images/SensorImages/pressure.png"
    },
    "Filtered_Pulse_Counter_1_Input":
    {
      "name": "Pulse Counter", // the name the user will assign later
      "option": <option key={"Filtered_Pulse_Counter_1_Input"} value="Pulse Counter">Pulse Counter</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 0, // set to default value on creation
      "maxThresh": 0, // set to default value on creation
      "scaleValue1": "gallons",
      "scaleValue2": 1, // used for the pulses per gallon, default 1 pulse = 1 gal
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "filtered_pulse_counter_1_input", // will eventually replace "version"
      "deviceName": "Pulse Counter", //device friendly name
      "version": "Filtered_Pulse_Counter_1_Input",
      "deviceImage": "/Images/SensorImages/PULSE-COUNTER-BOX.png"
    },
    "Pulse_Counter_1_Input":
    {
      "name": "Pulse Counter", // the name the user will assign later
      "option": <option key={"Pulse_Counter_1_Input"} value="Pulse Counter">Pulse Counter</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 0, // set to default value on creation
      "maxThresh": 0, // set to default value on creation
      "scaleValue1": "gallons",
      "scaleValue2": 1, // used for the pulses per gallon, default 1 pulse = 1 gal
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "pulse_counter", // will eventually replace "version"
      "deviceName": "Pulse Counter", //device friendly name
      "version": "Pulse_Counter_1_Input",
      "deviceImage": "/Images/SensorImages/PULSE-COUNTER-BOX.png"
    },
  "Voltage_Meter_500": 
    { 
      "name": "500V Voltage Meter", // the name the user will assign later
      "option": <option key={"Voltage_Meter_500"} value="Voltage_Meter_500">500 Volt Meter</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 0, // set to default value on creation
      "maxThresh": 500, // set to default value on creation
      "scaleValue1": "V",
      "scaleValue2": "V",
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Voltage_Meter_500", // will eventually replace "version"
      "deviceName": "500V Voltage Meter", //device friendly name
      "version": "Voltage_Meter_500",
      "deviceImage": "/Images/SensorImages/voltage_meter.png"
    },
    "AC_Voltage_500": 
    { 
      "name": "500V Voltage Meter", // the name the user will assign later
      "option": <option key={"AC_Voltage_500"} value="AC_Voltage_500">AC 500 Volt Meter</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 0, // set to default value on creation
      "maxThresh": 500, // set to default value on creation
      "scaleValue1": "V",
      "scaleValue2": "V",
      "pendingChange": false,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "AC_Voltage_500", // will eventually replace "version"
      "deviceName": "500V Voltage Meter", //device friendly name
      "version": "AC_Voltage_500",
      "deviceImage": "/Images/SensorImages/voltage_meter.png"
    },
    "Open_Closed": //TODO: check that this is the door open/closed sensor type vs. dry contact? does it matter?
    { 
      "name": "Door Open/Closed Sensor", // the name the user will assign later
      "option": <option key={"Open_Closed"} value="Open_Closed">Door Open / Closed</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": 1, // magnet not present
      "maxThresh": 1, // magnet not present
      "scaleValue1": "Closed",
      "scaleValue2": "Open",
      "pendingChange": true,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Open_Closed", // will eventually replace "version"
      "deviceName": "Door Open/Closed Sensor", //device friendly name
      "version": "Open_Closed",
      "deviceImage": "/Images/SensorImages/door.png"
    },
    "Pulse_Counter": //TODO: are there several subtypes? How to handle? -> maybe just update the SensorType when it checks in?
    { 
      "name": "Pulse counter", // the name the user will assign later
      "option": <option key={"Pulse_Counter"} value="Pulse_Counter">Single Input Pulse Counter</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": null,
      "maxThresh": null, 
      "scaleValue1": "gallons", // Default to gallons since I think 100% of our pulse meters are gallons?
      "scaleValue2": 1, // use as the multiplier //TODO: what does this mean?
      "pendingChange": true,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "Pulse_Counter", // will eventually replace "version"
      "deviceName": "Pulse Counter", //device friendly name
      "version": "Pulse_Counter",
      "deviceImage": "/Images/SensorImages/pulse_counter.png"
    },

    'FilteredPulseCounter64':
    { 
      "name": "Pulse counter", // the name the user will assign later
      "option": <option key={"FilteredPulseCounter64"} value="FilteredPulseCounter64">Filtered 64 Pulse Counter</option>,
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": null,
      "maxThresh": null, 
      "scaleValue1": "gallons", // Default to gallons since I think 100% of our pulse meters are gallons?
      "scaleValue2": 1, // use as the multiplier //TODO: what does this mean?
      "pendingChange": true,
      "manufacturer": "monnit", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "FilteredPulseCounter64", // will eventually replace "version"
      "deviceName": "Pulse counter", //device friendly name
      "version": "FilteredPulseCounter64",
      "deviceImage": "/Images/SensorImages/pulse_counter.png"
    },
    "Ethernet_Gateway_4": 
    { 
      "name": "Ethernet Gateway 4",
      "deviceType": "Ethernet_Gateway_4",
      "version": "Ethernet_Gateway_4",
      "type": "Ethernet_Gateway_4",
      "manufacturer": "monnit",
      "heartbeat": 5,
      "pendingChange": true,
      "deviceImage": "/Images/SensorImages/Smart Barn gateway.png"
    },
    "Ethernet_3_0": 
    { 
      "name": "Ethernet Gateway 3",
      "deviceType": "Ethernet_3_0",
      "version": "Ethernet_3_0",
      "type": "Ethernet_3_0",
      "manufacturer": "monnit",
      "heartbeat": 5,
      "pendingChange": true,
      "deviceImage": "/Images/SensorImages/Smart Barn gateway.png"
    },
    "Ranger_Extender": {
      "deviceList": [],
      "deviceImage": "/Images/SensorImages/Smart Barn gateway.png",
      "deviceName": "Range Extender",
      "deviceType": "Ranger_Extender",
      "heartbeat": 5,
      "manufacturer": "monnit",
      "name": "Range Extender",
      "version": "Ranger_Extender"
      //TODO: going to need some commands / ability to handle reform, sensorlist, etc.
    },
    "SmartBarnDairySensor": 
    { 
      "name": "Dairy Temperature Sensor",
      "heartbeat": 10,
      "heartbeatAssessments": 10,
      "minThresh": -40, // set to default value on creation
      "maxThresh": 125, // set to default value on creation
      "scaleValue1": "C",
      "manufacturer": "SmartBarn", // this is a new property that I need to add -> it will dictate which topics commands go to, keep monnit Mine from downloading SB sensors, etc.
      "deviceType": "DairyTankTemperature", // will eventually replace "version"
      "deviceName": "Smart Barn - Dairy Tank Sensor",
      "version": "DairyTankTemperature",
      "deviceImage": "/Images/SensorImages/TEMPERATURE.png"
    }
};

export const SensorTypes = {
  Dry_Contact: "Dry_Contact",
  Temperature: "Temperature",
  Voltage_Meter_500: "Voltage_Meter_500",
  Open_Closed: "Open_Closed",
  Humidity: "Humidity",
  Pulse_Counter_1_Input: "Pulse counter",
  Ethernet_Gateway_4: "Ethernet_Gateway_4",
  Ethernet_3_0: "Ethernet_3_0",
  Range_Extender: "Range_Extender",
  SmartBarnDairySensor: "SmartBarnDairySensor"
}

export function GetThresholds(props) {
  switch(props.version.toLowerCase()) {
    case "dry_contact":
      return props.minThresh === 1 ? <>Alarm: {props.scaleValue2}</> : <>Alarm: {props.scaleValue1}</>;

    case "open_closed":
      return props.minThresh === 1 ? <>Alarm: {props.scaleValue2}</> : <>Alarm: {props.scaleValue1}</>;

    case "temperature":
      return (
        <>
        Low alarm: {props.scaleValue1.startsWith("C") ? props.minThresh + "C": (props.minThresh*1.8 + 32).toFixed(0) + "F" } <br/>
        Hi alarm: {props.scaleValue1.startsWith("C") ? props.maxThresh + "C": (props.maxThresh*1.8 + 32).toFixed(0) + "F"}
        </>
      );
    case "humidity":
      return (
        <>
        Low temp alarm: {props.scaleValue1.startsWith("C") ? props.minThresh + "C": (props.minThresh*1.8 + 32).toFixed(0) + "F" } <br/>
        Hi temp alarm: {props.scaleValue1.startsWith("C") ? props.maxThresh + "C": (props.maxThresh*1.8 + 32).toFixed(0) + "F"}<br/>
        Low humidity alarm: {props.minThresh2}% <br/>
        Hi humidity alarm: {props.maxThresh2}%

        </>
      );
    // case "pulse_counter_1_input":
    //   return (
    //     <>Alarm: {props.maxThresh + " " + props.scaleValue1} </>
    //   );
    case "timed":
      return (
        <>TIME: {props.maxThresh + " " + props.scaleValue1} </>
      );
    default:
      return(  
        <>
          Low alarm: {props.minThresh + " " + props.scaleValue1}<br/>
          Hi alarm: {props.maxThresh + " " + props.scaleValue1}
        </>
      )
  } 
}

export function GetLastSensorMessageForTable(data, sensorVersion, scaleValue1, scaleValue2) {
  switch (sensorVersion.toLowerCase()) {
    case "dc_voltage_5v":
      const correctedValue = data*scaleValue1+scaleValue2*1
      return (correctedValue).toFixed(1);
    case "dry_contact":
    case "open_closed":
    case "button":
      return data == 1 ? scaleValue1 : scaleValue2;
    case "ac_voltage_500":
    case "voltage_meter_500":
      // console.log("voltage_meter_500Table", this.props.data);
      return data.toFixed(0) + ' ' + scaleValue1;   
    case "temperature":
      if(data < -40) { return "ERROR"}
      return (scaleValue1.charAt(0) === "F") ? ((data * 9.0/5.0) + 32.0).toFixed(1) + " F" : data.toFixed(1) + " C";
    case "humidity":
      return data.toFixed(0) + " %" + " " + data.toFixed(0);
    case "ranger_extender":
      return data + " sensors";
    default:
      return data + " " + scaleValue1;
  }
}


import React from "react";

import { v4 as uuidv4 } from 'uuid';
import { Alert, Button } from "react-bootstrap";

export default function AlertOverlay(props){
  const alertOverlay = props.notificationStatus === "alerting" ?
  <><Alert className={"in"} key={uuidv4()} deviceid={props.id} variant={"warning"}>Sensor Notification Alerting!<Button className="ml-1" size="sm" onClick={() => props.handleAcknowledgeNotification()} variant="danger">Acknowledge</Button></Alert></>
  : props.notificationStatus === "acknowledged" ? <><Alert style={{padding:"2px"}}className={"in"} key={uuidv4()} deviceid={props.id} variant={"warning"}><Button className="ml-1" size="sm" onClick={() => props.handleResetNotification()} variant="warning">Reset Notification</Button></Alert></>
  : <></>;

  return alertOverlay;

}
/* eslint-disable */

// these are my customized mutations separate from the autogenerated Amplify code. Separating out to prevent overwriting by Amplify.

export const createUserNotes = /* GraphQL */ `
    mutation CreateUserNotes($input: CreateUserNotesInput!) {
        createUserNotes(input: $input) {
            senid
            userId
            userFullName
            userNotes
        }
    }
`;

 
import { useState, useEffect } from 'react';
import { querySensorsByAccountIndex } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useLoadSensors(accountId, nextToken) {
  const [allSensors, setAllSensors] = useState(null);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(querySensorsByAccountIndex, { account: accountId, limit: 500, nextToken: nextToken })).catch((error) => console.log("graphQL error: ",error));
      setAllSensors(result.data.querySensorsByAccountIndex.items);
    }
    accountId&&fetchData();
  },[accountId, nextToken]);
  return allSensors;
}
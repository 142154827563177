import React from 'react';
import { Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

import NotificationEditHumidityTab from './NotificationEditHumidityTab';
import NotificationEditTemperatureTab from './NotificationEditTemperatureTab';
import NotificationToolTip from '../Components/Buttons/Notifications/NotificationToolTip';
import EditReturnToNormal from '../Components/Buttons/Notifications/EditReturnToNormal';

export default function NotificationEditTab(props) {
  const handleDryContactThresh = (e) => {
    props.setMaxThresh(e.target.value);
    props.setMinThresh(e.target.value);
  }

  const handleDryContactTimeThresholds = (e) => {
    props.setMaxTimeThresh(e.target.value);
    props.setMinTimeThresh(e.target.value);
  }

  const handleSetTemperatureMaxThresh = (e) => {
    props.setMaxThresh(props.scaleValue1 === "C" ? e.target.value : ((e.target.value-32)/1.8).toFixed(1))
  }

  const handleSetTemperatureMinThresh = (e) => {
    props.setMinThresh(props.scaleValue1 === "C" ? e.target.value : ((e.target.value-32)/1.8).toFixed(1))
  }

  const handleSetMaxTimeThresh = (e) => {
    e >=1 ? props.setMaxTimeThresh(e) : props.setMaxTimeThresh(1); //TODO: change these back to 10
  }

  const handleSetMinTimeThresh = (e) => {
    e >=1 ? props.setMinTimeThresh(e) : props.setMinTimeThresh(1); //TODO: change these back to 10
  }

  const changeScaleValue1 = (e) => {
    props.setScaleValue1(e)
  }

  const handleMessage = (e) => {
    props.setMessage(e.target.value);
  }
  const handleName = (e) => {
    props.setName(e.target.value);
  }
  
  if(!props.deviceType) {
    return null;
  }


  // little verbose but gets the job done. const components for each sensor type. Could split out into its own folder w/ a component for each sensor type?
  var alertWhen = <></>;

  const dryContact = 
    <>
      <label style={{ float: "left" }} className="input-label">
        Alert When Loop:
      </label>
      <select 
        id="awareMode" 
        className="form-control" 
        defaultValue={props.minThresh} 
        name="Aware State Mode"
        onChange={ handleDryContactThresh } 
      >
        <option value={0}>Closed</option>
        <option value={1}>Open</option>
      </select>
    </>;

  // TODO: need to fix the F to C conversion issues.
  const temperature = 
    <NotificationEditTemperatureTab
      maxThresh={props.maxThresh}
      minThresh={props.minThresh}
      scaleValue1={props.scaleValue1}
      handleSetTemperatureMaxThresh={handleSetTemperatureMaxThresh}
      handleSetTemperatureMinThresh={handleSetTemperatureMinThresh}
      changeScaleValue1={changeScaleValue1}
    />;

  const lowTemperature =
  <>
    <Form.Label>Alert When Below:</Form.Label>
    <Form.Control 
      type="number" 
      defaultValue={props.scaleValue1 === "C" ? (props.minThresh*1.0).toFixed() : ((props.minThresh*1.8)+32).toFixed()} 
      min={-200}
      max={props.scaleValue1 === "C" ? (props.maxThresh*1.0).toFixed() : ((props.maxThresh*1.8)+32)}
      onChange={(e) => props.handleSetTemperatureMinThresh(e) }
      />
    <Form.Label>or above:</Form.Label>
    <Form.Control 
      type="number" 
      defaultValue={props.scaleValue1 === "C" ? (props.maxThresh*1.0).toFixed() : ((props.maxThresh*1.8)+32).toFixed() }
      min={props.scaleValue1 === "C" ? (props.minThresh*1.0).toFixed() : ((props.minThresh*1.8)+32)}
      max={props.scaleValue1 === "C" ? 100 : 210}
      onChange={ (e) => handleSetTemperatureMaxThresh(e)}
      />
    <Form.Label>Units</Form.Label>
    <Form.Select defaultValue={ props.scaleValue1 } onChange={(e) => changeScaleValue1(e.target.value)}> {/*TODO: add handleSelectUnits for temperature */}
      <option value="C">C</option>
      <option value="F">F</option>
    </Form.Select>
  </>;
 

  const humidity = <NotificationEditHumidityTab 
                      message={ props.message }
                      setMessage={ handleMessage }
                      maxThresh={ props.maxThresh }
                      maxThresh2={ props.maxThresh2 }
                      minThresh={ props.minThresh }
                      minThresh2={ props.minThresh2 }
                      scaleValue1={ 'F' }
                      scaleValue2={ '%' }
                      setMaxThresh={ props.setMaxThresh }
                      setMaxThresh2={ props.setMaxThresh2 }
                      setMinThresh={ props.setMinThresh }
                      setMinThresh2={ props.setMinThresh2 }
                      sendReturnToNormalMessage={ props.sendReturnToNormalMessage }
                      setSendReturnToNormalMessage={ props.setSendReturnToNormalMessage }
                    />;

  const voltageMeter =
    <>
      <Form.Label>Alert when below:</Form.Label>
      <Form.Control 
        type="number" 
        defaultValue={props.minThresh}
        min="0"
        max={props.maxThresh}
        onChange={ (e) => props.setMinThresh(e.target.value) }
        />
      <Form.Label>and above:</Form.Label>
      <Form.Control 
        type="number" 
        defaultValue={props.maxThresh} 
        min={props.minThresh}
        max="500"
        onChange={ (e) => props.setMaxThresh(e.target.value) }
        />
    </>;
    
  const timedVoltage = 
    <>
      <Form.Label>Alert when</Form.Label>
      <InputGroup className="mb-3">
        <InputGroup.Text>below:</InputGroup.Text>
          <Form.Control 
            type="number" 
            defaultValue={ props.minThresh || 0 }
            min="0"
            max={props.maxThresh}
            onChange={ (e) => props.setMinThresh(e.target.value) }
          />
        <InputGroup.Text>Volts</InputGroup.Text>
      </InputGroup>
      <Form.Label>for longer than:</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control 
          type="number" 
          defaultValue={props.minTimeThresh || 10}
          min={ 1 } // TODO: 1 FOR TESTING ONLY!!!!
          max={ 720 }
          onChange={ (e) => handleSetMinTimeThresh(e.target.value) }
        />
        <InputGroup.Text>minutes</InputGroup.Text>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text>or above:</InputGroup.Text>
        <Form.Control 
          type="number" 
          defaultValue={props.maxThresh || 500} 
          min={props.minThresh}
          max="500"
          onChange={ (e) => props.setMaxThresh(e.target.value) }
          />
        <InputGroup.Text>Volts</InputGroup.Text>
      </InputGroup>
      <Form.Label>for longer than:</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control 
          type="number" 
          defaultValue={props.maxTimeThresh || 10}
          min={ 1 } // TODO: 1 FOR TESTING ONLY!!!!
          max={ 720 }
          onChange={ (e) => handleSetMaxTimeThresh(e.target.value) }
        />
        <InputGroup.Text>minutes</InputGroup.Text>
      </InputGroup>
      <Form.Text>Run time before alert is sent (minimum 1 minute, maximum 720 minutes / 12 hours)</Form.Text>
    </>

    const timedDryContact = 
    <>
      <label style={{ float: "left" }} className="input-label">
        Alert When Loop:
      </label>
      <select 
        id="awareMode" 
        className="form-control" 
        defaultValue={props.minThresh} 
        name="Aware State Mode"
        onChange={ handleDryContactThresh } 
      >
        <option value={0}>Closed</option>
        <option value={1}>Open</option>
      </select>
      <Form.Label>for longer than:</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control 
          type="number" 
          defaultValue={props.maxTimeThresh || 10}
          min={ 1 } // TODO: 1 FOR TESTING ONLY!!!!
          max={ 720 }
          onChange={ handleDryContactTimeThresholds }
        />
        <InputGroup.Text>minutes</InputGroup.Text>
      </InputGroup>
      <Form.Text>Run time before alert is sent (minimum 1 minute, maximum 720 minutes / 12 hours)</Form.Text>
    </>
  const openClosed = 
    <>
      <label style={{ float: "left" }} className="input-label">
        Alert when door is:
      </label>
      <select 
        id="awareMode" 
        className="form-control" 
        defaultValue={props.minThresh} 
        name="Aware State Mode"
        onChange={ handleDryContactThresh } 
      >
        <option value={0}>Closed</option>
        <option value={1}>Open</option>
      </select>
    </>;


  const pulseCounter =
    <>
    <Form.Label>Alert when</Form.Label>
    <InputGroup className="mb-3">
      <InputGroup.Text>below:</InputGroup.Text>
        <Form.Control 
          type="number" 
          defaultValue={ props.minThresh }
          min="0"
          max={ props.maxThresh }
          onChange={ (e) => props.setMinThresh(e.target.value) }
        />
      <InputGroup.Text>{props.scaleValue1}</InputGroup.Text>
    </InputGroup>
    <InputGroup className="mb-3">
      <InputGroup.Text>or above:</InputGroup.Text>
      <Form.Control 
        type="number" 
        defaultValue={ props.maxThresh } 
        min={props.minThresh}
        max="1000"
        onChange={ (e) => props.setMaxThresh(e.target.value) }
      />
      <InputGroup.Text>{props.scaleValue1}</InputGroup.Text>
    </InputGroup>
  </>

  const gateway = 
    <>
    <Form.Label>Alert when inactive for longer than:</Form.Label>
    <Form.Control 
      type="number" 
      defaultValue={props.maxThresh || 10}
      min={5}
      max={720}
      onChange={ (e) => props.setMaxThresh(e.target.value) }
    />
    <Form.Text>Time inactive in minutes before an alert is sent</Form.Text>
    </>
  const pressure300PSI =
    <>
      <Form.Label>Alert When Below:</Form.Label>
    <Form.Control type="number" defaultValue={props.minThresh || 0 } min="0" onChange={ (e) => props.setMinThresh(e.target.value) }
/>
      <Form.Label>or above:</Form.Label>
      <Form.Control type="number" defaultValue={props.maxThresh || 300 } max={300} onChange={ (e) => props.setMaxThresh(e.target.value) }/>
    </>;

  switch (props.deviceType.toLowerCase()) {
    case "dry_contact":
      alertWhen = props.notificationType === "timedDryContact"  ? timedDryContact : dryContact;
      break;
    case "open_closed":
      alertWhen = props.notificationType === "timedOpenClosed"  ? timedDryContact : openClosed;
      break;
    case "humidity":
      alertWhen = humidity;
      break;
    case "temperature":
      alertWhen = temperature;
      break;
    case "low_temperature":
      alertWhen = lowTemperature;
      break;
    case "timedvoltage":
      alertWhen = timedVoltage;
      break;
    case "timedopenclosed":
    case "timeddrycontact":
      alertWhen = timedDryContact;
      break;
    case "ac_voltage_500":
    case "voltage_meter_500":
    case "currentzerototwentyamp":
      alertWhen = props.notificationType === "timedVoltage"  ? timedVoltage : voltageMeter;
      break;
    case "filtered_pulse_counter_1_input":
    case "pulse_counter_1_input":
    case "filteredpulsecounter64":
      alertWhen = pulseCounter;
      break;
    case "pressure300psi":
      alertWhen = pressure300PSI;
      break;
    case "gateway":
      alertWhen = gateway;
      break;
    default:
      alertWhen = "Not implemented!";
  }
              
  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="formNotificationName">
          <Form.Label>Notification Name</Form.Label>
          <Form.Control type="text" defaultValue={props.notificationName} onChange={ (e) => handleName(e) } />
          <Form.Label>Notification Message &nbsp;</Form.Label><NotificationToolTip />
          <Form.Control type="text" defaultValue={ props.message } onChange={ (e) => handleMessage(e) } />
           {alertWhen}
          <EditReturnToNormal
            sendReturnToNormalMessage={props.sendReturnToNormalMessage}
            setSendReturnToNormalMessage={props.setSendReturnToNormalMessage}
            returnToNormalMessage={props.returnToNormalMessage}
            setReturnToNormalMessage={props.setReturnToNormalMessage}
          />
        </Form.Group>
      </Form>
    </>
  );
}


import React from "react";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import WarningIcon from '@mui/icons-material/Warning';
import DangerousIcon from '@mui/icons-material/Dangerous';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { Container, Row } from "react-bootstrap";

export default function GetSensorStatusIcons(props) {
  var statusIcon = null;
  if (props.message.length === 0) {
    statusIcon = <PowerSettingsNewIcon sx={{ fontSize: 18 }} title={"Off/No Messages Received"} htmlColor="gray" />;
  }

  // TODO: fix this issue (no question mark status)
  else if (props.afterExpectedCheckinTime) {
    statusIcon = <HelpIcon sx={{ fontSize: 18 }} title={"Unknown Status"} htmlColor="gray" />;
  }
  else {
    statusIcon = <CheckCircleIcon sx={{ fontSize: 18 }} htmlColor="green" />;
  }

  return (

    <>
      <Row><Container fluid>{statusIcon}</Container></Row>
      <Row><Container fluid>
        {(props.notificationStatus === "acknowledged" || props.notificationStatus === "timerAck") ? <WarningIcon sx={{ fontSize: 18 }} htmlColor="red" /> :
          (props.notificationStatus === "alerting" || props.notificationStatus === "timerAlarm") ? <WarningIcon sx={{ fontSize: 18 }} title={"alerting!"} htmlColor="red" /> :
            (props.notificationStatus === "disarmed") ? <DangerousIcon sx={{ fontSize: 18 }} title={"disarmed"} htmlColor="gray" /> :
              (props.notificationStatus === "armed" || props.notificationStatus === "timerActive") ? <AlarmOnIcon sx={{ fontSize: 18 }} title={"armed"} htmlColor="green" /> :
                (props.notificationStatus === "paused") ? <PauseCircleIcon sx={{ fontSize: 18 }} title={"paused"} htmlColor="blue" /> :
                  (props.notificationStatus === "timerRunning") ? <HourglassTopTwoToneIcon sx={{ fontSize: 18 }} title={"timerRunning"} htmlColor="blue" /> :
                    (props.notificationStatus === "sleeping") ? <NotificationsPausedIcon sx={{ fontSize: 18 }} title={"sleeping"} htmlColor="blue" /> : <DangerousIcon sx={{ fontSize: 18 }} title={"disarmed"} htmlColor="gray" />}
      </Container></Row>
      {(props.pendingChange) ? <Row><Container fluid><BuildCircleIcon sx={{ fontSize: 18 }} title={"Pending Settings Change"} htmlColor="orange" /></Container></Row> : null}
    </>
  );
}

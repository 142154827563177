import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isSameDay from 'date-fns/isSameDay';

export default function findHiLowHumidityByDay(sensorDataMessages, startDate, endDate) {
  const days = eachDayOfInterval({ start: startDate, end: endDate });
  var formattedHiLowByDay = [];
  days.forEach(function (day) {
    var maxHumidity = -Infinity;
    var minHumidity = Infinity;
    sensorDataMessages.forEach(function (items) {
      const timestamp = items.timestamp * 1000;
      const humidity = JSON.parse(items.dataValues).Humidity;
      const dateFromUnixTimestamp = new Date(timestamp);
      if (isSameDay(day, dateFromUnixTimestamp)) {
        if (maxHumidity < parseFloat(humidity)) 
        {
          maxHumidity = parseFloat(humidity);
        };
        if (minHumidity > parseFloat(humidity)) {
          minHumidity = parseFloat(humidity);
        }
      }
    });
    maxHumidity = (maxHumidity === -Infinity) ? "" : maxHumidity.toFixed(1);
    minHumidity = (minHumidity === Infinity) ? "" : minHumidity.toFixed(1);
    formattedHiLowByDay.push(["Hi: " + maxHumidity + "%" + " Low: " + minHumidity + "%"]);
  });
  return formattedHiLowByDay;
}
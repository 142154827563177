import React, { useState, useEffect, useRef } from 'react';

// MUI imports
import SendIcon from '@mui/icons-material/Send';
import Switch from '@mui/material/Switch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';

// bootstrap imports
import { Accordion, Col, Row, Container, Button, Tabs, Tab } from "react-bootstrap";

// smart barn imports
import { ListFirstNotifications } from "./NotificationQueries";
import NotificationEditTab from './NotificationEditTab';
import NotificationDevicesTab from './NotificationDevicesTab';
import NotificationUsersTab from './NotificationUsersTab';
import NotificationHistory from './NotificationHistory';
import { UpdateNotification } from '../Database.jsx';

import { format } from 'date-fns'

import { IOTData } from "../services.js";

//TODO: update sensor settings / status
const iotdata = IOTData();


export default function TimedOpenClosedNotification(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [assignedDevices, setAssignedDevices] = useState(!props.assignedDevices ? [] : props.assignedDevices);
  const [assignedUsers, setAssignedUsers] = useState(!props.assignedUsers ? [] : props.assignedUsers);
  const [tabKey, setTabKey] = useState('history');
  const [loadNotificationHistory, setLoadNotificationHistory] = useState(false);
  const [notificationName, setNotificationName] = useState(props.name);
  const [notificationStatus, setNotificationStatus] = useState(props.notificationStatus)
  const [message, setMessage] = useState(props.message);
  const [minThresh, setMinThresh] = useState(props.minThresh);
  const [minThresh2, setMinThresh2] = useState(props.minThresh2);
  const [minTimeThresh, setMinTimeThresh] = useState(props.minTimeThresh || 10);
  const [maxThresh, setMaxThresh] = useState(props.maxThresh);
  const [maxThresh2, setMaxThresh2] = useState(props.maxThresh2)
  const [maxTimeThresh, setMaxTimeThresh] = useState(props.maxTimeThresh || 720);
  const [returnToNormalMessage, setReturnToNormalMessage] = useState(props.returnToNormalMessage || "<Name> <ID> is now <Reading>");
  const [sendReturnToNormalMessage, setSendReturnToNormalMessage] = useState(props.sendReturnToNormalMessage || false);
  const [scaleValue1, setScaleValue1] = useState(props.scaleValue1)
  const [lastSentDate, setLastSentDate] = useState("N/A");
  const didMount = useRef(false);

  // TOPIC FOR NOTIFICATION HERE
  //cmd/sensor/+/monnit/notificationSettings/timedDryContact
  const cmdSensorNotificationTopic = "cmd/sensor/" + props.deviceType + "/monnit/notificationSettings/timedOpenClosed";
  const handleLoadNotificationHistory = () => {
    setLoadNotificationHistory(true);
  }


  useEffect(() => {
    // GetFirstSensorMessage(props.id, null, 1)
    ListFirstNotifications(props.id, null, 1)
    .then(
      (result) => {
        if(result.data.listFirstNotifications.items.length > 0) {
          setLastSentDate(format(new Date(result.data.listFirstNotifications.items[0].timestamp*1000), "MM/dd/yyyy h:mma"));
        }
        setIsLoaded(true)
      }, error => {
        setIsLoaded(true)
        setError(error) 
      }
    );
  });

  useEffect(() => {
   
    const handleToggle = () => {
      const iot_data = {
        assignedDevices: assignedDevices,
        notificationStatus: notificationStatus,
        notificationId: props.id
      }
  
      const topic = cmdSensorNotificationTopic;
      var params = {
        topic: topic,
        payload: JSON.stringify(iot_data),
        qos: 0,
      };
  
      UpdateNotification(iot_data)
      .then(
        iotdata.publish(params, function (err, data) {
          if (err) console.log(err, err.stack);
          else {
            // console.log("response", data);
            // console.log("Sent data ", iot_data);
              // alert("Notification Settings Saved!");
            }
          })
        )
      // console.log("ToggleUpdateNotification", iot_data)
    }
    // Return early, if this is the first render:
    if ( !didMount.current ) {
      didMount.current = true;
    } 
    else {
      handleToggle();
    }
  }, [assignedDevices, cmdSensorNotificationTopic, notificationStatus, props.id]);

  if(!isLoaded) { return <></> }
  const handleDelete = () => {
    // reset the notification states on notification toggle / updates GUI appearance based on flags
    setNotificationStatus("disarmed");
    // if the notification is toggle, it resets all the status flags of the notification as well.
    // var json_data = {
    //   "id": props.id,
    //   "notificationStatus": (checked ? "armed" : "disarmed")
    // }
    console.log("handleSwitchToggle", "disarmed");
    // updates the status in the database.

    // sensor status data for IoT core.
    const iot_data = {
      // assignedUsers: assignedUsers,
      notificationId: props.id,
      assignedDevices: assignedDevices,
      message: message,
      maxThresh: maxThresh,
      maxTimeThresh: maxTimeThresh,
      minThresh: minThresh,    
      notificationName: notificationName,
      notificationStatus: "disarmed",
      notificationType: "timedOpenClosed",
    }

    var params = {
      topic: cmdSensorNotificationTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log("response", data);
        console.log("Sent data ", iot_data);
        // alert("Notification Status Updated!");
      }
    });

    props.onDeleteNotification(props.notificationId);
  }

  // TODO: if a device is removed from the list of devices then update / remove the sensor from the notification
  
  const handleSave = () => {
    const iot_data = {
      account: props.account,
      accountId: props.account,
      assignedDevices: assignedDevices,
      assignedUsers: assignedUsers,
      maxThresh: maxThresh,
      maxTimeThresh: maxTimeThresh,
      message: message,
      minThresh: minThresh,
      minTimeThresh: minTimeThresh,
      notificationId: props.id,
      notificationName: notificationName,
      notificationStatus: notificationStatus,
      notificationType: "timedOpenClosed",
      returnToNormalMessage: returnToNormalMessage,
      sendReturnToNormalMessage: sendReturnToNormalMessage,
    }

    // topic structure: cmd/account-id/network-id/notificationId/sensortype/monnit/NotificationGroup
    const topic = cmdSensorNotificationTopic;
    var params = {
      topic: topic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };
    UpdateNotification(iot_data)
    .then(
      iotdata.publish(params, function (err, data) {
        if (err) console.log(err, err.stack);
        else {
          // console.log("response", data);
          // console.log("Sent data ", iot_data);
            alert("Notification Settings Saved!");
          }
        })
      );
    console.log("UpdateNotification", iot_data)
  }

  // TODO: fix the redundancies here, would like to use the handleSave only to simplify.
  const handleSwitchToggle = (event) => {
    // reset the notification states on notification toggle / updates GUI appearance based on flags
    // (Timed notifications) Timed notifications work differently than normal ones. To keep the notification from setting all the time set here as "timerActive"
    // (cont.) instead of "armed" The server will set the status to "armed" when the sensor has been "running" for a certain amount of time. Then the alert will send right away.
    setNotificationStatus(event.target.checked ? "timerActive" : "disarmed");
    console.log("Checked", notificationStatus);
  }

  const testNotification = () => {
    console.log("TODO: sensor test notifications!");
    const topic = "notifications/";
    const iot_data = {
      account: props.account,
      assignedDevices: assignedDevices,
      assignedUsers: assignedUsers,
      data: "TEST",
      deviceId: assignedDevices[0],
      deviceType: "sensor",
      message: "TEST + " + message,
      name: notificationName,
      networkId: props.networkId,
      networkName: props.networkName,
      notificationId: props.notificationId,
      notificationName: notificationName,
      notificationStatus: "alerting",
      notificationType: "TEST",
      scaleValue1: "TEST",
      senid: assignedDevices[0],
      timestamp: Date.now(),
      version: props.deviceType
    }

    console.log("iot_data", iot_data);
    // Send reform message to sensor topic
    const params = {
      topic: topic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    // iotdata.publish(params, function (err, data) {
    //   if (err) console.log(err, err.stack);
    //   else {
    //     // console.log(data);
    //     alert("Test notification sent.");
    //   }
    // });
  }
  // set the status icon based on the state of the notification
  var notificationStatusIcon=<CheckCircleIcon htmlColor="green" />;
  // check sensors for active notifications, then set status here.
  if(notificationStatus === "disarmed") {
    notificationStatusIcon=<CancelIcon htmlColor="gray" />;
  }
  else if(notificationStatus === "alerting") {
    notificationStatusIcon=<ErrorIcon htmlColor="red" />;
  }
  else if(notificationStatus === "acknowledged") {
    notificationStatusIcon=<PauseCircleIcon htmlColor="orange" />;
  }

  return (
    <Accordion.Item key={props.id} eventKey={props.id}>
      <Container fluid style={{paddingLeft: "0px"}}>
        <Switch 
          onChange={handleSwitchToggle} 
          checked = {(notificationStatus !== "disarmed")} 
          className="dynamic-class-name"
        />
        <b style={{textAlign:"left"}}>{notificationName}</b>&nbsp;Network: {props.networkName}
        <Accordion.Header onClick={ handleLoadNotificationHistory }>
          <Col style={{padding:"0px"}}>
            <Row className="align-items-center">
              <Col xs="1" lg="auto" style={{minWidth:"10px"}}>
                <Row><Container fluid>{notificationStatusIcon}</Container></Row>
              </Col>
              <Col xs="3" lg="1" >
                <img title={props.version} 
                  className="sensor-image-column" 
                  src="/Images/SensorImages/timed-notification-box.png"
                  alt="Timed notification icon" 
                />
              </Col>
              <Col xs="3" lg="1" >
                <img title={props.version} 
                  className="sensor-image-column" 
                  src="/Images/SensorImages/door.png"
                  alt="Timed notification icon" 
                />
              </Col>
              <Col><Container fluid style={{textAlign: "left"}}>Last sent: {lastSentDate}</Container></Col>
            </Row>
          </Col>
        </Accordion.Header>
      </Container>
      <Accordion.Body style={{padding:"0px"}}>
      { loadNotificationHistory === true && <Tabs
          id="controlled-tab"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
          className="mb-3"
        >
          <Tab eventKey="history" title="History">
            <NotificationHistory id={ props.id } tabKey={ tabKey }/> 
          </Tab>
          <Tab eventKey="edit" title="Edit">
            <Container style={{paddingLeft: 0, paddingRight: 0}} >
              <NotificationEditTab 
                id={ props.id } 
                notificationName={ notificationName }
                setName={ setNotificationName }
                message={ message } 
                deviceType={ props.deviceType }
                maxThresh={ maxThresh }
                maxThresh2={ maxThresh2 }
                maxTimeThresh={ maxTimeThresh }
                minThresh={ minThresh }
                minThresh2={ minThresh2 }
                minTimeThresh={ minTimeThresh }
                notificationType={ props.notificationType }
                scaleValue1={ scaleValue1 }
                scaleValue2={ props.scaleValue2 }
                setScaleValue1= { setScaleValue1 }
                sendReturnToNormalMessage={ sendReturnToNormalMessage }
                returnToNormalMessage={ returnToNormalMessage }
                setMessage={ setMessage }
                setMaxThresh={ setMaxThresh }
                setMaxThresh2={ setMaxThresh2 }
                setMaxTimeThresh={ setMaxTimeThresh }
                setMinThresh={ setMinThresh }
                setMinThresh2={ setMinThresh2 }
                setMinTimeThresh={ setMinTimeThresh }
                setSendReturnToNormalMessage={ setSendReturnToNormalMessage }
                setReturnToNormalMessage={ setReturnToNormalMessage}
              />
            </Container>
          </Tab>
          <Tab eventKey="devices" title="Devices">
            <NotificationDevicesTab
              allSensors={ props.allSensors }
              allGateways={ props.allGateways } 
              assignedDevices={ assignedDevices } 
              setAssignedDevices={ setAssignedDevices }
              deviceType = { props.deviceType }
              tabKey={ tabKey }
            />
          </Tab>
          <Tab eventKey="users" title="Users">
          <Container style={{paddingBottom:5, paddingLeft: 0, paddingRight: 0}}>
            {tabKey === "users" ? <NotificationUsersTab 
                    allUsers={ props.allUsers }
                    assignedUsers= { assignedUsers }
                    handleSetAssignedUsers={ setAssignedUsers }
                  /> : <></>}
          </Container>
          </Tab>
        </Tabs> }
        { tabKey === "history" ? <></> : 
          <><Button onClick={handleSave}>Save</Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <DeleteIcon htmlColor="black" />Delete</Button>
            <Button onClick={() => testNotification()}>
            <SendIcon htmlColor='black' />Test</Button>
          </> }
      </Accordion.Body>
    </Accordion.Item>
  );
}

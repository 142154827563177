 
import { useState, useEffect } from 'react';
import { listAccountUsers } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useLoadAllUserAccounts(userId) {
  const [userAccountList, setUserAccountList] = useState(null);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(listAccountUsers, { userId: userId })).catch((error) => console.log("graphQL error: ", error));
      const userAccountList = result.data.listAccountUsers.items;
      setUserAccountList(userAccountList)
    }
    userId&&fetchData();
  },[userId]);
  return userAccountList;
}
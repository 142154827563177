import { useState, useEffect } from 'react';
import { queryNotificationGroupsByAccount } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useLoadAllNotifications(accountId) {
  const [allNotifications, setAllNotifications] = useState(null);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(queryNotificationGroupsByAccount, { account: accountId, limit: 200 })).catch((error) => console.log("graphQL error: ",error));
      setAllNotifications(result.data.queryNotificationGroupsByAccount.items);
    }
    accountId&&fetchData();
  }, [accountId]);
  return allNotifications;
}

import React, { useState } from "react";

import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from "react-bootstrap/Modal";
import TextField from '@mui/material/TextField';

import { DeleteGateway} from "../../Database.jsx";

import { IOTData } from "../../services.js";
import DeleteDeviceModal from "../DeleteDeviceModal.jsx";
const iotdata = IOTData();
const cmdTopic = "cmd/gateway/monnit/settings";

export default function GatewaySettingsTab(props) {
  const [gatewayHeartbeat, setGatewayHeartbeat] = useState(props.gatewayHeartbeat);
  const [gatewayName, setGatewayName] = useState(props.gatewayName)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState(null);

  // TODO: fix this method, only deletes from database
  const handleDeleteGateway = () => {
    // TODO: delete confirmation modal!
    DeleteGateway(props.id)
    .then(
      (result) => {
        alert("Gateway deleted!"); //TODO: alert popover
        console.log(result);
        window.location.reload();
      },
      (error) => {
        setError(error);
        alert("Error deleting gateway");
      }
    );
  }

  const handleSaveSettings = (event) => {
    event.preventDefault();
    const iot_data = {
      deviceId: props.id,
      gwid: props.id,
      name: gatewayName,
      heartbeat: gatewayHeartbeat,
      pendingChange: true,
      version: props.type
    }
    console.log("iot_data", iot_data);
    // Send reform message to sensor topic
    const params = {
      topic: cmdTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log(data);
        alert("Successfully updated settings.");
      }
    });
  }

  if(error) {console.log (error)}

  return(
    <>
      <Button variant="contained" color="error" onClick={() => setShowDeleteModal(true)}>
        Delete
        <DeleteForeverIcon htmlColor="black" style={{ marginRight: "5px" }} />
      </Button>
      <DeleteDeviceModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        handleDeleteDevice={handleDeleteGateway}
        deviceName={gatewayName}
        deviceId={props.id}
      />
      <form>
        <div className="form-group">
          <label className="input-label">Name</label>
          <input name= "deviceName" type="text" className="form-control" value={gatewayName} onChange={(e) => setGatewayName(e.target.value)}></input>
          <label className="input-label">Heartbeat</label>
          <input name= "deviceHeartbeat" type="number" className="form-control" min="1" max="60" value={gatewayHeartbeat} onChange={(e) => setGatewayHeartbeat(e.target.value)} ></input>
          <input className="btn btn-primary" type="submit" value="Save" onClick={(e) => handleSaveSettings(e)} />
        </div>
      </form>
    </>
  )
}
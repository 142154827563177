import React, { useState, useEffect, useRef } from 'react';

// MUI imports
import SendIcon from '@mui/icons-material/Send';
import Switch from '@mui/material/Switch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';


import { Accordion, Row, Container, Button, Tabs, Tab, Col } from "react-bootstrap";
import { ListFirstNotifications } from "./NotificationQueries";
import NotificationEditTab from './NotificationEditTab';
import NotificationDevicesTab from './NotificationDevicesTab';
import NotificationUsersTab from './NotificationUsersTab';
import NotificationHistory from './NotificationHistory';
import { UpdateNotification } from '../Database.jsx';

import { format } from 'date-fns'

import { IOTData } from "../services.js";

//TODO: update sensor settings / status
const iotdata = IOTData();

export default function GatewayNotification(props) {

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [assignedDevices, setAssignedDevices] = useState(!props.assignedDevices ? [] : props.assignedDevices);
  const [tabKey, setTabKey] = useState('history');
  const [loadNotificationHistory, setLoadNotificationHistory] = useState(false);
  const [notificationName, setNotificationName] = useState(props.name);
  const [notificationStatus, setNotificationStatus] = useState(props.notificationStatus)
  const [message, setMessage] = useState(props.message);
  const [minThresh, setMinThresh] = useState(props.minThresh);
  const [maxThresh, setMaxThresh] = useState(props.maxThresh);
  const [assignedUsers, setAssignedUsers] = useState(!props.assignedUsers ? [] : props.assignedUsers);
  const [lastSentDate, setLastSentDate] = useState("N/A");
  const [returnToNormalMessage, setReturnToNormalMessage] = useState(props.returnToNormalMessage || "<Name> has returned to normal reading");
  const [sendReturnToNormalMessage, setSendReturnToNormalMessage] = useState(props.sendReturnToNormalMessage || false);
  const cmdTopic = "cmd/gateway/monnit/NotificationSettings";
  const didMount = useRef(false);

  const handleLoadNotificationHistory = () => {
    setLoadNotificationHistory(true);
  }
  
  useEffect(() => {
    // GetFirstSensorMessage(props.id, null, 1)
    ListFirstNotifications(props.id, null, 1)
    .then(
      (result) => {
        if(result.data.listFirstNotifications.items.length > 0) {
          // console.log("listFirstNotifications1", result.data.listFirstNotifications.items)
          setLastSentDate(format(new Date(result.data.listFirstNotifications.items[0].timestamp*1000), "MM/dd/yyyy h:mma"));
        }
        setIsLoaded(true)
      }, error => {
        setIsLoaded(true)
        setError(error) 
      }
    );
  });

  useEffect(() => {
    const handleToggle = () => {
      // const checked = event.target.checked
      // const toggleStatus = checked ? "armed" : "disarmed";
      // setNotificationStatus(toggleStatus);
  
      const iot_data = {
        assignedDevices: assignedDevices,
        assignedUsers: assignedUsers,
        networkId: props.networkId,
        networkName: props.networkName,
        notificationId: props.notificationId,
        notificationStatus: notificationStatus,
        notificationType: "gatewayInactivity"
      }
  
      const params = {
        topic: cmdTopic,
        payload: JSON.stringify(iot_data),
        qos: 0,
      };
      UpdateNotification(iot_data)
      .then(
        iotdata.publish(params, function (err, data) {
          if (err) console.log(err, err.stack);
          else {
            // console.log("response", data);
            // console.log("Sent data ", iot_data);
              // alert("Notification status updated!");
            }
          })
        );
    }
    if ( !didMount.current ) {
      didMount.current = true;
    }
    else {
      handleToggle();
    }
  }, [assignedDevices, assignedUsers, notificationStatus, props.networkId, props.networkName, props.notificationId]);

  if(!isLoaded) { return <></> }

  const handleDelete = () => {
    const iot_data = {
      account: props.account,
      accountId: props.account,
      assignedDevices: assignedDevices,
      assignedUsers: assignedUsers,
      message: message,
      maxThresh: maxThresh,
      networkId: props.networkId,
      networkName: props.networkName,
      notificationId: props.notificationId,
      notificationName: notificationName,
      notificationStatus: "disarmed",
      notificationType: "gatewayInactivity"
    }

    // console.log("iot_data", iot_data);

    const params = {
      topic: cmdTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log("response", data);
        console.log("Sent data ", iot_data);
        props.onDeleteNotification(props.notificationId)
        // alert("Notification Status Updated!");
      }
    });
    
  }

  // TODO: if a device is removed from the list of devices then update / remove the sensor from the notification
  const handleSave = () => {
    const iot_data = {
      account: props.account,
      accountId: props.account,
      assignedDevices: assignedDevices,
      assignedUsers: assignedUsers,
      message: message,
      maxThresh: maxThresh,
      networkId: props.networkId,
      networkName: props.networkName,
      notificationId: props.notificationId, 
      notificationName: notificationName,
      notificationStatus: notificationStatus,
      notificationType: "gatewayInactivity",
      returnToNormalMessage: returnToNormalMessage, 
      sendReturnToNormalMessage: sendReturnToNormalMessage
    }

    // console.log("iot_data", iot_data);

    const params = {
      topic: cmdTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    UpdateNotification(iot_data)
    .then(
      iotdata.publish(params, function (err, data) {
        if (err) console.log(err, err.stack);
        else {
          // console.log("response", data);
          // console.log("Sent data ", iot_data);
            alert("Notification Settings Saved!");
          }
        })
      );
    
  }

  // TODO: fix the redundancies here, would like to use the handleSave only to simplify.
  const handleSwitchToggle = (event) => {
    // reset the notification states on notification toggle / updates GUI appearance based on flags
    // const checked = event.target.checked
    // const toggleStatus = checked ? "armed" : "disarmed";
    
    setNotificationStatus(event.target.checked ? "armed" : "disarmed");
    console.log("Checked", notificationStatus);
  }

 

  const testNotification = () => {
    console.log("TODO: gateway test notifications!");
    const topic = "data/testIdle";
    const iot_data = {
      account: props.account,
      assignedDevices: assignedDevices,
      assignedUsers: assignedUsers,
      deviceId: assignedDevices[0],
      message: message,
      name: notificationName,
      networkId: props.networkId,
      networkName: props.networkName,
      notificationId: props.notificationId,
      notificationName: notificationName,
      notificationStatus: "alerting",
      notificationType: "TEST"
    }

    console.log("iot_data", iot_data);
    // Send reform message to sensor topic
    const params = {
      topic: topic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log(data);
        alert("Test notification sent.");
      }
    });
    // send JSON string to IoT core with the message and notification list. set a flag so it skips the ack steps and other stuff. 
  }

  // set the status icon based on the state of the notification
  var notificationStatusIcon=<CheckCircleIcon htmlColor="green" />;
  // check sensors for active notifications, then set status here.
  if(notificationStatus === "disarmed") {
    notificationStatusIcon=<CancelIcon htmlColor="gray" />;
  }
  else if(notificationStatus === "alerting") {
    notificationStatusIcon=<ErrorIcon htmlColor="red" />;
  }
  else if(notificationStatus === "acknowledged") {
    notificationStatusIcon=<PauseCircleIcon htmlColor="orange" />;
  }

  return (
    <>
      <Accordion.Item key={props.id} eventKey={props.id}>
        <Container fluid>
          <Switch 
            onChange={ handleSwitchToggle } 
            checked = {(notificationStatus !== "disarmed")} 
            className="dynamic-class-name"
          />
          <b style={{textAlign:"left"}}>{notificationName}</b>&nbsp;Network: {props.networkName}

        </Container>
        <Accordion.Header onClick={ handleLoadNotificationHistory }>
          <Col style={{padding:"0px"}}>
              <Row className="align-items-center">
                <Col xs="1" lg="auto" style={{minWidth:"10px"}}>
                  <Row><Container fluid>{notificationStatusIcon}</Container></Row>
                </Col>
                <Col xs="3" lg="1" >
                  <img title={props.version} 
                    src={'Images/SensorImages/Smart Barn gateway70x70.png'} 
                    alt="Gateway icon" 
                  />
                </Col>
                <Col><Container fluid style={{textAlign: "left"}}>Last sent: {lastSentDate}</Container></Col>
              </Row>
            </Col>
          </Accordion.Header>
          <Accordion.Body style={{padding:"0px"}}>
          { 
            loadNotificationHistory && <Tabs
              id="controlled-tab-example"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
              className="mb-3"
            >
              <Tab eventKey="history" title="History">
                <NotificationHistory id={ props.id } />
              </Tab>
              <Tab eventKey="edit" title="Edit">
                <Container style={{height: 400, paddingBottom:50, paddingLeft: 0, paddingRight: 0}} >
                  <NotificationEditTab 
                    id={ props.id } 
                    notificationName={ notificationName }
                    setName={ setNotificationName }
                    message={ message } 
                    deviceType={ props.deviceType }
                    minThresh={ minThresh }
                    maxThresh={ maxThresh } 
                    setMessage={ setMessage }
                    setMinThresh={ setMinThresh }
                    setMaxThresh={ setMaxThresh }
                    sendReturnToNormalMessage={ sendReturnToNormalMessage }
                    returnToNormalMessage={ returnToNormalMessage }
                    setSendReturnToNormalMessage={ setSendReturnToNormalMessage }
                    setReturnToNormalMessage={ setReturnToNormalMessage}
                  />
                </Container>
              </Tab>
              <Tab eventKey="devices" title="Devices">
                <Container style={{height: 400, paddingBottom:50, paddingLeft: 0, paddingRight: 0, width: "100%"}} >
                  <NotificationDevicesTab
                    allSensors={props.allSensors}
                    allGateways={ props.allGateways } 
                    assignedDevices={ assignedDevices } 
                    setAssignedDevices={ setAssignedDevices }
                    deviceType = {props.deviceType}
                    tabKey={ tabKey }
                  />
                </Container>
              </Tab>
              <Tab eventKey="users" title="Users">
              <Container style={{paddingBottom:5, paddingLeft: 0, paddingRight: 0}} >
                {tabKey === "users" ? <NotificationUsersTab 
                  allUsers={ props.allUsers }
                  assignedUsers= { assignedUsers }
                  handleSetAssignedUsers={ setAssignedUsers }
                /> : <></>}
              </Container>
              </Tab>
            </Tabs> 
            }
            {!(tabKey === "history") && <>
              <Button onClick={handleSave}>Save</Button>
              <Button variant="danger" onClick={() => handleDelete()}>
              <DeleteIcon htmlColor="black" />Delete</Button>
              <Button onClick={() => testNotification()}><SendIcon htmlColor='black'/> Test</Button></>}
          </Accordion.Body>
      </Accordion.Item>
    </>
  );
}

import React from "react";
// import { Route } from "react-router-dom";
// import "./NotificationsPage.css";
import Card from "react-bootstrap/Card"
import Stack from "react-bootstrap/Stack"
import "./Account.css";
import AccountEditModal from "./AccountEditModal"

export default function AccountInfo (props) {
  return (
    <Card className="card h-100" border='primary' key={ props.accountId }>
      <Card.Header>{props.accountName}</Card.Header>
        <Card.Body>
          <Stack >
            <div><b>Company: </b>{ props.accountName } </div>
            <div><b>Timezone: </b>{ props.timezone } </div>
            <div><b>Administrator:</b></div>
            <div><b>Address: </b> {props.address} </div>
            <div><b>City: </b>{props.city}</div>
            <div><b>State:</b>{props.state}</div>
            <div><b>Zip:</b>{props.zipCode}</div>
            <div><b>Country: </b>{props.country}</div>
            <div><b>Primary point of contact phone:</b></div>
          </Stack>
        </Card.Body>
        <AccountEditModal 
          accountId={ props.accountId }
          accountName={props.accountName} 
          address={props.address} 
          city={props.city} 
          state={ props.state }
          country={ props.country }
          timezone={ props.timezone }
          zipCode={ props.zipCode }
          buttonText="Edit Account Info" 
          id="editAccountForm" 
        />
    </Card>
  );
}
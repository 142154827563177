import React from 'react';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Switch from '@mui/material/Switch';
import NotificationToolTip from '../Components/Buttons/Notifications/NotificationToolTip';
import EditReturnToNormal from '../Components/Buttons/Notifications/EditReturnToNormal';

export default function NotificationEditTemperatureTab(props) {
  const handleFreezeAlarmStatus = (event) => {
    props.setFreezeAlarmStatus(event.target.checked ? "armed" : "disarmed");
  }
  
  const handleSetFreezeAlarmThresh = (e) => {
    props.setFreezeAlarmThresh(props.scaleValue1 === "C" ? e.target.value : ((e.target.value-32)/1.8).toFixed(1))
  }

  const handleSetTemperatureMaxThresh = (e) => {
    props.setMaxThresh(props.scaleValue1 === "C" ? e.target.value : ((e.target.value-32)/1.8).toFixed(1))
  }

  const handleSetTemperatureMinThresh = (e) => {
    props.setMinThresh(props.scaleValue1 === "C" ? e.target.value : ((e.target.value-32)/1.8).toFixed(1))
  }

  const handleSetScaleValue1 = (e) => {
    props.setScaleValue1(e)
  }

  const handleMessage = (e) => {
    props.setMessage(e.target.value);
  }
  const handleName = (e) => {
    props.setName(e.target.value);
  }

  const freezeAlarmCheck = props.freezeAlarmStatus === "armed" ? true : false;

  return(
    <>
    <Form>
      <Form.Group className="mb-3" controlId="formNotificationName">
        <Form.Label>Notification Name</Form.Label>
        <Form.Control type="text" defaultValue={props.notificationName} onChange={ (e) => handleName(e) } />
        <Form.Label>Notification Message &nbsp; </Form.Label>
        <NotificationToolTip />
        <Form.Control type="text" defaultValue={ props.message } onChange={ (e) => handleMessage(e) } />
        <Form.Label>Alert when</Form.Label>
          <InputGroup className="mb-3">
          <InputGroup.Text>below:</InputGroup.Text>
          <Form.Control 
            type="number" 
            defaultValue={ props.scaleValue1 === "C" ? (props.minThresh*1.0).toFixed() : ((props.minThresh*1.8)+32).toFixed() } 
            min={ -40 }
            max={props.scaleValue1 === "C" ? (props.maxThresh*1.0).toFixed() : ((props.maxThresh*1.8)+32).toFixed()}
            onChange={ (e) => handleSetTemperatureMinThresh(e) }
            />
          <InputGroup.Text>{props.scaleValue1}</InputGroup.Text>
          <InputGroup.Text>or above:</InputGroup.Text>
            <Form.Control 
              type="number" 
              defaultValue={ props.scaleValue1 === "C" ? (props.maxThresh*1.0).toFixed() : ((props.maxThresh*1.8)+32).toFixed() }
              min={ props.scaleValue1 === "C" ? (props.minThresh*1.0).toFixed() : ((props.minThresh*1.8)+32).toFixed() }
              max={ props.scaleValue1 === "C" ? 125 : 257 }
              onChange={ (e) => handleSetTemperatureMaxThresh(e) }
            />
            <InputGroup.Text>{props.scaleValue1}</InputGroup.Text>
          </InputGroup>
          <Form.Label>Freeze alarm? (must set below { ((props.minThresh*1.8)+32).toFixed()} F / {(props.minThresh*1.0).toFixed() } C)  </Form.Label>
          <InputGroup>
            <Switch checked={props.freezeAlarmStatus === "armed" ? true : false} onChange={handleFreezeAlarmStatus} />
            <Form.Control 
              type="number" 
              defaultValue={ props.scaleValue1 === "C" ? (props.freezeAlarmThresh*1.0).toFixed() : ((props.freezeAlarmThresh*1.8)+32).toFixed() } 
              disabled={!freezeAlarmCheck}
              min={ -40 }
              max={props.scaleValue1 === "C" ? (props.minThresh*1.0).toFixed() : ((props.minThresh*1.8)+32)}
              onChange={ (e) => handleSetFreezeAlarmThresh(e) }
            />
            <InputGroup.Text>{props.scaleValue1}</InputGroup.Text>
          </InputGroup>
          <Form.Label>Units (F or C?)</Form.Label>
          <Form.Select defaultValue={props.scaleValue1} onChange={(e) => handleSetScaleValue1(e.target.value)}> {/*TODO: add handleSelectUnits for temperature */}
            <option value="F">F</option>
            <option value="C">C</option>
          </Form.Select>
          <EditReturnToNormal
            sendReturnToNormalMessage={props.sendReturnToNormalMessage}
            setSendReturnToNormalMessage={props.setSendReturnToNormalMessage}
            returnToNormalMessage={props.returnToNormalMessage}
            setReturnToNormalMessage={props.setReturnToNormalMessage}
          />
      </Form.Group>
    </Form>
    </>
  );
}
import React from 'react'
// import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './Charts.css'

// date-fns
import 'chartjs-adapter-date-fns';

import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import endOfDay from 'date-fns/endOfDay';
import isSameDay from 'date-fns/isSameDay';


// Charts JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Legend,
  BarController,
  BarElement
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import Container from 'react-bootstrap/Container';

// register chartsjs functions and plugins
ChartJS.register(
  LinearScale,
  BarController,
  BarElement,
  CategoryScale,
  PointElement,
  LineElement,
  Legend,
  // Tooltip,
  LineController,
  ChartDataLabels
);

export default function PulseChart(props) {
  const chartData = [...props.messages];
  const days = eachDayOfInterval({start: props.startDate, end: props.endDate});
  var sumVals = [];
  var daysSum = [];

  var sumVal = days.forEach(function(day, index) {
    var total = 0;
    chartData.forEach(function(items) {
      var d = new Date(items.timestamp*1000);
      if (isSameDay(day, d)) {
        total += parseFloat(items.data);
      }
    })
    sumVals.push({x: index, y: total })
    daysSum.push([total.toFixed + " " + props.scaleValue1])
  })
  
  const datapoints = chartData.map(x => (
    {x: new Date(x.timestamp*1000), y: parseFloat(x.data)}
  ));
  
  const data = {
    datasets: [
      {
        // label: props.deviceName + " - " + props.deviceId,
        // id: 1,
        label: props.scaleValue1,
        type: 'line',
        backgroundColor: 'rgb(116, 170, 67)',
        borderColor: 'rgb(116, 170, 67)',
        data: datapoints,
        pointRadius: 1,
        pointHitRadisu: 0,
        borderWidth: 1,
        animation: false, // set here and not in options? weird...
        xAxisID: 'x',
        yAxisID: 'y',
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        // label: props.deviceName + " - " + props.deviceId,
        // id: 2,
        label: "Daily totals",
        type: 'bar',
        // backgroundColor: 'rgb(116, 170, 67)',
        borderColor: 'blue',
        data: sumVals,
        pointRadius: 0,
        pointHitRadius: 0,
        borderWidth: 1,
        animation: false, // set here and not in options? weird...
        xAxisID: 'x2',
        yAxisID: 'y2',
        datalabels: {
          formatter: (value) => value.y.toFixed(1),
          align: 'end',
          anchor: 'end',
          labels: {
            title: {
              color: 'blue',
              backgroundColor: 'lightGray',
              borderColor: 'blue',
              borderWidth: 1
            },
          },
        },
      },
    ]
  };
// dataValues !== null ? (scaleValue2.charAt(0) === "F") ? ((parseFloat(dataValues.Temperature) * 9.0/5.0) + 32.0).toFixed(1) + "F" : dataValues.Temperature + "C" : '--'
  const yMin = props.minThresh;
  const yMax = props.maxThresh;
  var options = {
    animation: false,
    parsing: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      
      // decimation: decimation,
      tooltip: {
        enabled: false,
      },
      interaction: {
        mode: 'nearest'
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: yMin ,
            yMax: yMin,
            borderColor: 'red',
            borderWidth: 2,
          },
          line2: {
            type: 'line',
            yMin: yMax,
            yMax: yMax,
            borderColor: 'red',
            borderWidth: 2,
          }
        }
      }
    },
    scales: {
      x: {
        adjustScaleRange: false,
        min: props.startDate,
        max: endOfDay(props.endDate),
        type: 'time',
        time: { unit: 'day' },
      },
      x2: {
        labels: daysSum,
        position: "top",
        offset: true,
        display: false,
        ticks: {
          // font: {
          //     size: 16,
          // },
          color: 'blue'
        },
        grid: {
          display: false
        }
      },
      y: {
        type: 'linear',
        labels: null,
        min: 0
      },
      y2: {
        type: 'linear',
        labels: null,
        position: 'right',
        grid: {
          display:false
        },
        min: 0,
      },
    }
  }
  // console.log("options", options);
  return(
    <Container fluid className='sensor-charts' >
      <Chart type='line' data = {
          data
        } 
        options = {
            options 
        }
        key={ props.id }
        />
    </Container>
  );
}
import React, { useState } from "react";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { v4 as uuidv4 } from 'uuid';
import { Col, Row, InputGroup } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';


export default function NotificationUserInfo (props) {
  const [userId, setUserId] = useState(props.userId);
  const [contactMethod, setContactMethod] = useState(props.contactMethod || 'email');
  const index = props.allUsers.findIndex( (users) =>
    users.userId === userId
  );
  console.log("USER ", userId)
  // Show "DELETED if user doesn't exist anymore"
  const userEmail = index === -1 ? "DELETED" : props.allUsers[index].email;
  const userSMS = index === -1 ? "DELETED" : props.allUsers[index].cellPhone;
  const userPhone = index === -1 ? "DELETED" : props.allUsers[index].cellPhone;
  // if(index === -1) { return <></>}
  

  const handleSetUser = (e) => {
    setContactMethod(null);
    setUserId(e.target.value);
    setContactMethod({email: userEmail});
    props.updateUser(e.target.value, props.i);
  }

  const handleSetContactMethod = (e) => {
    const contact = (e.target.value === "sms" ? userSMS : e.target.value === "phone" ? userPhone : userEmail)
    props.handleUpdateContact(e.target.value, props.i, contact);
  }

  const handleSetNotificationDelay = (e) => {
    props.handleSetNotificationDelay(props.i, e.target.valueAsNumber);
  }

  var allUsersOption = props.allUsers.map((user) => 
    <option key={uuidv4()} value={user.userId}>{user.firstName}{" "}{user.lastName}</option>
  );

  if(index === -1){
    allUsersOption.push(
      <option key={uuidv4()} value={userId} disabled>DELETED: {userId}</option>
    )
  }

  

  let sms="";
  let cellPhone="";
  let email="";
  sms = <option key={uuidv4()} disabled={!userSMS} value={'sms'}>SMS: {userSMS} </option>;
  cellPhone = <option key={uuidv4()} disabled={!userPhone} value={'phone'}>Call: {userPhone} </option>;
  email = <option key={uuidv4()} value={'email'}>Email: {userEmail} </option>;


  const title = 
    <Form >
      <Row>
        <Col sm="4">
          <Form.Select required name="Account User"value={userId} onChange={(e) => handleSetUser(e) }>
            {allUsersOption}
          </Form.Select>
        </Col>
        <Col style={{paddingLeft:10}} sm="4">
          <Form.Select required name="Contact Method" value={contactMethod} onChange={(e) => handleSetContactMethod(e)}>
            {sms}
            {cellPhone}
            {email}
          </Form.Select>
        </Col>
        <Col style={{paddingLeft:10}} sm="4">
          <InputGroup>
            <InputGroup.Text>Delay:</InputGroup.Text>
            <Form.Control
              type="number" 
              min="0"
              max="10"
              defaultValue={props.notificationDelayMinutes}
              onChange={(e) => handleSetNotificationDelay(e)}
            />
            <InputGroup.Text>minutes</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row> 
    </Form>

  return(
    <Card style={{padding:0}}>
      <CardHeader
        action={
          <RemoveCircleIcon style={{marginTop:10}}onClick={() => props.deleteUser(props.i)} />
        }
        title={title}
        style={{paddingLeft:0, paddingTop:0}}
      />
    </Card>
  );
}
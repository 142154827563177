import React, { useState, useEffect } from "react";
import { format } from 'date-fns'

import FormatLatestSensorMessage from "../Utilities/FormatLatestSensorMessage.jsx";
import { GetMostRecentSensorMessage } from "../Database.jsx";
import "./Sensor.css";
import { Accordion, Col, Container, Row, Badge } from "react-bootstrap";
import SensorBody from "./SensorBody";
import GetSensorBatteryIcon from "../Utilities/GetSensorBatteryIcon.jsx";
import GetSensorSignal from "../Utilities/GetSensorSignal.jsx";
import GetSensorStatusIcons from "../Utilities/GetSensorStatusIcons.jsx";
import GetDeviceIcon from "../Utilities/GetDeviceIcon.js";
import SensorAlertOverlay from "../Components/Buttons/SensorAlertOverlay.jsx";

/** 
 * Sensor function. Creates and renders the sensor accordians cells. Called by DisplayDevices.jsx
 */
export default function Sensor(props) {
  // expexted check in time is within the last 15 minutes (gateway heartbeat + sensor heartbeat + a minute of extra time)
  const [lastMessageTime, setLastMessageTime] = useState("--");
  const [sensorName, setSensorName] = useState(props.name);
  const [sensorMessage, setSensorMessage] = useState({timestamp: 0});
  const [notificationStatus, setNotificationStatus] = useState(props.notificationStatus);
  const [pendingChange, setPendingChange] = useState(props.pendingChange);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [loadSensorData, setLoadSensorData] = useState(false);
  const handleLoadSensorData = () => {
    setLoadSensorData(true);
  }

  // gets the first sensor message on loading, limits scan to single message. 
  useEffect(() => {
    GetMostRecentSensorMessage(props.id, null, 1)
    .then(
      (result) => {
        if(result.data.listFirstSensorMessages.items.length === 0){
          setSensorMessage([]);
          setIsLoaded(true);
        }
        else {
          setSensorMessage(result.data.listFirstSensorMessages.items[0]);
          setLastMessageTime(format(new Date(result.data.listFirstSensorMessages.items[0].timestamp*1000), "MM/dd/yyyy h:mma"));
          setIsLoaded(true);
        }
        
      }, error => {
        setIsLoaded(true)
        setError(error)
      }
    );
  },[props.id]);
  if(!isLoaded){
    return null;
  }

  if(error) {
    console.log(error)
  }
  
  const afterExpectedCheckinTime = sensorMessage.timestamp*1000 - Date.now()+(1000*60*(props.heartbeat+10)) < 0 ? true : false;
  const batteryIcon = sensorMessage.length !== 0 && GetSensorBatteryIcon(sensorMessage.battery);
  //NOTE: have to put MUI icons as the child of a container for them to be visible.
  return (
    <Accordion.Item eventKey={props.id}>
      <Col lg='auto'>
        <b style={{textAlign:"left"}}>{sensorName}</b> <p style={{fontSize:"small", display:"inline", fontStyle:"italic" }}>{props.deviceId} {props.networkName}</p>
      </Col>
      <SensorAlertOverlay 
        deviceId={props.id} // TODO: use props.deviceId? I think it's mismatched tho right now.
        notificationId={props.notificationId}
        notificationStatus={notificationStatus}
        setNotificationStatus={setNotificationStatus}
        
      />
      <Accordion.Header onClick={ handleLoadSensorData }>
        <Col style={{padding:"0px"}}>
          <Row className="align-items-center" style={{filter: afterExpectedCheckinTime || lastMessageTime==='--' ? 'grayscale(100%)' : 'none'}}>
            <Col xs="1" lg="auto" style={{minWidth:"10px"}} >
              <GetSensorStatusIcons
                afterExpectedCheckinTime={ afterExpectedCheckinTime } // expected check in = heartbeat + a typical GW heartbeat*milliseconds*minutes
                notificationStatus={ props.notificationStatus } 
                pendingChange = { pendingChange }
                timestamp={ sensorMessage.timestamp }
                message={ sensorMessage }
              />
            </Col>
            <Col xs="3" lg="1" >
              <img title={props.version || "sensor"} 
                className="sensor-image-column" 
                src={ GetDeviceIcon(props.deviceType) } 
                alt="Sensor" 
              />
            </Col>
            <Col xs="1" lg="auto" >
              <Row>
                <Container fluid>
                  <GetSensorSignal monnitGeneration={ props.monnitGeneration } signalPercent={ parseInt(sensorMessage.SignalPercent) } afterExpectedCheckinTime={ afterExpectedCheckinTime }/>
                </Container>
              </Row>
              <Row>
                <Container fluid>{batteryIcon}</Container>
              </Row>
            </Col>
            <Col xs="4" lg="4">
          {/* TODO: the header loads over and over and over and over every time the sensor message gets parsed... */}
                <Row className="sensor-reading-row">
                  <h2>
                    <FormatLatestSensorMessage 
                      data={ sensorMessage.data } 
                      dataValues={ sensorMessage.dataValues }
                      version = { props.version }
                      scaleLabel = { props.scaleLabel }
                      scaleValue1={ props.scaleValue1 } 
                      scaleValue2={ props.scaleValue2 }
                      sensorName={ sensorName }
                      setSensorName={ setSensorName }
                    />
                  </h2>
                </Row>           
            </Col>
            <Col style={{ display:"inline", padding:"0px" }}>{lastMessageTime}</Col>
            <Col xs="3" sm="5" style={{ textAlign:"center", paddingLeft:"5px" }}>
              <Badge pill bg={ notificationStatus === "disarmed" || notificationStatus === "N/A" ? "secondary" : sensorMessage.data < props.minThresh && sensorMessage.data > props.maxThresh ? "danger" : notificationStatus === "armed" ? "success" : "secondary"} style={{width:"170px"}}>Notification: {notificationStatus}</Badge>
            </Col>
          </Row>
        </Col>
      </Accordion.Header>
      <Accordion.Body style={{padding:"0px" }}>
        <Container fluid >
          <Container fluid>
          <Col style={{ textAlign: "left", color: "#74AA43", paddingLeft:"0px" }}>
            <Badge bg="secondary">Gateway ID: {sensorMessage.gwid}</Badge>
            <Badge bg="secondary">ID: { props.id }</Badge>
          </Col>
          </Container>
          {loadSensorData === true && (<SensorBody 
            id={props.id}
            account={props.account} 
            accountId={props.accountId}
            assignedUsers={props.assignedUsers}
            dataValueOffset={props.dataValueOffset}
            deviceId={props.id}
            deviceType={props.deviceType}
            heartbeat={props.heartbeat}
            manufacturer={props.manufacturer}
            maxThresh={props.maxThresh}
            maxThresh2={ props.maxThresh2}
            minThresh={props.minThresh}
            minThresh2={ props.minThresh2 }
            message={props.message}
            sensorName={ sensorName }
            setSensorName={ setSensorName }
            setPendingChange={ setPendingChange }
            networkId={props.networkId}
            notificationId={props.notificationId}
            notificationName={props.notificationName}
            notificationSchedule={ props.notificationSchedule }
            notificationStatus={ notificationStatus }
            notificationPausedUntilDateTime={ props.notificationPausedUntilDateTime }
            scaleLabel={ props.scaleLabel }
            scaleValue1={props.scaleValue1}
            scaleValue2={props.scaleValue2}
            sensorCode={props.sensorCode}
            sensorMessage={ sensorMessage }
            version={props.version}
        />)}
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
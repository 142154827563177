 
import { useState, useEffect } from 'react';
import { listAccountUsers } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useLoadAllUserAccounts(accountId) {
  const [allUsers, setAllUsers] = useState(null);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(listAccountUsers, { filter: {accountId: {eq: accountId}}, limit: 1000, nextToken: null })).catch((error) => console.log('GraphQL error', error));
      var userList = result.data.listAccountUsers.items;
      userList.push({
        "userId": "contact@smartbarn.io",
        "accountId": "c4bd7b20-3bab-11ea-a975-0523f21e6d1f",
        "username": "contact@smartbarn.io",
        "email": "contact@smartbarn.io",
        "landLine": null,
        "cellPhone": "937-697-1890",
        "firstName": "SmartBarn",
        "lastName": "Support",
        "accounts": "c4bd7b20-3bab-11ea-a975-0523f21e6d1f|",
        "permissions": 2,
        "emailVerified": null,
        "phoneVerified": null,
        "cellVerified": null,
        "timezone": null,
        "parentAccountId": "c4bd7b20-3bab-11ea-a975-0523f21e6d1f",
        "accountName": "Smart Barn Office",
        "notes": "",
        "dateCreated": "1649525158358"
      })
      // sort by first then last name.
      userList.sort((a,b) => {
        var nameA = a.firstName.toUpperCase() + a.lastName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.firstName.toUpperCase() + a.lastName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
      setAllUsers(userList);
    }
    accountId&&fetchData();
  },[accountId]);
  return allUsers;
}

function sortByFirstNameLastName(userList){
  return userList;
}
import React from "react";
import { ListAccounts } from "../Database.jsx"
import "./Administration.css";
import AdministrationAccountsTable from "./AdministrationAccountsTable";
import CreateNewDevice from "./CreateNewDevice.jsx";
import CreateNewAccount from "./AdminCreateNewAccount";
import Container from "react-bootstrap/Container";
import SensorLookup from "./SensorLookup.jsx";
import GatewayLookup from "./GatewayLookup.jsx";
export default class Administration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      user: props.account,
      allAccounts: null,
      isLoaded: false,
      error: null
    };
  }

  componentDidMount() {
    ListAccounts(1000, null)
    .then(
      (result) => {
        // console.log("allAccounts", result.data.listAccounts.items)
        this.setState({
          allAccounts: result.data.listAccounts.items,
          isLoaded: true,
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
      });
      }
    )
  }

  render() {
    if(!this.state.isLoaded) {
      return(null);
    }

    return (
      <Container fluid>
        <CreateNewAccount buttonText="Create New Account" id="registerNewAccount" />
        <CreateNewDevice buttonText="Register New Device" id="registerDevice" allAccounts={this.state.allAccounts}/>
        <SensorLookup id="deviceLookup" />
        <GatewayLookup id="gatewayLookup" />

        Total accounts: {this.state.allAccounts.length}
          <AdministrationAccountsTable 
            parent={ this } 
            account={ this.state.account } 
            user={ this.state.user } 
            allAccounts = { this.state.allAccounts } 
          />
      </Container>
    );
  }
}
import { API, graphqlOperation } from "aws-amplify";
import { 
  listAccountUsers,
  querySensorsByAccountIndex,
  listFirstSensorMessages,
  listSensorMessagesByDay,
  listGatewaysByAccount,
  listGatewayMessagesByDay,
  listFirstGatewayMessages,
  queryNotificationGroupsByAccount,
  listNetworksByAccount,
  listAccounts,
  getAccounts,
  queryNotificationsByNotificationGroup,
} from "./graphql/queries";

 import { 
    
   createAccounts,
   updateAccounts,

   createAccountUser,
   updateAccountUser,
   deleteAccountUser,

   createNetwork,
   updateNetwork,
   deleteNetwork,
      
   createGateways,
   updateGateways,
   deleteGateways,
   
   createSensors,
   updateSensors,
   deleteSensors,

   createNotificationGroups,
   updateNotificationGroups,
   deleteNotificationGroups,

   createNotifications
   
   
 } from "./graphql/mutations";

// more accurately list accounts for user
 export async function ListAccountUsers(userId) {
  const apiData = await API.graphql(graphqlOperation(listAccountUsers, { userId: userId })).catch((error) => console.log("graphQL error: ", error));
  // console.log(apiData);
  return apiData;
}

//TODO: fix limit issues
export async function GetSensorsByAccountId(account, nextToken) {
  const apiData = await API.graphql(graphqlOperation(querySensorsByAccountIndex, { account: account, limit: 500, nextToken: nextToken })).catch((error) => console.log("graphQL error: ",error));
  return apiData;
}

export async function ListGatewaysByAccount(account, nextToken) {
  const apiData = await API.graphql(graphqlOperation(listGatewaysByAccount, { account: account, limit: 100, nextToken: nextToken })).catch((error) => console.log("graphQL error: ",error));
  return apiData;
}

export async function GetMostRecentSensorMessage(id, nextToken, limit) {
  const apiData = await API.graphql(graphqlOperation(listFirstSensorMessages, { senid: id, limit: limit, nextToken: nextToken })); /* change the limit back to one */
  return apiData;
}

export async function GetSensorMessagesByDay(id, startTime, endTime, nextToken, limit) {
  const apiData = await API.graphql(
    graphqlOperation(listSensorMessagesByDay, { senid: id, startTime: startTime, endTime: endTime, limit: limit, nextToken: nextToken })).catch((err) => console.log("graphQL error: ", err));
  return apiData;
}

export async function GetSensorMessagesByDayMinMax(id, startTime, endTime, nextToken, limit) {
  const apiData = await API.graphql(graphqlOperation(listSensorMessagesByDay, { senid: id, startTime: startTime, endTime: endTime, limit: limit, nextToken: nextToken })).catch((err) => console.log("graphQL error: ", err));
  return apiData;
}


export async function ListGatewayMessagesByDay(id, startTime, endTime, nextToken, limit) {
  const apiData = await API.graphql(
    graphqlOperation(listGatewayMessagesByDay, { gwid: id, startTime: startTime, endTime: endTime, limit: limit || 25 , nextToken: nextToken })).catch((err) => console.log("graphQL error: ", err));

  return apiData;
}

export async function ListRecentGatewayMessages(id, nextToken, limit) {
  const apiData = await API.graphql(graphqlOperation(listFirstGatewayMessages, { gwid: id, limit: limit || 25, nextToken: nextToken }));
  return apiData;
}

export async function QueryNotificationGroupsByAccountId(accountId) {
  const apiData = await API.graphql(graphqlOperation(queryNotificationGroupsByAccount, { account: accountId, limit: 200 })).catch((error) => console.log("graphQL error: ",error));
  return apiData;
}

export async function QueryNotificationHistoryByNotificationId(notificationId, nextToken) {
  const apiData = await API.graphql(graphqlOperation(queryNotificationsByNotificationGroup, { notificationGroupId: notificationId, limit: 50, nextToken: nextToken }));
  return apiData;
}

export async function ListNetworksByAccountId(account, nextToken) {
  const apiData = await API.graphql(graphqlOperation(listNetworksByAccount, { account: account, limit: 25, nextToken: nextToken })).catch((error) => console.log(error));
  return apiData;
}

export async function ListAccounts(limit, nextToken) {
  const apiData = await API.graphql(graphqlOperation(listAccounts, { nextToken: nextToken, limit:limit })).catch((error) => console.log(error));
  return apiData;
}

export async function GetAccount(id) {
  const response = await API.graphql(graphqlOperation(getAccounts, { id: id }));
  return response;
}

//TODO: CRITICAL. STUPID 1000 limit is just a workaround for stupid AccountUser table schema.
export async function ListAccountUsersByAccountId(accountId, nextToken) {
  const apiData = await API.graphql(graphqlOperation(listAccountUsers, { filter: {accountId: {eq: accountId}}, limit: 1000, nextToken: nextToken })).catch((error) => console.log('GraphQL error', error));
  return apiData;
}


/**
 * MUTATIONS
 */

export async function CreateNewAccounts(input) {
  const apiData = await API.graphql(graphqlOperation(createAccounts, { input: input }));
  return apiData;
}

export async function UpdateAccount(input) {
  const apiData = await API.graphql(graphqlOperation(updateAccounts, { input: input })).catch((error) => console.log(error));

  return apiData;
}

export async function CreateNewAccountUser(input) {
  const apiData = await API.graphql(graphqlOperation(createAccountUser, { input: input }));
  return apiData;
}

export async function UpdateAccountUser(userInput) {
  const apiData = await API.graphql(graphqlOperation(updateAccountUser, { input: userInput })).catch((error) => console.log(error));

  return apiData;
}

export async function CreateNewSensor(input) {
  const apiData = await API.graphql(graphqlOperation(createSensors, { input: input })).catch((error) => console.log(error));
  return apiData;
}


/* gateway CRUD methods */
export async function CreateNewGateway(input) {
  const apiData = await API.graphql(graphqlOperation(createGateways, { input: input })).catch((error) => console.log(error));

  return apiData;
}

export async function UpdateGateway(deviceInput) {
  const apiData = await API.graphql(graphqlOperation(updateGateways, { input: deviceInput })).catch((err) => console.log(err));

  return apiData;
}

export async function DeleteGateway(id) {
  var input = { id: id };
  const apiData = await API.graphql(graphqlOperation(deleteGateways, { input: input })).catch((err) => console.log(err));

  return apiData;
}

export async function DeleteSensor(id) {
  var input = { id: id };
  const apiData = await API.graphql(graphqlOperation(deleteSensors, { input: input }));

  return apiData;
}

export async function DeleteNetwork(networkId, account) {
  var input = { id: networkId, account: account };
  const apiData = await API.graphql(graphqlOperation(deleteNetwork, { input: input })).catch((err) => console.log("graphql error", err));
  return apiData;
}

export async function CreateNetwork(input) {
  const apiData = await API.graphql(graphqlOperation(createNetwork, { input: input })).catch((err) => console.log(err));

  return apiData;
}


export async function UpdateSensor(deviceInput) {
  const apiData = await API.graphql(graphqlOperation(updateSensors, { input: deviceInput })).catch((err) => console.log(err));

  return apiData;
}

export async function UpdateNetwork(input) {
  const apiData = await API.graphql(graphqlOperation(updateNetwork, { input: input }));

  return apiData;
}

export async function CreateNotificationGroup(input) {
  const apiData = await API.graphql(graphqlOperation(createNotificationGroups, { input: input })).catch((err) => console.log(err));
  return apiData;
}

export async function LogNotifications(input) {
  const apiData = await API.graphql(graphqlOperation(createNotifications, { input: input })).catch((err) => console.log(err));
  return apiData;
}

export async function DeleteNotification(input) {
  const apiData = await API.graphql(graphqlOperation(deleteNotificationGroups, { input: input })).catch((err) => console.log(err));

  return apiData;
}

export async function UpdateNotification(input) {
  input.id = input.notificationId;
  input.name = input.notificationName;
  const apiData = await API.graphql(graphqlOperation(updateNotificationGroups, { input: input })).catch((err) => console.log(err));
  return apiData;
}

export async function DeleteAccountUser(username) {
  const apiData = await API.graphql(graphqlOperation(deleteAccountUser, { input: username })).catch((err) => console.log(err));
  return apiData;
}
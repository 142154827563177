import React, { useState } from 'react';

// import NotificationTypes from './NotificationTypes.jsx'
import { CreateNotificationGroup } from '../Database.jsx'

/* bootstrap imports */
import { Modal, Form, Button } from 'react-bootstrap'

import { v4 as uuidv4 } from 'uuid'; //updated uuid method
import { Tooltip } from '@mui/material';

export default function AddNewModal(props) {
  
  const [show, setShow] = useState(false);

  /* handle network selection */
  const [type, setType] = useState("Temperature");
  const [name, setName] = useState("");
  const selectedNetwork = props.selectedNetwork;
  const networkName = props.selectedNetworkName;

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    // setType("Temperature");
    setName("");
  }

  // const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    // var notification_data = "";
    // const device_data = NotificationTypes[type];
    const notification_data = type === "gatewayInactivity" ? 
      {
        account: localStorage.getItem("currentAccount"),
        deviceType: "gateway",
        id: uuidv4().toString(), // this is the notification uuid
        message: "<Name> has stopped communicating!",
        name: name || "Gateway Inactivity notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "gatewayInactivity",
        sendType: "gatewayInactivity",
        notificationStatus: "disarmed"
      } : type === "BatteryBackup" ? 
      {
        account: localStorage.getItem("currentAccount"),
        deviceType: "BatteryBackup",
        id: uuidv4().toString(), // this is the notification uuid
        message: "<Name> power outage detected!",
        name: name || "Base station ower outage",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "BatteryBackup",
        sendType: "BatteryBackup",
        notificationStatus: "disarmed"
      } :
      type === "sensorInactivity" ?
      {
        account: localStorage.getItem("currentAccount"),
        deviceType: "sensor",
        id: uuidv4().toString(), // this is the notification uuid
        inactiveThresh: 15,
        message: "<Name> <ID> <Network> has not checked in for <Time> minutes",
        name: name || "Sensor Inactivity Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationStatus: "disarmed",
        notificationType: "sensorInactivity",
        sendType: "sensorInactivity"
      }
      :
      type === "timedVoltage" ?
      {
        account: localStorage.getItem("currentAccount"),
        deviceType: "Voltage_Meter_500",
        id: uuidv4().toString(), // this is the notification uuid
        message: "<Name> <ID> has run longer than <Time> minutes",
        name: name || "Timed voltage notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "timedVoltage",
        sendType: "timedVoltage",
        notificationStatus: "disarmed"
      }
      :
      type === "timedDryContact" ?
      {
        account: localStorage.getItem("currentAccount"),
        deviceType: "Dry_Contact",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 1,
        minThresh: 1,
        message: "<Name> <ID> has been <Reading> for <Time> minutes",
        name: name || "Time delay dry contact notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "timedDryContact",
        sendType: "timedDryContact",
        notificationStatus: "disarmed"
      } :
      type === "timedOpenClosed" ?
      {
        account: localStorage.getItem("currentAccount"),
        deviceType: "Open_Closed",
        id: uuidv4().toString(), // this is the notification uuid
        message: "<Name> <ID> has been <Reading> for <Time> minutes",
        name: name || "Time delay door notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "timedOpenClosed",
        sendType: "timedOpenClosed",
        notificationStatus: "disarmed"
      } : 
      type === "Temperature" ?
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "Temperature",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 125,
        minThresh: -40,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Temperature Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "Temperature",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "F", 
        scaleValue2: null,
        notificationStatus: "disarmed"
      } :
      type === "Low_Temperature" ?
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "Low_Temperature",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 100,
        minThresh: -200,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Ultra Low Temperature Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "Low_Temperature",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "C", 
        scaleValue2: null,
        notificationStatus: "disarmed"
      } :
      type === "Dry_Contact" ?
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "Dry_Contact",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 1,
        minThresh: 1,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Dry Contact Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "Dry_Contact",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "Closed", 
        scaleValue2: "Open",
        notificationStatus: "disarmed"
      }  :
      type === "Humidity" ?
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "Humidity",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 0,
        minThresh: 100,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Humidity Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "Humidity",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "%", 
        scaleValue2: "F",
        notificationStatus: "disarmed"
      } :
      type === "Open_Closed" ?
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "Open_Closed",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 1,
        minThresh: 1,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Door Open Closed Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "Open_Closed",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "Closed", 
        scaleValue2: "Open",
        notificationStatus: "disarmed"
      } :
      type === "Pulse_Counter_1_Input" ?
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "Pulse_Counter_1_Input",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 1000,
        minThresh: 0,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Pulse Counter Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "Pulse_Counter_1_Input",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        notificationStatus: "disarmed"
      }  :
      type === "Pressure300PSI" ? 
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "Pressure300PSI",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 0,
        minThresh: 300,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Pressure Sensor Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "Pressure300PSI",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "PSI", 
        scaleValue2: "PSI",
        notificationStatus: "disarmed"
      } :
      type === "Voltage_Meter_500" ?
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "Voltage_Meter_500",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 500,
        minThresh: 0,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Voltage Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "Voltage_Meter_500",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "V", 
        notificationStatus: "disarmed"
      } :
      type === "CurrentZeroToTwentyAmp" ?
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "CurrentZeroToTwentyAmp",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 25,
        minThresh: 0,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Current notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "CurrentZeroToTwentyAmp",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "amps", 
        notificationStatus: "disarmed"
      } :

      type === "SmartBarnDairySensor" ? 
      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        dataType: 0,
        deviceType: "SmartBarnDairySensor",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: 125,
        minThresh: -40,
        message: "<Name> <ID> is reading <Reading>",
        name: name || "Temperature Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "SmartBarnDairySensor",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "F", 
        scaleValue2: null,
        notificationStatus: "disarmed"
      } : 

      {
        account: localStorage.getItem("currentAccount"),
        alarmOnExitAlert: false,
        alertWhen: "not between",
        // assignedDevices: [],
        // assignedUsers: [],
        dataType: 0,
        deviceType: "ERROR",
        id: uuidv4().toString(), // this is the notification uuid
        maxThresh: "ERROR",
        minThresh: "ERROR",
        message: "<Name> <ID> is reading <Reading>",
        name: name || "ERROR Sensor Notification",
        networkId: selectedNetwork,
        networkName: networkName, // TODO: might need the actual name?
        notificationType: "measurement",
        paused: false,
        resendAttempts: 5,
        resetLimit: 10,
        sendType: "instant",
        scaleValue1: "ERROR", 
        scaleValue2: "ERROR",
        notificationStatus: "disarmed"
      }
    // console.log("NEW NOTIFICATION!!", notification_data)
    CreateNotificationGroup(notification_data)
    .then( 
      () => {
        alert("Notification created!");
        // window.location.reload();
        props.setAllNotifications(allNotifications => [...allNotifications, notification_data])
        handleClose();
      }
    );
  };

  const SensorTypes =
    <>
      <option key={"gatewayInactivity"} value="gatewayInactivity">Gateway inactivity</option>
      <option key={"BatteryBackup"} value="BatteryBackup">Battery Backup</option>
      <option key={"CurrentZeroToTwentyAmp"} value="CurrentZeroToTwentyAmp">0-20 Amp Current Meter</option>
      <option key={"sensorInactivity"} value="sensorInactivity">Sensor inactivity</option>
      <option key={"Temperature"} value="Temperature">Temperature</option>
      <option key={"Low_Temperature"} value="Low_Temperature">Ultra Low Temperature (-200C)</option>
      <option key={"Dry_Contact"} value="Dry_Contact">Dry Contact</option>
      <option key={"Humidity"} value="Humidity">Humidity</option>
      <option key={"Open_Closed"} value="Open_Closed">Door Open / Closed</option>
      <option key={"Pulse_Counter_1_Input"} value="Pulse_Counter_1_Input">Pulse counter / water leak</option>
      <option key={"Pressure300PSI"} value="Pressure300PSI">Pressure Sensor - 300 PSI</option>
      <option key={"Voltage_Meter_500"} value="Voltage_Meter_500">Voltage Meter 500</option>
      <option key={"timedVoltage"} value="timedVoltage">Timed - Voltage Meter run time</option>
      <option key={"timedDryContact"} value="timedDryContact">Timed - Dry Contact delayed alert</option>
      <option key={"timedOpenClosed"} value="timedOpenClosed">Timed - Door delayed alert</option>
      <option disabled key={"SmartBarnDairySensor"} value="SmartBarnDairySensor">Smart Barn Dairy Sensor</option>
    </>

  var networks = props.allNetworks.map(element => (
    <option key={element.id} value={element.id} >{element.name}</option>
    )
  );

  return (
    <>
      <Tooltip title="Select a network to add a notification" disableHoverListener={props.selectedNetwork !== "All Networks"} >
        <span>
          <Button variant="primary" onClick={handleShow} disabled={props.selectedNetwork === "All Networks"}>
            Add New
          </Button>
        </span>
      </Tooltip>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>Add Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id='addNotification' onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Network:</Form.Label>        
              <Form.Select disabled value={ selectedNetwork } >
                {networks}
              </Form.Select>
            <Form.Group>
              <Form.Label>Device / Notification Type:</Form.Label>        
                <Form.Select value={ type } onChange={(e) => setType(e.target.value)}>
                  {SensorTypes}
                </Form.Select>
            </Form.Group>
              </Form.Group>
            <Form.Group>
              <Form.Label>Notification Name:</Form.Label>
                <Form.Control type="text" value={name} onChange={(e) => (setName(e.target.value))} required />
              </Form.Group>
          </Form>
        </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form='addNotification'>Add Notification</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
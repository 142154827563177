 
import { useState, useEffect } from 'react';
import { listFirstSensorMessages } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useGetMostRecentSensorMessage(sensorId) {
  const [sensorMessage, setSensorMessage] = useState(null);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(listFirstSensorMessages, { senid: sensorId, limit: 1, nextToken: null })).catch((error) => console.log("graphQL error: ",error));
      if(result.data.listFirstSensorMessages.items.length === 0){
        setSensorMessage([]);
      }
      else {
        setSensorMessage(result.data.listFirstSensorMessages.items[0]);
      }
    }
    sensorId&&fetchData();
  },[sensorId]);
  return sensorMessage;
}
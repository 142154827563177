import React from "react";

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { IOTData } from "../services.js";
import { Chip } from "@mui/material";
const iotdata = IOTData();

export default function GatewaySensorListTab(props) {
  if(!props.tabKey === "sensorSettings") return <></>
  if(!props.sensorsInNetwork) return <></> // not sure why this is here?
  if(!props.deviceList) {
    const unknownDevices = props.allSensors.filter(({networkId}) => (props.networkId === networkId));
    const unknownDeviceList = unknownDevices.map(({id}) => <Grid item key={id}><Badge badgeContent={'x'} color="warning"><Button disableRipple variant="contained" size="small" color="error" style={{minWidth:"100px"}}>{id}</Button></Badge></Grid>)
    // if device list is null or empty or whatever, do this
    return(
      <>
        <div> 
        <Grid container spacing={1} padding={1} >
          <Grid item>
            <Button disableRipple variant="contained" color="primary" size="small" onClick={() => handleLoadSensors()}><CloudSyncIcon title={"Load sensors"} style={{paddingRight:"2px"}}/>  Load sensors</Button>
          </Grid>
        </Grid>
        Total sensors loaded: 0
        </div>
        <div style={{ width: '100%', minHeight:"200px" }}>
          <Grid container spacing={2} padding={1}>
            {unknownDeviceList}
            <Grid item key={"checkMarkKey"}><Button disableRipple variant="contained" size="small" style={{minWidth:"70px"}}>X - Sensor not loaded</Button></Grid>
          </Grid>
        </div>
      </>
    )
  }

  props.deviceList.sort();
  
  const deviceList = props.deviceList.map(sensorId => 
                                            <Grid 
                                              item
                                              alignContent="left"
                                              key={sensorId} >
                                              <Chip icon={props.sensorsInNetwork.find(({id}) => id === sensorId) ? <CheckCircleIcon align="left" color='success'/> : <HelpOutlineIcon />} label={sensorId} style={{minWidth:"100px"}}/>
                                            </Grid>)
  // list of devices not loaded in gateway memory

  const unknownDevices = props.allSensors.filter(({networkId, id}) => (props.networkId === networkId)&&!props.deviceList.includes(id));

  const handleLoadSensors = () => {
    // Send reform message to sensor topic
    const sensorList = props.allSensors.map((sensor) => { 
      // return sensor.id && (sensor.networkId === props.networkId)
        if(sensor.networkId === props.networkId) {return (sensor.id) }
    });
    const filteredList = sensorList.filter(sensor => sensor !== undefined)
    const params = {
      topic: "Reform",
      payload: JSON.stringify({ id: props.id, gwid: props.id, version: props.version, networkId: props.networkId, account: props.account, assignedDevices: filteredList }),
      qos: 0,
    };

    iotdata.publish(params, function (err) {
      if (err) console.log(err, err.stack);
      else {
        alert("Successfully updated sensor list.");
      }
    });
  }

  return(
    <>
      <div> 
        <Grid container spacing={2} padding={1} alignItems="center">
          <Grid item>
            <Button disableRipple variant="contained" color="primary" size="small" onClick={() => handleLoadSensors()}><CloudSyncIcon title={"Load sensors"} style={{paddingRight:"2px"}}/>  Load sensors</Button>
          </Grid>
        </Grid>
        Total sensors loaded: {props.deviceList.length}
      </div>
      <div style={{ width: '100%', minHeight:"200px" }}>
        <Grid container spacing={2} padding={1}>
          {deviceList}
          {unknownDevices.map(({id}) => <Grid item key={id}><Chip icon={<CancelIcon />} label={id} color="error" style={{minWidth:"100px"}}/></Grid>)}
        </Grid>
        <Grid container spacing={2} padding={1}>
          <Grid item key={"checkMarkKey"}><Chip icon={<CheckCircleIcon />} label="Sensor loaded"  color="info" style={{minWidth:"240px"}}/></Grid>
          <Grid item key={"xKey"}><Chip icon={<CancelIcon />} label="Sensor not loaded" color="info" style={{minWidth:"240px"}}/></Grid>
          <Grid item key={"qMarkKey"}><Chip icon={<HelpOutlineIcon />} label="Unknown sensor loaded" color="info"style={{minWidth:"240px"}}/></Grid>
        </Grid>
        
      </div>
    </>
  )
}

export default function FormatHumidityDataValues(dataValues, scaleValue1) {
  if(dataValues === null){
    return '--';
  }

  const temperatureData = dataValues.Temperature*1.0;
  const humidityData = dataValues.Humidity*1.0;
  if(scaleValue1.startsWith("F")){
    const formattedTemperatureData = ((temperatureData * 9.0/5.0) + 32.0).toFixed(1) + "F";
    const formattedHumidityData = (humidityData).toFixed(0) + "%";
    return formattedHumidityData + " " + formattedTemperatureData;
  }
  
  // else return as degrees C
  return humidityData.toFixed(0) + "% " + temperatureData.toFixed(1) + "C";
} 
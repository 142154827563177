 
import { useState, useEffect } from 'react';
import { listGatewaysByAccount } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useLoadGateways(accountId, nextToken) {
  const [allGateways, setAllGateways] = useState(null);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(listGatewaysByAccount, { account: accountId, limit: 100, nextToken: nextToken })).catch((error) => console.log("graphQL error: ",error));
      setAllGateways(result.data.listGatewaysByAccount.items);
    }
    accountId&&fetchData();
  },[accountId, nextToken]);
  return allGateways;
}
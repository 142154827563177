/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccounts = /* GraphQL */ `
  mutation CreateAccounts($input: CreateAccountsInput!) {
    createAccounts(input: $input) {
      id
      accountId
      parentAccountId
      name
      accountName
      address
      city
      state
      zipCode
      country
      timezone
      adminName
      pointOfContact
      pointOfContactPhone
      administrators
      notes
      dateCreated
    }
  }
`;
export const updateAccounts = /* GraphQL */ `
  mutation UpdateAccounts($input: UpdateAccountsInput!) {
    updateAccounts(input: $input) {
      accountId
      name
      accountName
      address
      city
      state
      zipCode
      country
      timezone
      adminName
      pointOfContact
      pointOfContactPhone
      administrators
      notes
    }
  }
`;
export const deleteAccounts = /* GraphQL */ `
  mutation DeleteAccounts($input: DeleteAccountsInput!) {
    deleteAccounts(input: $input) {
      id
      accountId
      parentAccountId
      name
      accountName
      address
      city
      state
      zipCode
      country
      timezone
      adminName
      pointOfContact
      pointOfContactPhone
      administrators
      notes
      dateCreated
    }
  }
`;
export const createNetwork = /* GraphQL */ `
  mutation CreateNetwork($input: CreateNetworkInput!) {
    createNetwork(input: $input) {
      account
      id
      name
    }
  }
`;
export const updateNetwork = /* GraphQL */ `
  mutation UpdateNetwork($input: UpdateNetworkInput!) {
    updateNetwork(input: $input) {
      account
      id
      name
    }
  }
`;
export const deleteNetwork = /* GraphQL */ `
  mutation DeleteNetwork($input: DeleteNetworkInput!) {
    deleteNetwork(input: $input) {
      account
      id
    }
  }
`;
export const createGateways = /* GraphQL */ `
  mutation CreateGateways($input: CreateGatewaysInput!) {
    createGateways(input: $input) {
      id
      account
      accountId
      deviceType
      deviceId
      heartbeat
      manufacturer
      modemSerialNum
      name
      networkId
      networkName
      notificationStatus
      pendingChange
      resetInterval
      sensorCode
      type
      version
    }
  }
`;
export const updateGateways = /* GraphQL */ `
  mutation UpdateGateways($input: UpdateGatewaysInput!) {
    updateGateways(input: $input) {
      id
      powerStatusPinEnabled
      modemSerialNum
    }
  }
`;
export const deleteGateways = /* GraphQL */ `
  mutation DeleteGateways($input: DeleteGatewaysInput!) {
    deleteGateways(input: $input) {
      id
    }
  }
`;
export const createGatewayMessages = /* GraphQL */ `
  mutation CreateGatewayMessages($input: CreateGatewayMessagesInput!) {
    createGatewayMessages(input: $input) {
      gwid
      timestamp
      battery
      date
      messageCount
      messageType
      power
    }
  }
`;
export const updateGatewayMessages = /* GraphQL */ `
  mutation UpdateGatewayMessages($input: UpdateGatewayMessagesInput!) {
    updateGatewayMessages(input: $input) {
      gwid
      timestamp
      battery
      date
      messageCount
      messageType
      power
    }
  }
`;
export const deleteGatewayMessages = /* GraphQL */ `
  mutation DeleteGatewayMessages($input: DeleteGatewayMessagesInput!) {
    deleteGatewayMessages(input: $input) {
      gwid
      timestamp
      battery
      date
      messageCount
      messageType
      power
    }
  }
`;
export const createSensors = /* GraphQL */ `
  mutation CreateSensors($input: CreateSensorsInput!) {
    createSensors(input: $input) {
      id
      account
      accountId
      codeMatch
      deviceId
      deviceName
      deviceType
      networkId
      networkName
      sensorCode
      sensorType
      name
      version
      heartbeat
      linkModeTrans
      heartbeatAware
      heartbeatAssessments
      maxThresh
      minThresh
      sync
      hysteresis
      powerOptions
      rearmTime
      showDewPoint
      awareBuffer
      awareStateMode
      scaleValue1
      scaleValue2
      scaleLabel
      pulseEdgeDetection
      stabilizationDelay
      accumulate
      averagingInterval
      batteryThresh
      awareStateMeasurement
      awareStateBattery
      awareStateInactive
      notificationRestartTime
      filter
      pendingChange
      sensitivity
      notificationId
      notificationStatus
      manufacturer
    }
  }
`;
export const updateSensors = /* GraphQL */ `
  mutation UpdateSensors($input: UpdateSensorsInput!) {
    updateSensors(input: $input) {
      id
      account
      accountId
      codeMatch
      deviceId
      deviceName
      deviceType
      networkId
      sensorCode
      sensorType
      name
      version
      heartbeat
      linkModeTrans
      heartbeatAware
      heartbeatAssessments
      maxThresh
      minThresh
      sync
      hysteresis
      powerOptions
      rearmTime
      showDewPoint
      awareBuffer
      awareStateMode
      scaleValue1
      scaleValue2
      scaleLabel
      pulseEdgeDetection
      stabilizationDelay
      accumulate
      averagingInterval
      batteryThresh
      notifyStatus
      awareStateMeasurement
      awareStateBattery
      awareStateInactive
      notificationRestartTime
      filter
      pendingChange
      sensitivity
      notificationId
      notificationStatus
      manufacturer
    }
  }
`;
export const deleteSensors = /* GraphQL */ `
  mutation DeleteSensors($input: DeleteSensorsInput!) {
    deleteSensors(input: $input) {
      id
    }
  }
`;
export const createSensorMessages = /* GraphQL */ `
  mutation CreateSensorMessages($input: CreateSensorMessagesInput!) {
    createSensorMessages(input: $input) {
      senid
      timestamp
      SignalPercent
      battery
      dataType
      dataValue
      date
      gwid
      sensorType
      signal
      data
    }
  }
`;
export const updateSensorMessages = /* GraphQL */ `
  mutation UpdateSensorMessages($input: UpdateSensorMessagesInput!) {
    updateSensorMessages(input: $input) {
      senid
      timestamp
      SignalPercent
      battery
      dataType
      dataValue
      date
      gwid
      sensorType
      signal
      data
    }
  }
`;
export const deleteSensorMessages = /* GraphQL */ `
  mutation DeleteSensorMessages($input: DeleteSensorMessagesInput!) {
    deleteSensorMessages(input: $input) {
      senid
      timestamp
      SignalPercent
      battery
      dataType
      dataValue
      date
      gwid
      sensorType
      signal
      data
    }
  }
`;
export const createDeviceList = /* GraphQL */ `
  mutation CreateDeviceList($input: CreateDeviceListInput!) {
    createDeviceList(input: $input) {
      deviceId
      deviceType
      account
      name
      networkId
      version
      heartbeat
      linkModeTrans
      awareStateHeartbeat
      sync
      heartbeatAssessments
      maxThresh
      minThresh
      powerOptions
      showDewPoint
      rearmTime
      awareBuffer
      awareStateMode
      hysteresis
      scaleValue1
      scaleValue2
      scaleLabel
      pulseEdgeDetection
      stabilizationDelay
      accumulate
      averagingInterval
      batteryThresh
      notifyStatus
      inactiveThresh
      notifyMeasurement
      notifyInactive
      notifyBattery
      ackMeasurement
      ackBattery
      ackInactive
      awareStateMeasurement
      awareStateBattery
      awareStateInactive
      notificationRestartTime
      lastMeasurementNotify
      lastBatteryNotify
      lastInactiveNotify
      filter
      resetInterval
      pendingChange
      sensitivity
    }
  }
`;
export const updateDeviceList = /* GraphQL */ `
  mutation UpdateDeviceList($input: UpdateDeviceListInput!) {
    updateDeviceList(input: $input) {
      deviceId
      deviceType
      account
      name
      networkId
      version
      heartbeat
      linkModeTrans
      awareStateHeartbeat
      sync
      heartbeatAssessments
      maxThresh
      minThresh
      powerOptions
      showDewPoint
      rearmTime
      awareBuffer
      awareStateMode
      hysteresis
      scaleValue1
      scaleValue2
      scaleLabel
      pulseEdgeDetection
      stabilizationDelay
      accumulate
      averagingInterval
      batteryThresh
      notifyStatus
      inactiveThresh
      notifyMeasurement
      notifyInactive
      notifyBattery
      ackMeasurement
      ackBattery
      ackInactive
      awareStateMeasurement
      awareStateBattery
      awareStateInactive
      notificationRestartTime
      lastMeasurementNotify
      lastBatteryNotify
      lastInactiveNotify
      filter
      resetInterval
      pendingChange
      sensitivity
    }
  }
`;
export const deleteDeviceList = /* GraphQL */ `
  mutation DeleteDeviceList($input: DeleteDeviceListInput!) {
    deleteDeviceList(input: $input) {
      deviceId
      deviceType
      account
      name
      networkId
      version
      heartbeat
      linkModeTrans
      awareStateHeartbeat
      sync
      heartbeatAssessments
      maxThresh
      minThresh
      powerOptions
      showDewPoint
      rearmTime
      awareBuffer
      awareStateMode
      hysteresis
      scaleValue1
      scaleValue2
      scaleLabel
      pulseEdgeDetection
      stabilizationDelay
      accumulate
      averagingInterval
      batteryThresh
      notifyStatus
      inactiveThresh
      notifyMeasurement
      notifyInactive
      notifyBattery
      ackMeasurement
      ackBattery
      ackInactive
      awareStateMeasurement
      awareStateBattery
      awareStateInactive
      notificationRestartTime
      lastMeasurementNotify
      lastBatteryNotify
      lastInactiveNotify
      filter
      resetInterval
      pendingChange
      sensitivity
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications($input: CreateNotificationsInput!) {
    createNotifications(input: $input) {
      notificationId
      timestamp
      contactAddress
      contactMethod
      deviceId
      message
      notificationStatus
      userFirstLastName
      result
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications($input: UpdateNotificationsInput!) {
    updateNotifications(input: $input) {
      id
      timestamp
      ack
      date
      sendTypes
      receiverName
      deviceMessage
      deviceType
      sentMessage
      notificationType
      deviceId
      notificationGroupId
      sendType
      notificationActive
      userFirstLastName
      result
      message
      contactAddress
      contactMethod
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications($input: DeleteNotificationsInput!) {
    deleteNotifications(input: $input) {
      id
      timestamp
      ack
      date
      sendTypes
      receiverName
      deviceMessage
      deviceType
      sentMessage
      notificationType
      deviceId
      notificationGroupId
      sendType
      notificationActive
      userFirstLastName
      result
      message
      contactAddress
      contactMethod
    }
  }
`;
export const createNotificationGroups = /* GraphQL */ `
  mutation CreateNotificationGroups($input: CreateNotificationGroupsInput!) {
    createNotificationGroups(input: $input) {
      id
      account
      alarmOnExitAlert
      alertWhen
      dataType
      deviceType
      inactiveThresh
      maxThresh
      minThresh
      message
      name
      networkId
      networkName
      notificationType
      paused
      resendAttempts
      resetLimit
      sendType
      scaleValue1
      scaleValue2
      notificationStatus  
    }
  }
`;
export const updateNotificationGroups = /* GraphQL */ `
  mutation UpdateNotificationGroups($input: UpdateNotificationGroupsInput!) {
    updateNotificationGroups(input: $input) {
      account
      accountId
      assignedDevices
      assignedUsers {
        name
        username
        contactMethod
        contactValue
        notificationDelayMinutes
        notificationDelaySeconds
      }
      deviceType
      freezeAlarmStatus
      freezeAlarmThresh
      id
      inactiveThresh
      maxThresh
      maxThresh2
      minThresh
      minThresh2
      message
      name
      networkId
      networkName
      notificationId
      notificationMessage
      notificationName
      notificationStatus
      notificationType
      returnToNormalMessage
      scaleValue1
      scaleValue2
      sendReturnToNormalMessage
    }
  }
`;

export const deleteNotificationGroups = /* GraphQL */ `
  mutation DeleteNotificationGroups($input: DeleteNotificationGroupsInput!) {
    deleteNotificationGroups(input: $input) {
      id
    }
  }
`;
export const createDeviceShadows = /* GraphQL */ `
  mutation CreateDeviceShadows($input: CreateDeviceShadowsInput!) {
    createDeviceShadows(input: $input) {
      deviceId
      Payload
    }
  }
`;
export const updateDeviceShadows = /* GraphQL */ `
  mutation UpdateDeviceShadows($input: UpdateDeviceShadowsInput!) {
    updateDeviceShadows(input: $input) {
      deviceId
      Payload
    }
  }
`;
export const deleteDeviceShadows = /* GraphQL */ `
  mutation DeleteDeviceShadows($input: DeleteDeviceShadowsInput!) {
    deleteDeviceShadows(input: $input) {
      deviceId
      Payload
    }
  }
`;
export const createNotificationUsers = /* GraphQL */ `
  mutation CreateNotificationUsers($input: CreateNotificationUsersInput!) {
    createNotificationUsers(input: $input) {
      notificationId
      username
      notificationDetails
    }
  }
`;
export const updateNotificationUsers = /* GraphQL */ `
  mutation UpdateNotificationUsers($input: UpdateNotificationUsersInput!) {
    updateNotificationUsers(input: $input) {
      notificationId
      username
      notificationDetails
    }
  }
`;
export const deleteNotificationUsers = /* GraphQL */ `
  mutation DeleteNotificationUsers($input: DeleteNotificationUsersInput!) {
    deleteNotificationUsers(input: $input) {
      notificationId
      username
      notificationDetails
    }
  }
`;
export const createAccountUser = /* GraphQL */ `
  mutation CreateAccountUser(
    $input: CreateAccountUserInput!
    $condition: ModelAccountUserConditionInput
  ) {
    createAccountUser(input: $input, condition: $condition) {
      userId
      accountId
      username
      userUUID
      email
      landLine
      cellPhone
      firstName
      lastName
      accounts
      permissions
      emailVerified
      phoneVerified
      cellVerified
      timezone
      parentAccountId
      accountName
      notes
      dateCreated
    }
  }
`;
export const updateAccountUser = /* GraphQL */ `
  mutation UpdateAccountUser(
    $input: UpdateAccountUserInput!
    $condition: ModelAccountUserConditionInput
  ) {
    updateAccountUser(input: $input, condition: $condition) {
      userId
      accountId
      username
      email
      landLine
      cellPhone
      cellPhoneProvider
      cellPhoneProviderData
      firstName
      lastName
      accounts
      permissions
      emailVerified
      emailToTextAddress
      useEmailToText
      phoneVerified
      cellVerified
      timezone
      parentAccountId
      accountName
      notes
      dateCreated
    }
  }
`;
export const deleteAccountUser = /* GraphQL */ `
  mutation DeleteAccountUser(
    $input: DeleteAccountUserInput!
    $condition: ModelAccountUserConditionInput
  ) {
    deleteAccountUser(input: $input, condition: $condition) {
      userId
      accountId
      username
      email
      landLine
      cellPhone
      firstName
      lastName
      accounts
      permissions
      emailVerified
      phoneVerified
      cellVerified
      timezone
      parentAccountId
      accountName
      notes
      dateCreated
      createdAt
      updatedAt
    }
  }
`;

import React, {useState} from 'react';
import { Modal, Form } from 'react-bootstrap'
import Button from '@mui/material/Button';
import { createUserNotes } from '../../../graphql/customMutations';
import { API, graphqlOperation } from "aws-amplify";


/**
 * I would like this to be generalized and use children props but having trouble getting that to work.
 * @param {*} props: senid, userId 
 * @returns nothing
 */
const titleText = "Add note";
export default function CreateNewUserNoteModal(props) {
  const [show, setShow] = useState(false);
  const [userNotes, setUserNotes] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const userId=localStorage.getItem('userId'); // TODO: make this a context for the whole app
  const userName=localStorage.getItem('userFirstName') + " " + localStorage.getItem('userLastName');

  const handleClose = () => {
    setShow(false);
    setUserNotes("");
  }

  const handleShow = () => setShow(true);
  
  const handleSave = () => {
    const userNotesData = {
      "senid": props.senid,
      "userId": userId,
      "userFullName": userName,
      "userNotes": userNotes,
      // "dateCreated": Date.now() // unix timestamp for the date. serves as the record primary key. I don't need this since created in VTL?
    }
    console.log(userNotesData);
    CreateNewNotes(userNotesData)
    .then(
      (result) => {
        console.log("createUserNotesResult", result);
        setSuccess(result);
        alert("Note saved!");
        props.handleAddRow(userNotesData);
      },
      (error) => {
        setError(error);
        alert("Error saving");
        // window.location.reload();
      });
    handleClose();
  }
  
  // handle the text input and save it to the userNotes value
  const handleTextInput = (e) => {
    setUserNotes(e.target.value);
  }
  
  return (
    <>
      <Button variant="outlined" onClick={handleShow}>
        {titleText}
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={props.id} onSubmit={handleSave}>
            <Form.Group className="mb-3" controlId="createUserNoteForm">
              <Form.Label>Notes</Form.Label>
                <Form.Control type="text" value={userNotes} onChange={handleTextInput} required />
            </Form.Group>
          </Form>
        </Modal.Body> 
        <Modal.Footer>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          <Button variant="contained" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

async function CreateNewNotes(input) {
  const apiData = await API.graphql(graphqlOperation(createUserNotes, { input: input }));
  return apiData;
}
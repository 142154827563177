import React from 'react';

import Form from 'react-bootstrap/Form';
import NotificationToolTip from './NotificationToolTip';


export default function EditReturnToNormal(props) {
  
  return(
    <>
    <Form.Check 
      type="checkbox"
      id="checkbox-alertOnExit"
      label="Send message when notification resets to normal?"
      onChange={ (e) => props.setSendReturnToNormalMessage(e.target.checked)}
      checked={ props.sendReturnToNormalMessage }
    />
    <Form.Label>Return to normal message &nbsp;</Form.Label>
    <NotificationToolTip />
    <Form.Control disabled={ !props.sendReturnToNormalMessage }type="text" defaultValue={ props.returnToNormalMessage } onChange={ (e) => props.setReturnToNormalMessage(e.target.value) } />
    </>
  );
}
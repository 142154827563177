import React, { useState, useEffect, useRef } from 'react';
import { IOTData } from "../services.js";
import Button from "react-bootstrap/Button";
import EditTemperatureSettingsTab from './EditTemperatureSettingsTab.jsx';

const iotdata = IOTData();

export default function SensorEditConfiguration (props) {
  const [sensorName, setSensorName] = useState(props.sensorName);
  const [dataValueOffset, setDataValueOffset] = useState(props.dataValueOffset || 0.0);
  const [deviceHeartbeat, setDeviceHeartbeat] = useState(props.heartbeat || 10);
  const [scaleLabel, setScaleLabel] = useState(props.scaleLabel || "");
  const [scaleValue1, setScaleValue1] = useState(props.scaleValue1);
  const [scaleValue2, setScaleValue2] = useState(props.scaleValue2);
  const [pendingChange, setPendingChange] = useState(props.pendingChange);
  const didMount = useRef(false);
  useEffect(() => {
    // Return early, if this is the first render:
    if ( !didMount.current ) {
      didMount.current = true;
    } 
    else {
      setPendingChange(true);
    }
  }, [deviceHeartbeat]);

  const handleSaveSettings = (event) => {
    event.preventDefault();
    props.setPendingChange(true);
    props.setSensorName(sensorName);
    const json_string = {
      account: props.account,
      accountId: props.account,
      dataValueOffset: dataValueOffset,
      deviceId: props.id,
      deviceType: props.deviceType,
      heartbeat: deviceHeartbeat,
      heartbeatAssessments: deviceHeartbeat,
      heartbeatAware: deviceHeartbeat,
      id: props.id,
      manufacturer: props.manufacturer || "monnit",
      maxThresh: props.maxThresh,
      minThresh: props.minThresh,
      message: props.message,
      name: sensorName,
      networkId: props.networkId,
      notificationId: props.notificationId,
      notificationStatus:props.notificationStatus || "disarmed",
      pendingChange: pendingChange,
      scaleLabel: scaleLabel,
      scaleValue1: scaleValue1,
      scaleValue2: scaleValue2,
      sensorCode: props.sensorCode,
      version: props.version,
      cmdStatus: "REQUEST",
      cmdTimeStamp: new Date()
    }
    const params = {
      topic: "cmd/sensor/" + props.deviceType + "/monnit/settings"  ,
      payload: JSON.stringify(json_string),
      qos: 0
    };
    iotdata.publish(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        alert("Problem saving settings!");
      }
      else {
        console.log("response", data);
        alert("Settings Saved!");
        // window.location.reload();
      }
    });
  }


  let html = "";
  switch (props.version.toLowerCase()) {
    case "dc_voltage_5v":
      // TODO: need to handle both scale value changes... not sure how to handle but it will be fine.
      html = 
        <>
          <label className="input-label">Label:</label>
          <input className="form-control" name= "scaleLabel" type="text" value={ scaleLabel } onChange={ (e) => setScaleLabel(e.target.value) }></input>
          <label className="input-label">Slope:</label>
          <input className="form-control" name="scaleValue1" type="text" value={ scaleValue1 } onChange={ (e) => setScaleValue1(e.target.value) }></input>
          <label className="input-label">Intercept:</label>
          <input className="form-control" name="scaleValue2" type="text" value={ scaleValue2 } onChange={ (e) => setScaleValue2(e.target.value) }></input>
        </>
        break;
    case "temperature":
      html = <EditTemperatureSettingsTab 
          scaleValue1 = {scaleValue1} 
          setScaleValue1={setScaleValue1} 
          dataValueOffset={dataValueOffset} 
          setDataValueOffset={setDataValueOffset} 
        />
      break;
    case "humidity":
        html = 
          <>
          <label className="input-label">Scale Value</label>
          <select value={scaleValue1} className="form-control" onChange={(e) => setScaleValue1(e.target.value)} name="scaleValue1">
            <option value="F">F</option>
            <option value="C">C</option>
          </select>
          </>
        break;
    case "dry_contact":
      // TODO: need to handle both scale value changes... not sure how to handle but it will be fine.
      html = 
        <>
        <label className="input-label">Label when closed:</label>
        <input className="form-control" name= "scaleValue1" type="text" value={scaleValue1} onChange={(e) => setScaleValue1(e.target.value)}></input>
        <label className="input-label">Label when open:</label>
        <input className="form-control" name="scaleValue2" type="text" value={scaleValue2} onChange={(e) => setScaleValue2(e.target.value)}></input>
        </>
        break;
      case "pulse_counter_1_input":
      case "filtered_pulse_counter_1_input":
      case "filteredpulsecounter64":
        html = 
        <>
        <label className="input-label">Units:</label>
        <input className="form-control" name= "scaleValue1" type="text" value={scaleValue1} onChange={(e) => setScaleValue1(e.target.value)}></input>
        <label className="input-label">Pulses/unit:</label>
        <input className="form-control" name="scaleValue2" type="number" min="1" value={scaleValue2} onChange={(e) => setScaleValue2(e.target.value)}></input>
        </>
        break;
    default:
      html = "";
  }

  return (
    <form>
      <div className="form-group">
        <label className="input-label">Name</label>
        <input name= "deviceName" type="text" className="form-control" value={ sensorName } onChange={(e) => setSensorName(e.target.value)}></input>
        <label className="input-label">Heartbeat</label>
        <input name= "deviceHeartbeat" type="number" className="form-control" min="1" value={deviceHeartbeat} onChange={(e) => setDeviceHeartbeat(e.target.value)} ></input>
        {html}
        <br />
        <Button type="submit" value="Save Settings" onClick={(e) => handleSaveSettings(e)}>Save</Button>
      </div>
    </form>
  );
}
import CalculateBatteryPercent from "./CalculateBatteryPercent";
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';

export default function GetSensorBatteryIcon(batteryLevel) {
  const percentValue = CalculateBatteryPercent(batteryLevel);
  
  if (percentValue >= 70) {
    return <BatteryFullIcon sx={{ fontSize: 18 }} title={percentValue + "%"} htmlColor="green" />;
  } else if (percentValue >= 50) {
    return <Battery5BarIcon sx={{ fontSize: 18 }} title={percentValue + "%"} htmlColor="#72973E"/>;
  } else if (percentValue >= 25) {
    return <Battery4BarIcon sx={{ fontSize: 18 }} title={percentValue + "%"} htmlColor="orange" />;
  } else if (percentValue >= 15) {
    return <Battery2BarIcon sx={{ fontSize: 18 }} title={percentValue + "%"} htmlColor="orange" />;
  } else if(percentValue >= 5) {
    return <Battery1BarIcon sx={{ fontSize: 18 }} title={percentValue + "%"} htmlColor="orange" />;
  } else {
    return <Battery0BarIcon sx={{ fontSize: 18 }} title={percentValue + "%"} htmlColor="red" />;
  }
}

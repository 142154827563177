
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import NetworkWifi3BarIcon from '@mui/icons-material/NetworkWifi3Bar';
import NetworkWifi2BarIcon from '@mui/icons-material/NetworkWifi2Bar';
import NetworkWifi1BarIcon from '@mui/icons-material/NetworkWifi1Bar';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';

export default function GetSensorSignal(props) {
  if (!props.signalPercent) {
    return <SignalWifi0BarIcon sx={{ fontSize: 18 }} title="?" htmlColor="gray" />;
  } else {
    if (props.signalPercent > 80) {
      return <SignalWifi4BarIcon sx={{ fontSize: 18 }}  htmlColor={ props.afterExpectedCheckinTime ? "gray" : "green" } title={props.signalPercent} alt={ '4 bars' } />;
    } else if (props.signalPercent > 60) {
      return <NetworkWifiIcon sx={{ fontSize: 18 }} htmlColor={ props.afterExpectedCheckinTime ? "gray" : "green" } title={props.signalPercent} alt={'4 bars'} />;
    } else if (props.signalPercent > 40) {
      return <NetworkWifi3BarIcon sx={{ fontSize: 18 }} htmlColor={ props.afterExpectedCheckinTime ? "gray" : "green" } title={props.signalPercent} alt={'3 bars'}/>;
    } else if (props.signalPercent > 20) {
      return <NetworkWifi2BarIcon sx={{ fontSize: 18 }} htmlColor={ props.afterExpectedCheckinTime ? "gray" : "yellow" } title={props.signalPercent} alt={'2 bars'}/>;
    } else if (props.signalPercent > 0) {
      return <NetworkWifi1BarIcon sx={{ fontSize: 18 }} htmlColor={ props.afterExpectedCheckinTime ? "gray" : "red" } title={props.signalPercent} alt={'1 bar'}/>;
    } else {
      return <SignalWifi0BarIcon sx={{ fontSize: 18 }} htmlColor={ props.afterExpectedCheckinTime ? "gray" : "red" } title={"0"} alt={'no signal'}/>;
    }
  }
}

import React from "react";
import AccountUser from "./AccountUser";
import "./Account.css";
import AccountInfo from "./AccountInfo";
import CreateNewUser from "./CreateNewUser";

import Container from "react-bootstrap/Container";

import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';

import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

export default function Account(props) {
  if(!props.allUsers) { return <></>}
  return (
    <Container fluid>
        {props.userId.includes("@smartbarn.io") ? <CreateNewUser accountID={props.accountId} accountName={localStorage.getItem("currentCompanyName")} buttonText="New User" id="registerNewUser" /> : <></>}
    {/*TODO:BILLING*/}
      <Tabs>
        <Tab eventKey="accountUsers" title={<span>Users<GroupIcon /></span>}>
          <Container>
            <Row>
              {props.allUsers.map(function (user) { return <AccountUser {...user} key={user.userId} /> })}
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="accountInfo" title={<span>Account Info <BusinessIcon /></span>}>
          <AccountInfo {...props.accountInfo} />
        </Tab>
      </Tabs>
    </Container>
  );
}


import React, { useState } from 'react';
import { GetSensor, GetAccount } from './AdminPageQueries'; 
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';

export default function SensorLookup(props) {
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
 
  /* handle sensor ID */
  const [deviceId, setDeviceId] = useState("");
  const deviceIdChange = (event) => setDeviceId(event.target.value);
  
  const [accountId, setAccountId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [networkName, setNetworkName] = useState("");

  const handleDeviceLookup = (e) => {
    e.preventDefault();
    GetSensor(deviceId)
    .then(
      (result) => {
        if(result.data.getSensors){
          setAccountId(result.data.getSensors.accountId);
          setAccountName(result.data.getSensors.accountName);
          setDeviceName(result.data.getSensors.name);
          setNetworkName(result.data.getSensors.networkName);
          handleAccountLookup(result.data.getSensors.accountId);
        }
        
      },
      (error) => {
        setError(error);
      }
    )
  };

  const handleAccountLookup = (e) => {
    GetAccount(e)
    .then(
      (result) => {
        setAccountName(result.data.getAccounts.accountName);
      },
      (error) => {
        setError(error);
      }
    )
  };


  return(
    <>
      <Button variant="primary" onClick={handleShow}>
        Sensor Lookup
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>Find a sensor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={props.id} onSubmit={ (e) => handleDeviceLookup(e)}>
            <Form.Group>
              <Form.Label>Device ID:</Form.Label>
                <Form.Control type="text" value={deviceId} onChange={deviceIdChange} required />
            </Form.Group>
          </Form>
        </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          
          <Button type="submit" onClick={ handleDeviceLookup }>Search...</Button>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Container>
            <Col>
              <Col>
                <Row>Name: { deviceName }</Row>
                <Row>Account: { accountName } </Row>
                <Row>Network: { networkName } </Row>
              </Col>
            </Col>
          </Container>
        </Modal.Footer>
      </Modal>    </>
  );
}
import React, {useState} from 'react';
import { Modal, Button, Form, } from 'react-bootstrap';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { CreateNetwork } from '../Database.jsx';
/**
 * I would like this to be generalized and use children props but having trouble getting that to work.
 * @param {*} props 
 * @returns 
 */

export default function ManageAddNetworkModal(props) {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);

  const[networkName, setNetworkName] = useState("");
  const handleNetwork = (e) => setNetworkName(e.target.value);
  const handleClose = () => setShow(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    // console.log("CreateNetwork");
    //TODO: change this to a regular UUID (will require change to dbase schema and Java Mine server)
    
    // console.log(props.accountId + " " + networkName + " " + new Date().valueOf().toString());
    // TODO: why does it try to create two networks at a time within a few ms? /1000 is the workaround b/c it causes a duplicate key.
    const networkId = Math.floor((new Date().valueOf()/1000)).toString();
    CreateNetwork({account: props.accountId, id: networkId, name: networkName})
    .then(
      (result) => {
        console.log("CreateNetwork", result);
        setSuccess(result);
        alert("Network created successfully!");
        window.location.reload();
      },
      (error) => {
        setError(error);
        alert("Error creating network: ", error);
        // window.location.reload();
      }
    );
  };

    // handleClose();
    // lambda action here
    // ideally receive a response from the lambda and then show the following alert:
    // then page refreshes. New account should be visible and accessible.

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
      <AddCircleIcon htmlColor="white" style={{ marginRight: "5px" }} />
        {props.buttonText}
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{props.buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form id={props.id} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formParentAccount">
            <Form.Label>Parent Account:</Form.Label>
            <Form.Select aria-label="select parent account" disabled >
              <option value="c4bd7b20-3bab-11ea-a975-0523f21e6d1f">Smart Barn</option>
              <option value="a9af7c70-0e1e-11eb-8cb6-eb022084b0e8">Organic Valley</option>
            </Form.Select>      
          </Form.Group>
          <Form.Group className="mb-3" controlId="formCompany" >
            <Form.Label>Account / Company Name:</Form.Label>
            <Form.Control type="text" placeholder={localStorage.getItem("currentCompanyName")} disabled />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formNetwork">
            <Form.Label>Network Name:</Form.Label>
            <Form.Control type="text" required onChange={(e) => handleNetwork(e)}>
            </Form.Control>      
            {props.devices}
          </Form.Group>
        </Form>
        </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form={props.id} onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


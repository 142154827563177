

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import WarningIcon from '@mui/icons-material/Warning';

export default function StatusIcons(props) {
  return (
    <>
        <Row><Container fluid>
        {(props.notificationStatus === "acknowledged" ) ? <WarningIcon sx={{ fontSize: 18 }} htmlColor="red" /> : 
                    (props.notificationStatus === "alerting" || props.notificationStatus === "timerAlarm") ? <WarningIcon sx={{ fontSize: 18 }} title={"alerting!"} htmlColor="red" /> : 
                    (props.notificationStatus === "disarmed") ? <DangerousIcon sx={{ fontSize: 18 }} title={"disarmed"} htmlColor="gray" /> : 
                    (props.notificationStatus === "armed" || props.notificationStatus === "timerActive") ? <AlarmOnIcon sx={{ fontSize: 18 }} title={"armed"} htmlColor="green" /> : 
                    (props.notificationStatus === "paused") ? <PauseCircleIcon sx={{ fontSize: 18 }} title={"paused"} htmlColor="blue" /> : 
                    (props.notificationStatus === "timerRunning") ? <HourglassTopTwoToneIcon sx={{ fontSize: 18 }} title={"timerRunning"} htmlColor="blue" /> : 
                    (props.notificationStatus === "sleeping") ? <NotificationsPausedIcon  sx={{ fontSize: 18 }} title={"sleeping"} htmlColor="blue" /> : <DangerousIcon sx={{ fontSize: 18 }} title={"disarmed"} htmlColor="gray" />
                    }
      </Container></Row>
  </>
  );
}
import React from 'react';
import endOfDay from 'date-fns/endOfDay';
import './Charts.css';
// date-fns
import 'chartjs-adapter-date-fns';

// Charts JS
import Container from 'react-bootstrap/Container';
import { Line } from 'react-chartjs-2';
import findHiLowTemperatureByDay from "../../../Utilities/findHiLowTemperatureByDay";
import convertCToF from '../../../Utilities/convertCToF';

export default function TemperatureChart(props) {
  const sensorDataMessages = [...props.messages];
  // find the absolute max / min in the displayed dataset for x and y
  const maxTemperature = Math.max(...sensorDataMessages.map(sensorDataMessage => sensorDataMessage.data));
  // format to F if needed
  const formattedMaxTemperature =  props.scaleValue1.startsWith('C') ? maxTemperature : convertCToF(maxTemperature);
  // format to F if needed  
  const minTemperature = Math.min(...sensorDataMessages.map(sensorDataMessage => sensorDataMessage.data));
  const formattedMinTemperature =  props.scaleValue1.startsWith('C') ? minTemperature : convertCToF(minTemperature);


  // set the min max of the chart using the calculated min / max values by day
  const sensorNotificationMaxThresh = props.scaleValue1.startsWith('C') ? props.maxThresh : convertCToF(props.maxThresh);
  const sensorNotificationMinThresh = props.scaleValue1.startsWith('C') ? props.minThresh : convertCToF(props.minThresh);

  const formattedHiLowByDay = findHiLowTemperatureByDay(sensorDataMessages, props.startDate, props.endDate, props.scaleValue1);
  const xyDataForChart = sensorDataMessages.map(x => {
    if (x.data !== null && x.data !== undefined) {
      return {
        x: new Date(x.timestamp * 1000),
        y: props.scaleValue1.startsWith('C') ? x.data.toFixed(1) : convertCToF(x.data.toFixed(1))
      };
    } else {
      // Handle null or undefined x.data
      return {
        x: new Date(x.timestamp * 1000),
        y: null // Default value 
      };
    }
  });
  // data formatting per charts.js API for a line chart https://www.chartjs.org/docs/latest/samples/line/styling.html
  const data = {
    datasets: [
      
      {
        datalabels: {
          labels: {
            title: null
          }
        },
        id: 1,
        label: 'Temperature',
        type: 'line',
        backgroundColor: 'rgb(116, 170, 67)',
        borderColor: 'rgb(116, 170, 67)',
        data: xyDataForChart,
        pointRadius: 0,
        borderWidth: 2,
        animation: false, // set here and not in options? weird...
        yAxisID: 'y'
      },
    ]
  };

  var yMinLimit = parseInt(Math.min(formattedMinTemperature, sensorNotificationMinThresh) - 5) < -40 ? -40 : parseInt(Math.min(minTemperature, sensorNotificationMinThresh) - 5);
  var yMaxLimit = parseInt(Math.max(formattedMaxTemperature, sensorNotificationMaxThresh) + 5);

  // hack for temperature limits for Organic Valley sensors.
  if(props.manufacturer === "SmartBarn"){
    yMinLimit = 20;
    yMaxLimit = 160;
  }
  // set the charting options
  const options = {
    animation: false, // animation kills performance
    parsing: false, // parsing kills performance
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
      },
      interaction: {
        mode: 'nearest'
      },
      annotation: {
        annotations: {
          line1: { // sensor notification minimum threshold
            type: 'line',
            yMin: sensorNotificationMinThresh ,
            yMax: sensorNotificationMinThresh,
            borderColor: 'red',
            borderWidth: 2,
          },
          line2: { // sensor notification max threshold
            type: 'line',
            yMin: sensorNotificationMaxThresh,
            yMax: sensorNotificationMaxThresh,
            borderColor: 'red',
            borderWidth: 2,
          }
        }
      }
    },
    scales: {
      x: {
        adjustScaleRange: false,
        min: props.startDate,
        max: endOfDay(props.endDate),
        type: 'time',
        time: { unit: 'day' }
      },
      x2: {
        labels: formattedHiLowByDay,
        position: "top",
        offset: true,
        display: true,
        ticks: {
          font: {
            weight: '500',
            size: '12'
          },
          color: 'green',
        },
        grid: {
          display: false
        }
      },
      y: {
        min: yMinLimit,
        max: yMaxLimit,
        type:'linear',
        labels: null,
        // suggestedMax: yMax
      },
    }
  }
 
  return(
    <Container fluid style = {{paddingRight: '0'}} className='sensor-charts' >
      <Line data = {
          data
        } 
        options = {
            options 
        }
        key={ props.id }
        />
    </Container>
  );
}


import React, { useState, useEffect } from "react";

import { startOfDay, endOfDay, format} from 'date-fns'

import { DataGrid, GridToolbarExport, GridToolbarContainer, gridClasses } from '@mui/x-data-grid';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import Tooltip from '@mui/material/Tooltip';

import Spinner from 'react-bootstrap/Spinner';

import { ListGatewayMessagesByDay } from "../Database.jsx";
import DatePicker from "./DatePicker";

import { Container } from "react-bootstrap";

export default function GatewayHistory (props) {

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [gatewayMessages, setGatewayMessages] = useState([]);
  
  const [startDate, setStartDate] = useState(startOfDay(new Date(new Date().setDate(new Date().getDate()-1))));
  const [endDate, setEndDate] = useState(new Date());

  const handleStartDateChange = (e) => {
    // console.log("Start date", e)
    setStartDate(e);
    setIsLoaded(false);
    // setDateRange[0] = e;
  }

  const handleEndDateChange = (e) => {
    setEndDate(e);
    setIsLoaded(false);
    // setDateRange[1] = e;
  }

  useEffect(() => {
    const getMoreGatewayMessages = (nextToken) => {
      ListGatewayMessagesByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), nextToken, 1440)
      .then(
        (result) => {
          // console.log("ListGatewayMessagesByDayNextToken", result)
          handleGatewayMessages(result.data.listGatewayMessagesByDay.items);
          if(result.data.listGatewayMessagesByDay.nextToken) {
            getMoreGatewayMessages(result.data.listGatewayMessagesByDay.nextToken);
          }
          else {
            setIsLoaded(true)
          }
        }, error => {
          setIsLoaded(true)
          setError(error)
        }
      );
    }
  
    ListGatewayMessagesByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), null, 1440)
    .then(
      (result) => {
        // console.log("ListGatewayMessagesByDay", result)
        setGatewayMessages(result.data.listGatewayMessagesByDay.items);
        setIsLoaded(true);
        //TODO: fix below.
        if(result.data.listGatewayMessagesByDay.nextToken) {
          getMoreGatewayMessages(result.data.listGatewayMessagesByDay.nextToken);
        }
        else {
          setIsLoaded(true)
        }
      }, error => {
        setIsLoaded(true)
        setError(error)
        
      }
    );
  }, [startDate, endDate, props.id]);


  const handleGatewayMessages = (data) => {
    // const message = gatewayMessages.concat(data);
    setGatewayMessages(gatewayMessages => [...gatewayMessages, ...data]);
  }

  const columns = [
    { field: 'date', width: '150',  
      valueFormatter: ({ value }) => format(Date.parse(value), 'MM/dd/yy h:mm aa') },
    { field: 'messageCount', width: '30', headerName: "#"},
    { field: 'messageType', width: '200', headerName: "Type"},
    { field: 'pendingChange', width: '10', renderCell: ({value}) => value ? <Tooltip title="Pending settings" placement="right"><BuildCircleIcon htmlColor="orange"/></Tooltip> : <></>, headerName: "" },] 

  
  // if(!isLoaded){
  //   return null;
  // }
  
  return (
    <Container fluid style={{ height: '600', width: '100%' }}>
      <DatePicker endDate={endDate} startDate={startDate} handleStartDateChange={(date) => handleStartDateChange(date)} handleEndDateChange={(date) => handleEndDateChange(date)}/>
      {(isLoaded) ? 
      <DataGrid 
          components={{
          Toolbar: CustomToolbar,
        }}
        pageSize={15} 
        rows={gatewayMessages} 
        rowHeight={25} 
        columns={columns} 
        getRowId={(row) => row.timestamp}
        rowsPerPageOptions={[15]}
        autoHeight
        disableSelectionOnClick 
      /> : <Container className="justify-content-center" style={{paddingTop:'50px', width:"100px"}}><Spinner animation="border" /></Container> }
    </Container>
  );
}

//TODO: put this in it's own file instead of copying the function twice in sensor body and here.


function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

import React from 'react';
import { Form } from 'react-bootstrap';
import NotificationToolTip from '../../Components/Buttons/Notifications/NotificationToolTip';
import EditReturnToNormal from '../../Components/Buttons/Notifications/EditReturnToNormal';

export default function EditGatewayPowerNotification(props) {
  const handleThresholds = (e) => {
    props.setMaxThresh(e.target.value);
    props.setMinThresh(e.target.value);
  }

  const handleMessage = (e) => {
    props.setMessage(e.target.value);
  }
  const handleName = (e) => {
    props.setName(e.target.value);
  }
  
  if(!props.deviceType) {
    return null;
  }

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="formNotificationName">
          <Form.Label>Notification Name</Form.Label>
          <Form.Control type="text" defaultValue={props.notificationName} onChange={ (e) => handleName(e) } />
          <Form.Label>Notification Message &nbsp; </Form.Label>
          <NotificationToolTip />
          <Form.Control type="text" defaultValue={ props.message } onChange={ (e) => handleMessage(e) } />
          <label style={{ float: "left" }} className="input-label">
            Alert When Loop:
          </label>
          <select 
            id="awareMode" 
            className="form-control" 
            defaultValue={props.minThresh} 
            name="Aware State Mode"
            onChange={ handleThresholds } 
            disabled
          >
            <option value={0}>Closed</option>
            <option value={1}>Open</option>
          </select>
          <EditReturnToNormal
            sendReturnToNormalMessage={props.sendReturnToNormalMessage}
            setSendReturnToNormalMessage={props.setSendReturnToNormalMessage}
            returnToNormalMessage={props.returnToNormalMessage}
            setReturnToNormalMessage={props.setReturnToNormalMessage}
          />
        </Form.Group>
      </Form>
    </>
  );
}

import React, { useEffect, useRef, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import GatewayAdminSettings from "./GatewayAdminSettings.jsx";

import { IOTData } from "../../services.js";

const iotdata = IOTData();

export default function GatewayNotificationTab (props) {
  const [notificationStatus, setNotificationStatus] = useState(props.notificationStatus);
  const [powerStatusPin, setPowerStatusPin] = useState(props.powerStatusPinEnabled || false);
  const [modemSerialNum, setModemSerialNum] = useState(props.modemSerialNum || "");

  const testNotificationTopic = 'notifications/gateway/alerting'
  const testNotification = () => {
    // console.log("TODO: sensor test notifications!");
    const iot_data = {
      account: props.account,
      data: props.sensorMessage.data,
      dataValues: JSON.parse(props.sensorMessage.dataValues),
      deviceId: props.id,
      deviceType: "sensor",
      name: props.name,
      networkId: props.networkId,
      networkName: props.networkName,
      notificationId: props.notificationId,
      notification: {
        message: "TEST " + props.message,
        name: props.name,
        assignedUsers: props.assignedUsers,
        notificationType: "TEST",
      },
      notificationName: props.notificationName,
      notificationStatus: "alerting",
      scaleValue1: props.scaleValue1,
      scaleValue2: props.scaleValue2,
      senid: props.id,
      timestamp: Date.now(),
      version: props.deviceType
    }

    console.log("iot_data", iot_data);
    // Send reform message to sensor topic
    const params = {
      topic: testNotificationTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        // console.log(data);
        alert("Test notification sent!");
      }
    });
  }

  const didMount = useRef(false);
  useEffect(() => {

    const handlePause = () => {
      const json_string = {
        deviceId: props.id,
        id: props.id,
        gwid: props.id,
        notificationStatus: notificationStatus,
        cmdStatus: "REQUEST",
        cmdTimeStamp: new Date()
      }
  
      const params = {
        topic: "cmd/gateway/monnit/settings"  ,
        payload: JSON.stringify(json_string),
        qos: 0
      };
      iotdata.publish(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
          alert("Problem saving settings!");
        }
        else {
          console.log("response", data);
          alert("Notification "+ notificationStatus);
          window.location.reload();
        }
      });
    }
  
    // Return early, if this is the first render:
    if ( !didMount.current ) {
      didMount.current = true;
    } 
    else {
      handlePause();
    }
  }, [notificationStatus, props.deviceType, props.id]);

  const users = props.assignedUsers ?
    <Grid container spacing={1}>
      {props.assignedUsers.map((element, index) => 
        <Grid item index={index} xs={12} lg={4} key={index}>
          <Paper elevation={2} >
            <h4>{index + 1}. {element.name}</h4> 
            Contact method: { element.contactMethod }<br/>
            Contact: { element.contactValue }
          </Paper>
        </Grid>
      )}
    </Grid> 
    : <></>
  const buttonText = notificationStatus === "paused" ? "Unpause" : "Pause";

  return (
    <div>
      <Stack spacing={1}>
        <div>
          <Button disabled={ true } variant='contained' color='success' size='small' onClick={() => testNotification()}>Test<SendIcon fontSize='small'/></Button>
          <Button variant='contained' color='primary' size='small' onClick={() => setNotificationStatus(notificationStatus === "paused" ? "armed" : "paused")}>{buttonText}</Button>
          <GatewayAdminSettings
            accountId={props.account}
            deviceName={props.deviceName}
            gwid={props.id}
            modemSerialNum={modemSerialNum}
            networkId={props.networkId}
            networkName={props.networkName}
            setModemSerialNum={setModemSerialNum}
            powerStatusPin={powerStatusPin} 
            setPowerStatusPin={(e) => setPowerStatusPin(e)}
          />

          <div>
            Modem Serial Number: {modemSerialNum}
          </div>
          <div>
            Power outage detection: <Checkbox checked={powerStatusPin} disabled/>
          </div>
        </div>
        <Paper elevation={0}>
          Notification name: { props.notificationName }<br/>
          Status: { props.notificationStatus }<br/>
          Message: { props.message }
        </Paper>
        <Paper elevation={0}>
          <div>
            User list:
          {users}
          </div>
        </Paper>
      </Stack>
    </div>
  );
}
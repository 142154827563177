 
import { useState, useEffect } from 'react';
import { listNetworksByAccount } from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export default function useLoadAllNetworks(accountId) {
  const [allNetworks, setAllNetworks] = useState(null);
  useEffect(() => {
    const fetchData = async () =>{
      const result = await API.graphql(graphqlOperation(listNetworksByAccount, { account: accountId, limit: 25, nextToken: null })).catch((error) => console.log(error));
      localStorage.setItem("allNetworks", JSON.stringify(result.data.listNetworksByAccount.items));
      setAllNetworks(result.data.listNetworksByAccount.items);
    }
    accountId&&fetchData();
  },[accountId]);
  return allNetworks;
}
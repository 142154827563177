import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'; //updated uuid method
import CreateNewAccountForm from './AdminCreateNewAccountForm';
import { CreateNewAccounts } from '../Database.jsx';
import { CreateNetwork } from '../Database.jsx';

/**
 * I would like this to be generalized and use children props but having trouble getting that to work.
 * @param {*} props 
 * @returns 
 */

export default function CreateNewAccount(props) {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSubmit = (event) => {
    const formdata = event.target;
    const accountId = uuidv4();

    const accountData = {
      "id": accountId,
      "accountId": accountId,
      "parentAccountId": formdata.formParentAccount.value,
      "name": formdata.formCompany.value,
      "accountName": formdata.formCompany.value,
      "address": formdata.formAddress.value,
      "city": formdata.formCity.value,
      "state": formdata.formState.value,
      "zipCode": formdata.formZip.value,
      "country": formdata.formCountry.value,
      "timezone": formdata.formTimezone.value,
      "notes": formdata.formNotes.value,
      "dateCreated": Date.now()
    }

    setValidated(true);
    // handleClose();
    // lambda action here
    // ideally receive a response from the lambda and then show the following alert:
    // var alertData = ("EVENT " + JSON.stringify(accountData));
    handleClose();
    // console.log(accountData);
    CreateNewAccounts(accountData)
      .then(
        (result) => {
          // console.log("createAccountResult", result);
          setSuccess(result);
          alert("Account created successfully!");
          CreateNetwork({account: accountId, id: Math.floor((new Date().valueOf()/1000)).toString(), name: formdata.networkName.value})
          .then(
            (result) => {
              setSuccess(result);
              alert("Network created successfully!");
              window.location.reload();
            }
            // (error) => {
            //   setError(error);
            //   alert("Error creating network: ", error);
            //   // window.location.reload();
            // }
          );
        },
        (error) => {
          setError(error);
          alert("Error creating account or network: ", error);
          // window.location.reload();
        });
    // then page refreshes. New account should be visible and accessible.
  }


  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {props.buttonText}
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{props.buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateNewAccountForm handleSubmit={handleSubmit} id={props.id}/>
        </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form={props.id}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


import React from "react";
import { format} from 'date-fns'
import Container from "react-bootstrap/Container";
import Spinner from 'react-bootstrap/Spinner'

import { DataGrid, GridToolbarExport, GridToolbarContainer, gridClasses, GridRowModes } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { v4 as uuidv4 } from 'uuid';
import CreateNewUserNoteModal from "./CreateUserNoteModal";
const randomId = () => uuidv4();

// test string
// const userNotes = [
//     { timestamp: 1668501600, userNotes: 'This is a test user note. I wonder what was so important.', userFullName: 'a.user' },
//     { timestamp: 1668500600, userNotes: 'test', userFullName: 'Andrew Klein' },
//     { timestamp: 1668401600, userNotes: 'test', userFullName: 'test' },
// ]

// use test token here for testing then delete before deployment.

export default function UserNotesTab(props) { // userId and senId props
    const [rows, setRows] = React.useState(props.userNotes);
    const handleAddRow = (data) => {
        setRows([...rows, { id: data.senid, senid: data.senid, timestamp: Date.now()/1000, userFullName: data.userFullName, userNotes: data.userNotes }]);
    };
    // the fields that will be returned by the GraphQL query.
    // senid
    // userId
    // userFullName
    // timestamp
    // userNotes

    
    const dataGridColumns =
        [
            { field: 'timestamp', headerName: 'Date', width: '155', valueFormatter: ({ value }) => format(new Date(value*1000), 'MM/dd/yyyy h:mma') },
            // { field: 'userId', width: '100', headerName: 'User ID' },
            { field: 'userFullName', width: '200', headerName: 'User Name'},
            { field: 'userNotes', headerName: 'Notes', width: '500' }
        ] 

    return(
        <Container fluid style={{ height: '600', width: '100%' }}>
            {props.userNotes ? (
                <DataGrid 
                    getRowHeight={() => 'auto'} 
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        toolbar: { setRows, rows, senid: props.senid, handleAddRow }, // passes the propse the CustomToolbar component
                    }}
                    pageSize={ 15 } 
                    rows={ rows } 
                    rowHeight={ 25 } 
                    columns={ dataGridColumns } 
                    getRowId={(row) => row.timestamp} // serves as the row ID required by Datagrid
                    rowsPerPageOptions={[15]}
                    autoHeight
                    disableSelectionOnClick
                    initialState={{
                        sorting: {
                          sortModel: [{ field: 'timestamp', sort: 'desc' }],
                        },
                      }}
                />
            ) : ( 
                <Container className="justify-content-center" style={{width:"100px"}}><Spinner animation="border" /></Container>
            )}
        </Container>
    );
}
  
function CustomToolbar(props) {

    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <GridToolbarExport />
                <CreateNewUserNoteModal
                    senid={props.senid}
                    handleAddRow={props.handleAddRow}
                />
        </GridToolbarContainer> 
    );
  }
  
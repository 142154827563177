import awsmobile from "./aws-exports.js";
import { IotData } from "aws-sdk";

var iotdata = null;

export function IOTData() {
  if (iotdata == null) {
    iotdata = new IotData({
      apiVersion: 'latest',
      endpoint: awsmobile.iot_endpoint,
      region: awsmobile.aws_project_region,
      accessKeyId: awsmobile.access_key_id,
      secretAccessKey: awsmobile.secret_access_key,
    });
  }
  return iotdata;

}
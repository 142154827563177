import React from "react";

import { Alert, Button } from "react-bootstrap";
import { LogNotifications } from "../../Database";
import { IOTData } from "../../services.js";
import { v4 as uuidv4 } from 'uuid';

const iotdata = IOTData();

export default function SensorAlertOverlay(props){

  const topic = "notifications/sensor/acknowledge";
  const handleAcknowledgeNotification = () => {
    const notificationStatus = props.notificationStatus.startsWith("time") ? "timerAck" : "acknowledged";
    const json_string = {
      deviceId: props.deviceId, 
      notificationStatus: notificationStatus,
      notificationId: props.notificationId,
      userFirstLastName: localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName'),
      contactMethod: 'App',
      message: 'User acknowledged alert',
      result: 'Success',
      timestamp: Math.floor((new Date().valueOf()/1000)),
      topic: topic
    }
    const params = {
      topic: topic,
      payload: JSON.stringify(json_string),
      qos: 0
    };

    LogNotifications(json_string, json_string.notificationId)
    .then(
      iotdata.publish(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
        }
        else {
          console.log("response", data);
        }
      })
    );
    props.setNotificationStatus(notificationStatus);
    alert("Notification acknowledged!");
  }

  const handleResetNotification = () => {
    const notificationStatus = props.notificationStatus.startsWith("time") ? "timerActive" : "armed";
    const json_string = {
      deviceId: props.deviceId, 
      notificationStatus: notificationStatus,
      notificationId: props.notificationId,
      userFirstLastName: localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName'),
      contactMethod: 'App',
      message: 'User reset alert',
      result: 'Success',
      timestamp: Math.floor((new Date().valueOf()/1000))
    }
    const params = {
      topic: "notifications/sensor/reset"  ,
      payload: JSON.stringify(json_string),
      qos: 0
    };
    console.log("JSON STRING: ", json_string)
    LogNotifications(json_string)
    .then(
      iotdata.publish(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
          alert("Problem saving settings!");
        }
        else {
          console.log("response", data);
          // alert("Notification reset!");
          // window.location.reload();
        }
      })
    )
    props.setNotificationStatus(notificationStatus);
    alert("Notification reset!");
  }

  const notificationStatus = props.notificationStatus; 
  const alertOverlay = notificationStatus === "alerting" || notificationStatus === "timerAlarm"  ?
  <><Alert className={"in"} key={uuidv4()} deviceid={props.deviceId} variant={"warning"}>Sensor Notification Alerting!<Button className="ml-1" size="sm" onClick={() => handleAcknowledgeNotification()} variant="danger">Acknowledge</Button></Alert></>
  : notificationStatus === "acknowledged" || notificationStatus === "timerAck" ? <><Alert style={{padding:"2px"}}className={"in"} key={uuidv4()} deviceid={props.deviceId} variant={"warning"}><Button className="ml-1" size="sm" onClick={() => handleResetNotification()} variant="warning">Reset Notification</Button></Alert></>
  : <></>;
  return(
    <>
      {alertOverlay}
    </>
  )
}
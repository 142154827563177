import React, { useState } from "react";

/* react-bootstrap imports */
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

/* Smart Barn imports */
import "./Overview.css";
import DisplayDevices from "./DisplayDevices";

export default function Overview(props) {
  console.log("Overview")
  const [currentView, setCurrentView] = useState("All");
  const [selectedNetwork, setSelectedNetwork] = useState(localStorage.getItem("selectedNetwork") || "All Networks");
  const handleNetworkSelect = (event) => {
    setSelectedNetwork(event.target.value);
    localStorage.setItem("selectedNetwork", event.target.value);
  }

  if(!props.allNetworks) {
    return(null);
  }

  const networks = props.allNetworks.map(element => (
    <option key={element.id} value={element.id }>{element.name}</option>
    ));
    
  return (
    <Container fluid>
      <ButtonToolbar aria-label="Toolbar with button groups" >
          {/* network filter dropdown */}
        <select value={ selectedNetwork } id="network" title={"network"} onChange={(e) => handleNetworkSelect(e) }>
          <option value="All Networks" as="button" >All Networks</option>
          {networks}
        </select>          
        {/* Switch between gateway and sensor views */}
        <Button 
          variant="primary" 
          className="btn-primary"
          id="alternateViewButton"
          onClick={ () => { setCurrentView((currentView === "All") ? "Sensors" : "All"); }
          }
          >
          View&nbsp;{(currentView === "All") ? "Sensors" : "All"}
        </Button>
      </ButtonToolbar>
      <DisplayDevices
        allGateways={ selectedNetwork === "All Networks" ? props.allGateways : props.allGateways.filter(({networkId}) => networkId === parseInt(selectedNetwork)) }
        allSensors={ selectedNetwork === "All Networks" ? props.allSensors : props.allSensors.filter(({networkId}) => networkId === parseInt(selectedNetwork))}
        allUsers={ props.allUsers }
        currentView={currentView}
        selectedNetwork = { selectedNetwork }
      />
    </Container>
  );
}


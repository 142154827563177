import React, { useState, useEffect } from "react";
import { format } from 'date-fns'


import { SensorDictionary } from "../SensorTypes.jsx";
import { GetMostRecentSensorMessage, LogNotifications } from "../Database.jsx";
import { v4 as uuidv4 } from 'uuid';
import { IOTData } from "../services.js";

import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import NetworkWifi3BarIcon from '@mui/icons-material/NetworkWifi3Bar';
import NetworkWifi2BarIcon from '@mui/icons-material/NetworkWifi2Bar';
import NetworkWifi1BarIcon from '@mui/icons-material/NetworkWifi1Bar';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';

import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import "./Sensor.css";
import { Accordion, Alert, Button, Col, Container, Row, Badge } from "react-bootstrap";
import RangeExtenderBody from "./RangeExtenderBody.jsx";
// import { GetThresholds } from "../SensorTypes.jsx";

const iotdata = IOTData();

/**
 * Sensor function. Creates and renders the sensor accordians.
 */

export default function RangeExtender(props) {
  // expexted check in time is within the last 15 minutes (gateway heartbeat + sensor heartbeat + a minute of extra time)
  const [lastMessageTime, setLastMessageTime] = useState("--");
  const [sensorMessage, setSensorMessage] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [loadSensorData, setLoadSensorData] = useState(false);
  const handleLoadSensorData = () => {
    setLoadSensorData(true);
  }
  // gets the first sensor message on loading, limits scan to single message. 
  useEffect(() => {
    GetMostRecentSensorMessage(props.id, null, 1)
    .then(
      (result) => {
        if(result.data.listFirstSensorMessages.items.length === 0){
          setSensorMessage([]);
          setIsLoaded(true);
        }
        else {
          setSensorMessage(result.data.listFirstSensorMessages.items[0]);
          setLastMessageTime(format(new Date(result.data.listFirstSensorMessages.items[0].timestamp*1000), "MM/dd/yyyy h:mma"));
          setIsLoaded(true);
        }
        
      }, error => {
        setIsLoaded(true)
        setError(error)
      }
    );
  },[props.id]);

  if(!isLoaded){
    return null;
  }

  if(error) {
    console.log(error)
  }

  const alertOverlay = props.notificationStatus === "alerting" ?
    <><Alert className={"in"} key={uuidv4()} deviceid={props.id} variant={"warning"}>Sensor Notification Alerting!<Button className="ml-1" size="sm" onClick={() => handleAcknowledgeNotification()} variant="danger">Acknowledge</Button></Alert></>
    : props.notificationStatus === "acknowledged" ? <><Alert style={{padding:"2px"}}className={"in"} key={uuidv4()} deviceid={props.id} variant={"warning"}><Button className="ml-1" size="sm" onClick={() => handleResetNotification()} variant="warning">Reset Notification</Button></Alert></>
    : <></>;

  const handleAcknowledgeNotification = () => {
    const json_string = {
      deviceId: props.deviceId, 
      notificationStatus: "acknowledged",
      notificationId: props.notificationId,
      userFirstLastName: localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName'),
      contactMethod: 'App',
      message: 'User acknowledged alert',
      result: 'Success',
      timestamp: Math.floor((new Date().valueOf()/1000))
    }
    const params = {
      topic: "notifications/sensor/acknowledge"  ,
      payload: JSON.stringify(json_string),
      qos: 0
    };

    LogNotifications(json_string, json_string.notificationId)
    .then(
      iotdata.publish(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
          alert("Problem saving settings!");
        }
        else {
          console.log("response", data);
          alert("Notification acknowledged!");
          window.location.reload();
        }
      })
    );
  }

  const handleResetNotification = () => {
    const json_string = {
      deviceId: props.deviceId, 
      notificationStatus: "armed",
      notificationId: props.notificationId,
      userFirstLastName: localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName'),
      contactMethod: 'App',
      message: 'User reset alert',
      result: 'Success',
      timestamp: Math.floor((new Date().valueOf()/1000))
    }
    const params = {
      topic: "notifications/sensor/reset"  ,
      payload: JSON.stringify(json_string),
      qos: 0
    };
    console.log("JSON STRING: ", json_string)
    LogNotifications(json_string)
    .then(
      iotdata.publish(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
          alert("Problem saving settings!");
        }
        else {
          console.log("response", data);
          alert("Notification reset!");
          window.location.reload();
        }
      })
    )
  }

  const afterExpectedCheckinTime = sensorMessage.timestamp*1000 - Date.now()+(1000*60*(props.heartbeat+10)) < 0 ? true : false; // heartbeat + 10 minutes = inactive.


  //NOTE: have to put MUI icons as the child of a container for them to be visible.
  return (
    <Accordion.Item eventKey={props.id}>
      <b style={{textAlign:"left"}}>{props.name}</b> <p style={{fontSize:"small", display:"inline", fontStyle:"italic" }}>{props.deviceId} {props.networkName}</p>
      {alertOverlay}
      <Accordion.Header onClick={ handleLoadSensorData }>
        <Col style={{padding:"0px"}}>
          <Row className="align-items-center" style={{filter: afterExpectedCheckinTime || lastMessageTime==='--' ? 'grayscale(100%)' : 'none'}}>
            <Col xs="1" lg="auto" style={{minWidth:"10px"}} >
              <StatusIcons
                expectedCheckInTime={ Date.now()-(1000*60*(props.heartbeat+6)) } // expected check in = heartbeat + a typical GW heartbeat*milliseconds*minutes
                notificationStatus={ props.notificationStatus } 
                pendingChange={ props.pendingChange }
                timestamp={ sensorMessage.timestamp }
                message={ sensorMessage }
              />
            </Col>
            <Col xs="3" lg="1" >
              <img title={props.version || "sensor"} 
                className="sensor-image-column" 
                src={SensorDictionary[props.version].deviceImage} 
                alt="Sensor" 
              />
            </Col>
            <Col xs="1" lg="auto" >
              <Row>
                <Container fluid>
                  {GetSensorSignal(parseInt(sensorMessage.SignalPercent))}
                </Container>
              </Row>
              <Row><Container fluid></Container></Row>
            </Col>
            <Col xs="4" lg="4" >
              {sensorMessage.data} sensors
            </Col>
            <Col style={{ display:"inline", padding:"0px" }}>{lastMessageTime}</Col>
            <Col xs="3" sm="5" style={{ textAlign:"center", paddingLeft:"5px" }}>
              <Badge pill bg={ props.notificationStatus === "disarmed" || props.notificationStatus === "N/A" ? "secondary" : sensorMessage.data < props.minThresh && sensorMessage.data > props.maxThresh ? "danger" : props.notificationStatus === "armed" ? "success" : "secondary"} style={{width:"170px"}}>Notification: {props.notificationStatus}</Badge>
            </Col>

          </Row>
        </Col>
      </Accordion.Header>
      <Accordion.Body style={{padding:"0px" }}>
        {/* <Container fluid id={"collapse-" + props.id} arial-labelledby={"collapse-" + props.id} data-parent="#sensorTable"> */}
        <Container fluid >
          <Container fluid>
          <Col style={{ textAlign: "left", color: "#74AA43", paddingLeft:"0px" }}>
            <Badge bg="secondary">Gateway ID: {sensorMessage.gwid}</Badge>
            <Badge bg="secondary">ID: { props.id }</Badge>
          </Col>
          </Container>
          {loadSensorData === true && (<RangeExtenderBody 
          id={props.id}
          account={props.account} 
          accountId={props.accountId}
          assignedUsers={props.assignedUsers}
          deviceId={props.id}
          deviceType={props.deviceType}
          heartbeat={props.heartbeat}
          manufacturer={props.manufacturer}
          maxThresh={props.maxThresh}
          minThresh={props.minThresh}
          message={props.message}
          name={props.name}
          networkId={props.networkId}
          notificationId={props.notificationId}
          notificationName={props.notificationName}
          notificationStatus={props.notificationStatus}
          scaleValue1={props.scaleValue1}
          scaleValue2={props.scaleValue2}
          sensorCode={props.sensorCode}
          version={props.version}
        />)}
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
  
}


function GetSensorSignal(messageSignal) {
  if (messageSignal === "") {
    return <HelpIcon sx={{ fontSize: 18 }} title="?" htmlColor="gray" />;
  } else {
    if (messageSignal > 80) {
      return <SignalWifi4BarIcon sx={{ fontSize: 18 }} htmlColor="green" title={messageSignal} alt={ '4 bars' } />;
    } else if (messageSignal > 60) {
      return <NetworkWifiIcon sx={{ fontSize: 18 }} htmlColor="green" title={messageSignal} alt={'4 bars'} />;
    } else if (messageSignal > 40) {
      return <NetworkWifi3BarIcon sx={{ fontSize: 18 }} htmlColor="green" title={messageSignal} alt={'3 bars'}/>;
    } else if (messageSignal > 20) {
      return <NetworkWifi2BarIcon sx={{ fontSize: 18 }} htmlColor="yellow" title={messageSignal} alt={'2 bars'}/>;
    } else if (messageSignal > 0) {
      return <NetworkWifi1BarIcon sx={{ fontSize: 18 }} htmlColor="red" title={messageSignal} alt={'1 bar'}/>;
    } else {
      return <SignalWifi0BarIcon sx={{ fontSize: 18 }} htmlColor="red" title={"0"} alt={'no signal'}/>;
    }
  }
}

function StatusIcons(props) {
  var statusChangeIcon = null;
  var statusIcon = null;
  if (props.pendingChange) {
    statusChangeIcon = <BuildCircleIcon sx={{ fontSize: 18 }} title={"Pending Settings Change"} htmlColor="orange" />;
  }
  if (props.message.length === 0) {
    statusIcon = <PowerSettingsNewIcon sx={{ fontSize: 18 }} title={"Off/No Messages Received"} htmlColor="gray" />;
  } 
  // TODO: fix this issue (no question mark status)
  else if (props.timestamp*1000-props.expectedCheckInTime < 0) {
      statusIcon = <HelpIcon sx={{ fontSize: 18 }} title={"Unknown Status"} htmlColor="gray" />;
  } 
  else {
    statusIcon = <CheckCircleIcon sx={{ fontSize: 18 }} htmlColor="green"  />;
  }
  return (
    <>
      <Row><Container fluid>{statusIcon}</Container></Row>
      <Row><Container fluid>{statusChangeIcon}</Container></Row>   
  </>
  );
}

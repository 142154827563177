
import React, { useEffect, useRef, useState } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SensorNotificationPauseUntilDate from '../SensorNotificationPauseUntilDate.jsx';
import { IOTData } from "../../services.js";

export default function SensorNotificationTab (props) {
  const [notificationStatus, setNotificationStatus] = useState(props.notificationStatus);
  const testNotificationTopic = 'notifications/sensor/alerting'
  // TODO: FIX NOTIFICATION
  const testNotification = () => {
    const iot_data = {
      account: props.account,
      data: props.sensorMessage.data,
      dataValues: JSON.parse(props.sensorMessage.dataValues),
      deviceId: props.id,
      deviceType: "sensor",
      name: props.name,
      networkId: props.networkId,
      networkName: props.networkName,
      notificationId: props.notificationId,
      notification: {
        message: "TEST " + props.message,
        name: props.name,
        assignedUsers: props.assignedUsers,
        notificationType: "TEST",
      },
      notificationName: props.notificationName,
      notificationStatus: "alerting",
      scaleValue1: props.scaleValue1,
      scaleValue2: props.scaleValue2,
      senid: props.id,
      timestamp: Date.now(),
      version: props.deviceType
    }

    console.log("iot_data", iot_data);
    const params = {
      topic: testNotificationTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    IOTData().publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        alert("Test notification sent!");
      }
    });
  }

  const didMount = useRef(false);
  useEffect(() => {
    const handlePause = () => {
      const notificationPauseUntilDateTime = notificationStatus === "paused" ? 10413792000 : 0; // if pausing, then pause until far into the future, if unpausing then far in thepast
      const json_string = {
        deviceId: props.id,
        id: props.id,
        notificationStatus: notificationStatus,
        notificationPauseUntilDateTime: notificationPauseUntilDateTime,
        cmdStatus: "REQUEST",
        cmdTimeStamp: new Date(),
      }
  
      const params = {
        topic: "notifications/sensor/setPauseStatus"  ,
        payload: JSON.stringify(json_string),
        qos: 0
      };
      IOTData().publish(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
          alert("Problem saving settings!");
        }
        else {
          console.log("response", data);
          alert("Notification "+ notificationStatus);
          window.location.reload();
        }
      });
    }
  
    if ( !didMount.current ) {
      didMount.current = true;
    } 
    else {
      handlePause(true);
    }
  }, [notificationStatus, props.deviceType, props.id]);

  const buttonDisabled = props.notificationStatus === 'N/A';
  const users = props.assignedUsers ?
    <Grid container spacing={1}>
      {props.assignedUsers.map((element, index) => 
        <Grid item index={index} xs={12} lg={4} key={index}>
          <Paper elevation={2} >
            <h4>{index + 1}. {element.name}</h4> 
            Contact method: { element.contactMethod }<br/>
            Contact: { element.contactValue }
          </Paper>
        </Grid>
      )}
    </Grid> 
    : <></>
  const buttonWidth = window.innerWidth <= 400 ? "45%" : "100px";
  const buttonText = notificationStatus === "paused" ? "Unpause" : "Pause";
  const buttonIcon = notificationStatus === "paused" ? <PlayArrowIcon /> : <PauseIcon />;
  return (
      <Stack spacing={1} >
        <Stack spacing ={1}   direction={{ xs: 'column', sm: 'row' }}>
          <Grid>
            <Button style={{width: buttonWidth}} disabled={ buttonDisabled } variant='contained' color='success' size='small' onClick={() => testNotification()}>Test<SendToMobileIcon size='small'/></Button>
            <Button style={{width: buttonWidth}} variant='contained' color='primary' size='small' onClick={() => setNotificationStatus(notificationStatus === "paused" ? "armed" : "paused")}>{buttonText}{buttonIcon}</Button>
          </Grid>
          <SensorNotificationPauseUntilDate
            id={ props.id } 
            deviceId={ props.id }
            notificationStatus={ notificationStatus }
            notificationPausedUntilDateTime={ props.notificationPausedUntilDateTime }
          />
        </Stack>
        <Paper elevation={0}>
          Notification name: { props.notificationName }<br/>
          Status: { props.notificationStatus }<br/>
          Message: { props.message }
        </Paper>
        <Paper elevation={0}>
          <div>
            User list:
          {users}
          </div>
        </Paper>
      </Stack>
  );
}
import { API, graphqlOperation } from "aws-amplify";
import { 
  getSensors,
  getAccounts,
  getGateways
} from "../graphql/queries";

export async function GetSensor(senId) {
  const apiData = await API.graphql(graphqlOperation(getSensors, { id: senId })).catch((error) => console.log("graphQL error: ", error));
  // console.log(apiData);
  return apiData;
}


export async function GetGateway(gatewayId) {
  const apiData = await API.graphql(graphqlOperation(getGateways, { id: gatewayId })).catch((error) => console.log("graphQL error: ", error));
  // console.log(apiData);
  return apiData;
}


export async function GetAccount(id) {
  const apiData = await API.graphql(graphqlOperation(getAccounts, { id: id })).catch((error) => console.log("graphQL error: ", error));
  // console.log(apiData);

  return apiData;
}

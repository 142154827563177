import React, { useState } from "react";
import { format } from 'date-fns'
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import PowerIcon from '@mui/icons-material/Power';
import StatusIcons from "./StatusIcons.jsx";
import "./BatteryBackup.css";
import { Accordion, Col, Container, Row, Badge } from "react-bootstrap";
import BatteryBackupBody from "./BatteryBackupBody.jsx";
import useGetMostRecentSensorMessage from "../../hooks/userGetMostRecentSensorMessage.jsx";
import AlertOverlay from "../AlertOverlay.jsx";
import UpdateNotificationStatus from "./UpdateNotificationStatus.jsx";

export default function BatteryBackup(props) {
  // expected check in time is within the last 15 minutes (gateway heartbeat + sensor heartbeat + a minute of extra time)
  const [loadSensorData, setLoadSensorData] = useState(false); // flag that tells the sensor body to load the history on click from user.
  const handleLoadSensorData = () => {setLoadSensorData(true);} // this is set when the sensor body is clicked. Prevents every sensor from loading all their history before user interacts with it.
  const sensorMessage = useGetMostRecentSensorMessage(props.id);
  const lastMessageTime = sensorMessage&&getLastMessageTime(sensorMessage.timestamp);

  const handleAcknowledgeNotification = () => {
    const notificationStatus = {
      notificationStatus: 'acknowledged',
      notificationId: props.notificationId,
      deviceId: props.deviceId,
    }
    UpdateNotificationStatus(notificationStatus);
  }

  const handleResetNotification = () => {
    const notificationStatus = {
      notificationStatus: 'armed',
      notificationId: props.notificationId,
      deviceId: props.deviceId,
    }
    UpdateNotificationStatus(notificationStatus);
  }

  //NOTE: have to put MUI icons as the child of a container for them to be visible.
  return (
    <Accordion.Item eventKey={props.id}>
      <b style={{textAlign:"left"}}>{props.name}</b> <p style={{fontSize:"small", display:"inline", fontStyle:"italic" }}>{props.deviceId} {props.networkName}</p>
      <AlertOverlay 
        notificationStatus={props.notificationStatus} 
        handleResetNotification={handleResetNotification} 
        handleAcknowledgeNotification={handleAcknowledgeNotification} 
        />
      <Accordion.Header onClick={ handleLoadSensorData }>
        <Col style={{padding:"0px"}}>
          <Row className="align-items-center">
            <Col title="Status Icons"  xs="1" lg="auto" style={{minWidth:"10px"}} >
              <StatusIcons data={sensorMessage?.data} notificationStatus={props.notificationStatus} />
            </Col>
            <Col xs="3" lg="1" >
              <img title={"Battery Backup"} 
                className="sensor-image-column" 
                src={"/Images/SensorImages/battery-hexagon.png"} 
                alt="Battery Backup" 
              />
            </Col>
            <Col xs="1" lg="auto" >
              <Row>
                <Container fluid>
                <Row>
                  <Container fluid>
                    {sensorMessage?.data === 0 ? <PowerIcon sx={{ fontSize: 18 }} title={"Power OK"} htmlColor="green" /> : <BatteryFullIcon sx={{ fontSize: 18 }} title={"Power OK"} htmlColor="green" />}
                  </Container>
                </Row>
                </Container>
              </Row>
              <Row><Container fluid></Container></Row>
            </Col>
            <Col xs="4" lg="4" >
              <h4><b>{sensorMessage?.data === 1 ? "On Battery!" : "Power OK" }</b></h4>
            </Col>
            <Col style={{ display:"inline", padding:"0px" }}>{lastMessageTime}</Col>
            <Col xs="3" sm="5" style={{ textAlign:"center", paddingLeft:"5px" }}>
              <Badge pill bg={ props.notificationStatus === "disarmed" || props.notificationStatus === "N/A" ? "secondary" : sensorMessage?.data < props.minThresh && sensorMessage?.data > props.maxThresh ? "danger" : props.notificationStatus === "armed" ? "success" : "secondary"} style={{width:"170px"}}>Notification: {props.notificationStatus}</Badge>
            </Col>
          </Row>
        </Col>
      </Accordion.Header>
      <Accordion.Body style={{padding:"0px" }}>
        <Container fluid >
          <Container fluid>
          <Col style={{ textAlign: "left", color: "#74AA43", paddingLeft:"0px" }}>
            <Badge bg="secondary">Gateway ID: {props.gatewayId}</Badge>
            <Badge bg="secondary">ID: { props.id }</Badge>
          </Col>
          </Container>
          {loadSensorData === true && (<BatteryBackupBody 
          id={props.id}
          account={props.account} 
          accountId={props.accountId}
          assignedUsers={props.assignedUsers}
          deviceId={props.id}
          deviceType={props.deviceType}
          heartbeat={props.heartbeat}
          manufacturer={props.manufacturer}
          maxThresh={props.maxThresh}
          minThresh={props.minThresh}
          message={props.message}
          name={props.name}
          networkId={props.networkId}
          notificationId={props.notificationId}
          notificationName={props.notificationName}
          notificationStatus={props.notificationStatus}
          scaleValue1={props.scaleValue1}
          scaleValue2={props.scaleValue2}
          sensorCode={props.sensorCode}
          version={props.version}
        />)}
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function getLastMessageTime(lastMessageTime){
  if(lastMessageTime != null) {
    const messageTime = format(new Date(lastMessageTime*1000), "MM/dd/yyyy h:mma");
    return messageTime;
  }
  return "--";
}


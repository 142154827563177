import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function EditTemperatureSettingsTab (props) {
  const handleSetDataValueOffset = (e) => {
    if(props.scaleValue1.startsWith('F')){
      const offset = Math.round((e*5/9)*10)/10; // multiply and divide by 10 to round to a single decimal but with float intact, unlike offset.toFixed(1);
      props.setDataValueOffset(offset);
    }
    else {
      props.setDataValueOffset(e);
    }
  }

  const handleSetScaleValue1 = (e) => {
    props.setDataValueOffset(0.0); // reset offset to 0 when changing the scale value between F and C.
    props.setScaleValue1(e);
  }

  const offsetValue = props.scaleValue1.startsWith('F') ? (props.dataValueOffset * 9/5).toFixed() : props.dataValueOffset;
  return(
    <>
      <label className="input-label">Offset</label>
      <Tooltip title="Offset temperature up or down" placement="right">
        <IconButton>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <input className="form-control" max={10} min={-10} name="dataValueOffset" type="number" value={ offsetValue } onChange={(e) => handleSetDataValueOffset(e.target.value)}></input>
      <label className="input-label">Scale Value</label>
      <select value={props.scaleValue1} className="form-control" onChange={(e) => handleSetScaleValue1(e.target.value)} name="scaleValue1">
        <option value="F">F</option>
        <option value="C">C</option>
      </select>
    </>
  );
}
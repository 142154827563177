import React from "react";
import { Image } from "@aws-amplify/ui-react";

import "./Header.css";

import { Col, Container, Row } from "react-bootstrap";

export default function Header() {
  // const { tokens } = useTheme();
  return (
    <Container className="sbHeader">
      <Row style={{padding: "0px", alignItems: "center"}}>
        <Col xs="12" lg="auto">
          <Image className="company-logo-image" src="/newsblogo.png" alt="Smart Barn Logo"></Image>
        </Col>
      </Row>
    </Container>
  );
}
  

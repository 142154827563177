import React from "react";
import { Auth } from "aws-amplify";
// TODO: deprecate this whole file
class CurrentAccountInfo extends React.Component {
  static username = null;
  static userKey = null;
  static currentAccount = null;
  static mainAccounts = null;
  static authorizationLevel = null;
  static allAccounts = null;
  static timezone = null;
  static currentCompanyName = null;
  static mainCompanyName = null;
  static network = "All Networks";
  static homePageDisplay = "sensors";

  getUserAuthentication() {
    // console.log("getting user auth");
    Auth.currentAuthenticatedUser()
      .then((user) => this.handleUser(user))
      .catch(() => console.log("User not logged in"));
  }

  handleUser(user) {
    CurrentAccountInfo.username = user.username;
    localStorage.setItem("username", user.username);
    CurrentAccountInfo.userKey = user.userDataKey;
    localStorage.setItem("userKey", user.userDataKey);
    localStorage.setItem("userId")
  }

  updateUser(data) {
    var accounts = data.data.getAccountUser.accounts.split("|");
    CurrentAccountInfo.currentAccount = accounts[0];
    localStorage.setItem("currentAccount", accounts[0]);
    CurrentAccountInfo.allAccounts = accounts.slice(0, accounts.length - 1);
    CurrentAccountInfo.mainAccounts = CurrentAccountInfo.allAccounts;
    localStorage.setItem("allAccounts", data.data.getAccountUser.accounts);
    localStorage.setItem("mainAccounts", data.data.getAccountUser.accounts);
    CurrentAccountInfo.authorizationLevel = data.data.getAccountUser.permissions;
    localStorage.setItem("authorizationLevel", data.data.getAccountUser.permissions);
    localStorage.setItem("proxyStatus", "unactive");
    // DataBase.getAccount(accounts[0]).then((data) => this.parseAccountData(data, accounts.slice(0, accounts.length - 1)));
    if (CurrentAccountInfo.authorizationLevel <= 1) {
    } else {
      this.setState({ account: CurrentAccountInfo.mainAccounts[0].id });
    }
  }
}

export default CurrentAccountInfo;

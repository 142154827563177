import React, { useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import "./App.css";
import "react-bootstrap";

import AppRoutes from "./AppRoutes";

import Container from 'react-bootstrap/Container'

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from "react-bootstrap/Spinner";
import Snackbar from '@mui/material/Snackbar';

import { BrowserRouter } from "react-router-dom";

import awsmobile from "./aws-exports";
import Footer from "./Footer";
import useLoadUserInfo from "./hooks/useLoadUserInfo";
import useLoadAccountInfo from "./hooks/useLoadAccountInfo";
import useLoadAllUserAccounts from "./hooks/useLoadAllUserAccounts";
import useLoadAllNetworks from "./hooks/useLoadAllNetworks";
import useLoadSensors from "./hooks/useLoadSensors";
import useLoadGateways from "./hooks/useLoadGateways";
import useCheckForLinkedAccounts from "./hooks/useCheckForLinkedAccounts";
import useLoadAllNotifications from "./hooks/useLoadAllNotifications";


Amplify.configure(awsmobile);
Auth.configure(awsmobile);


function App ( {isPassedToWithAuthenticator, signIn, signOut, user }) {
  // const [selectedNetwork, setSelectedNetwork] = useState(localStorage.getItem("selectedNetwork") || "All Networks");
  const [newContentFlag, setNewContentFlag] = useState(false);
  // TODO: pull all of this out of App.js and put in own component.
  // chain of promises using (function).then... first Auth => then load all users => then load all networks => then all sensors => then allGateways.
  // it's a bit more verbose than I'd like but whatever. I set these up as separate methods because it was getting hard to follow in componentDidMount.
  console.log("APP.JS renders", user.username)
  const userId = user.username;
  const userInfo = useLoadUserInfo(userId);
  const linkedAccounts = useCheckForLinkedAccounts(userId);
  const currentAccount = localStorage.getItem('currentAccount');
  const accountInfo = useLoadAccountInfo(currentAccount);
  const allUsers = useLoadAllUserAccounts(currentAccount);
  const allNetworks = useLoadAllNetworks(currentAccount);
  const allSensors = useLoadSensors(currentAccount);
  const allGateways = useLoadGateways(currentAccount);
  const allNotifications = useLoadAllNotifications(currentAccount);

  if(!allSensors) {
    return (
      <Container fluid style={{ maxWidth: "960px", minHeight:"500px" }}>
        <Container className="justify-content-center" style={{paddingTop:'50px', width:"100px"}}><Spinner animation="border" /></Container>
      </Container>
    );
  }
  
  if(currentAccount === "NO ACCOUNT") {
    return (
      <Container fluid style={{ maxWidth: "960px" }}>
        <h2>ACCOUNT ERROR. PLEASE CONTACT SUPPORT AT 937-697-1890 OR CONTACT@SMARTBARN.IO</h2>
      </Container>
    );
  }

  const handleCloseSnackBar = (event, reason) => {
    console.log("HANDLE CLOSE!!")
    if (reason === 'clickaway') {
      return;
    }
    setNewContentFlag(false);
  };

  const handleForceSWUpdate = () => {
    forceSWupdate()
    .then(
      localStorage.setItem('newContentFlag', false),
      window.location.reload()
    );
  }

  const newContentFlagAction = (
    <>
      <Button color="secondary" size="small" onClick={() => handleForceSWUpdate()}>
        UPDATE
      </Button>

    <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBar}
      >
      
        <CloseIcon fontSize="small" />
    </IconButton>
    </>
  )

  return (
    <>
      <Container fluid style={{ maxWidth:"960px", padding:"0px" }}>
        <BrowserRouter>
          <AppRoutes 
            accountId={currentAccount}
            userId={ userId } 
            allUsers={ allUsers } 
            allNetworks={ allNetworks } 
            allSensors={ allSensors || []} 
            allGateways={ allGateways || [] }
            allNotifications={allNotifications || []}
            accountInfo={ accountInfo }
            linkedAccounts={ linkedAccounts }
          />
        </BrowserRouter>
      </Container>
      <Footer />
      <Snackbar
        open={newContentFlag}
        onClose={handleCloseSnackBar}
        message="App updates are available!"
        action={newContentFlagAction}
      />
    </>
  );
}

const services = {
  async handleSignIn(formData) {
    let { username, password } = formData;
    // custom username
    username = username.toLowerCase();
    return Auth.signIn({
      username,
      password,
    });
  },
  
  async handleSignUp(formData) {
    let { username, password, attributes } = formData;
    username = username.toLowerCase();
    attributes.email = attributes.email.toLowerCase();
    return Auth.signUp({
      username,
      password,
      attributes
    });
  }
};

// THIS WORKS BUT IS FUGLY AS HELL. TODO: I don't think it works.
async function forceSWupdate() {
  console.log("FORCING SERVICE WORKER UPDATE")
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister()
      }
    })
  }
}

export default withAuthenticator(App, {
  variation: "default",
  services: services,
  hideSignUp: true
});

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}
import React, {useState} from "react";
import { Container } from 'react-bootstrap'
import { DataGrid } from '@mui/x-data-grid';

export default function ManageDevices (props) {
  const handleCheckBox = (event) => {
    props.handleSelect(event);
  }

  if(!props.allDevices) {
    return(
      null
    );
  }
  // this is a filtering function used by the const filteredList below
  const filterItems = (arr, query) => {
    return arr.filter(el => el.networkId == query); // using == instead of === to broaden match, sensor.networkId is int, and query is string.
  }

  // filters the list of devices down to just the devices in the selected network
  const filteredList = filterItems(props.allDevices, props.selectedNetwork);
  
  // get the networks dropdown filter -> todo put in componentDidMount to prevent re-renders over and over    
  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'sensorCode', headerName: 'Code', width: 80 },
    { field: 'version', headerName: 'Type', width: 200 },
    { field: 'name', headerName: 'Device Name', width: 300 },
    { field: 'monnitGeneration', headerName: '', width: 70 },
    { field: 'firmwareVersion', headerName: '', }
  ];
  
  return(
    <>
      <Container style={{paddingBottom:50, paddingLeft: 0, paddingRight: 0}} >
        <DeviceTable rows={filteredList} columns={columns} checkBox={(event) => handleCheckBox(event)} />
      </Container>
    </>
  )
}

function DeviceTable(props) {
  const [selectionModel, setSelectionModel] = useState([]);
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
          rows={props.rows}
          columns={props.columns}
          density="compact"

          // pageSize={5}
          // rowsPerPageOptions={[5]}
          checkboxSelection
          // disableSelectionOnClick
          autoHeight
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
            props.checkBox(newSelectionModel);
          }}
          selectionModel={selectionModel}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}
          
        />
    </div>
  );
}

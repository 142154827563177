import React, { useState, useEffect } from "react";
import format from 'date-fns/format';

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Spinner from 'react-bootstrap/Spinner'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { startOfDay } from 'date-fns'
import DeleteDeviceModal from "../DeleteDeviceModal.jsx";
import { GetSensorMessagesByDay, DeleteSensor } from "../../Database.jsx";
import DatePicker from "../DatePicker.jsx";

import { DataGrid, GridToolbarExport, GridToolbarContainer, gridClasses } from '@mui/x-data-grid';

import BatteryBackupEdit from "./BatteryBackupEdit.jsx";
import BatteryBackupNotificationTab from './BatteryBackupNotificationTab.jsx';

export default function BatteryBackupBody(props) {

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [tabKey, setTabKey] = useState('history');
  const [sensorMessages, setSensorMessages] = useState([]);
  // const [deleteButton, setDeleteButton] = useState(true);
  
  const handleSensorMessages = (data) => {
    setSensorMessages(sensorMessages => [...sensorMessages, ...data]);
  }
  
  const [startDate, setStartDate] = useState(startOfDay(new Date(new Date().setDate(new Date().getDate()-1))));
  const [endDate, setEndDate] = useState(new Date());

  const [result, setResult] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleStartDateChange = (e) => {
    // console.log("Start date", e)
    setStartDate(e);
    setIsLoaded(false);
    // setDateRange[0] = e;
  }

  const handleEndDateChange = (e) => {
    setEndDate(e);
    setIsLoaded(false);
    // setDateRange[1] = e;
  }

  const handleDeleteSensor = () => {
      DeleteSensor(props.id)
      .then(
        (result) => {
          alert("Sensor deleted!"); //TODO: alert popover
          setResult(result);
          window.location.reload();
      },
      (error) => {
        setError(error);
        alert("Error deleting sensor");
      }
    );
  }

  const columns = [
    { field: 'date', headerName: 'Date', width: '150', valueFormatter: ({ value }) => format(Date.parse(value), 'MM/dd/yyyy h:mma') },
    { field: 'data', width: '150',
      valueFormatter: ({value}) => GetLastSensorMessageForTable(value, props.version, props.scaleValue1, props.scaleValue2)},
  ];

  useEffect(() => {
    const getMoreSensorMessages = (nextToken) => {
      GetSensorMessagesByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), nextToken, 1440)
      .then(
        (result) => {
          // console.log("ListFirstSensorMessagesBodyNextToken", result)
          console.log((startDate/1000).toFixed(0) + " " + (result.data.listSensorMessagesByDay.items[0].date));
          handleSensorMessages(result.data.listSensorMessagesByDay.items);
          if(result.data.listSensorMessagesByDay.nextToken) {
            getMoreSensorMessages(result.data.listSensorMessagesByDay.nextToken);
          }
          else {
            setIsLoaded(true)
          }
        }, error => {
          setIsLoaded(true)
          setError(error)
        }
      );
    }
    // console.log("StartDate", (startDate/1000).toFixed());
    GetSensorMessagesByDay(props.id, (startDate/1000).toFixed(0), (endDate/1000).toFixed(0), null, 1440)
    .then(
      (result) => {
        // console.log("ListFirstSensorMessagesBody", result)
        setSensorMessages(result.data.listSensorMessagesByDay.items);
        if(result.data.listSensorMessagesByDay.nextToken) {
          getMoreSensorMessages(result.data.listSensorMessagesByDay.nextToken);
        }
        else {
          setIsLoaded(true)
        }
      }, error => {
        setIsLoaded(true)
        setError(error)
        
      }
    );
  }, [startDate, endDate, props.id]);
  return (
        <Tabs
          id="controlled-tab"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
          className="mb-3"
        >
          <Tab eventKey="history" title="History">
            <Container fluid style={{ height: '600', width: '100%' }}>
              <DatePicker endDate={endDate} startDate={startDate} handleStartDateChange={(date) => handleStartDateChange(date)} handleEndDateChange={(date) => handleEndDateChange(date)}/>
              {(isLoaded && tabKey === 'history') ? 
              <DataGrid 
                 components={{
                  Toolbar: CustomToolbar,
                }}
                pageSize={15} 
                rows={sensorMessages} 
                rowHeight={25} 
                columns={columns} 
                getRowId={(row) => row.timestamp}
                rowsPerPageOptions={[15]}
                autoHeight
                disableSelectionOnClick
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
              /> : 
              <Container className="justify-content-center" style={{width:"100px"}}><Spinner animation="border" /></Container> }
            </Container>
          </Tab>
          
          <Tab eventKey="edit" title="Edit">
            <Container fluid style={{paddingTop:"0px"}}>
            <Button variant="danger" onClick={() => setShowModal(true)}>
                Delete
                <DeleteForeverIcon htmlColor="black" style={{ marginRight: "5px" }} />
              </Button>
              <DeleteDeviceModal
                show={showModal}
                onHide={() => setShowModal(false)}
                name={props.name}
                handleDeleteDevice={handleDeleteSensor}
              />
              <BatteryBackupEdit 
                assignedUsers={props.assignedUsers} //TODO: FIX JSON PARSE CRAP
                key={props.id}
                account={props.account} 
                accountId={props.accountId}
                deviceId={props.id}
                deviceType={props.deviceType}
                heartbeat={props.heartbeat}
                id={props.id}
                manufacturer={props.manufacturer}
                maxThresh={props.maxThresh}
                minThresh={props.minThresh}
                message={props.message}
                name={props.name}
                networkId={props.networkId}
                notificationId={props.notificationId}
                notificationName={props.notificationName}
                notificationStatus={props.notificationStatus}
                scaleValue1={props.scaleValue1}
                scaleValue2={props.scaleValue2}
                sensorCode={props.sensorCode}
                version={props.version}
                />
            </Container>
          </Tab>
          <Tab eventKey="notifications" title="Notifications">
            <BatteryBackupNotificationTab 
                assignedUsers={props.assignedUsers} //TODO: FIX JSON PARSE CRAP
                account={props.account} 
                accountId={props.accountId}
                deviceId={props.id}
                deviceType={props.deviceType}
                heartbeat={props.heartbeat}
                id={props.id}
                manufacturer={props.manufacturer}
                maxThresh={props.maxThresh}
                maxThresh2={props.maxThresh2}
                minThresh={props.minThresh}
                minThresh2={ props.minThresh2 }
                message={props.message}
                name={props.sensorName}
                networkId={props.networkId}
                notificationId={props.notificationId}
                notificationName={props.notificationName}
                notificationPausedUntilDateTime={props.notificationPausedUntilDateTime}
                notificationStatus={props.notificationStatus}
                scaleValue1={props.scaleValue1}
                scaleValue2={props.scaleValue2}
                sensorCode={props.sensorCode}
                sensorMessage={ props.sensorMessage }
                version={props.version}
              />

          </Tab>
        </Tabs>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function GetLastSensorMessageForTable(data) {
  return data === 0 ? "Power OK" : "On Battery!"
}
import React, { useState, useEffect, useRef } from 'react';

// MUI imports
import Switch from '@mui/material/Switch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';

// bootstrap imports
import { Accordion, Col, Row, Container, Button, Tabs, Tab } from "react-bootstrap";

// smart barn imports
import { ListFirstNotifications } from "./NotificationQueries";
import NotificationEditTab from './NotificationEditTab';
import NotificationDevicesTab from './NotificationDevicesTab';
import NotificationUsersTab from './NotificationUsersTab';
import NotificationHistory from './NotificationHistory';

import { UpdateNotification } from '../Database.jsx';

import { format } from 'date-fns'

import { IOTData } from "../services.js";

//TODO: update sensor settings / status
const iotdata = IOTData();

export default function SensorNotification(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [assignedDevices, setAssignedDevices] = useState(!props.assignedDevices ? [] : props.assignedDevices);
  const [assignedUsers, setAssignedUsers] = useState(!props.assignedUsers ? [] : props.assignedUsers);
  const [tabKey, setTabKey] = useState('history');
  const [loadNotificationHistory, setLoadNotificationHistory] = useState(false);
  const [notificationName, setNotificationName] = useState(props.name);
  const [notificationStatus, setNotificationStatus] = useState(props.notificationStatus)
  const [message, setMessage] = useState(props.message);
  const [minThresh, setMinThresh] = useState(props.minThresh);
  const [minThresh2, setMinThresh2] = useState(props.minThresh2);
  const [maxThresh, setMaxThresh] = useState(props.maxThresh);
  const [maxThresh2, setMaxThresh2] = useState(props.maxThresh2)
  const [returnToNormalMessage, setReturnToNormalMessage] = useState(props.returnToNormalMessage || "<Name> has returned to normal reading");
  const [sendReturnToNormalMessage, setSendReturnToNormalMessage] = useState(props.sendReturnToNormalMessage || false);
  const [scaleValue1, setScaleValue1] = useState(props.scaleValue1)
  const [lastSentDate, setLastSentDate] = useState("N/A");
  const cmdSensorNotificationTopic = "cmd/sensor/" + props.deviceType + "/monnit/NotificationSettings";
  const didMount = useRef(false);

  const handleLoadNotificationHistory = () => {
    setLoadNotificationHistory(true);
  }

  useEffect(() => {
    ListFirstNotifications(props.id, null, 1)
    .then(
      (result) => {
        if(result.data.listFirstNotifications.items.length > 0) {
          setLastSentDate(format(new Date(result.data.listFirstNotifications.items[0].timestamp*1000), "MM/dd/yyyy h:mma"));
        }
        setIsLoaded(true)
      }, error => {
        setIsLoaded(true)
        setError(error) 
      }
    );
  });

  useEffect(() => {
    const handleToggle = () => {
      const iot_data = {
        assignedDevices: assignedDevices,
        notificationStatus: notificationStatus,
        notificationId: props.id
      }
  
      // topic structure: cmd/account-id/network-id/notificationId/sensortype/monnit/NotificationGroup
      // const topic = props.deviceType === "gateway" ? "cmd/GatewayNotificationSettings/" + props.id + "/monnit" : cmdSensorNotificationTopic;
      // changed from prev. line to this since gatewaynotifications will stay separate.
      const topic = cmdSensorNotificationTopic;
      var params = {
        topic: topic,
        payload: JSON.stringify(iot_data),
        qos: 0,
      };
  
      UpdateNotification(iot_data)
      .then(
        iotdata.publish(params, function (err, data) {
          if (err) console.log(err, err.stack);
          else {
              // alert("Notification Settings Saved!");
            }
          })
        )
    }
  // Return early, if this is the first render:
  if ( !didMount.current ) {
    didMount.current = true;
  } else {
    handleToggle();
  }   
  }, [assignedDevices, cmdSensorNotificationTopic, notificationStatus, props.id]);

  if(!isLoaded) { return <></> }
  const handleDelete = () => {
    // reset the notification states on notification toggle / updates GUI appearance based on flags
    setNotificationStatus("disarmed");
    // if the notification is toggle, it resets all the status flags of the notification as well.
    // var json_data = {
    //   "id": props.id,
    //   "notificationStatus": (checked ? "armed" : "disarmed")
    // }
    console.log("handleSwitchToggle", "disarmed");
    // updates the status in the database.

    // sensor status data for IoT core.
    const iot_data = {
      notificationId: props.id,
      deviceType: props.deviceType,
      assignedDevices: assignedDevices,
      notificationName: notificationName,
      notificationStatus: "disarmed",
      message: message,
      maxThresh: maxThresh,
      maxThresh2: maxThresh2,
      minThresh: minThresh,
      minThresh2: minThresh2,    
      pendingChange: false,
    }

    // UpdateNotification(json_data);
    // TODO: assign / send data to sensors

    // topic structure: cmd/account-id/network-id/notificationId/sensortype/monnit/NotificationGroup

    var params = {
      topic: cmdSensorNotificationTopic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    iotdata.publish(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log("response", data);
        console.log("Sent data ", iot_data);
        // alert("Notification Status Updated!");
      }
    });

    props.onDeleteNotification(props.notificationId);
  }

  // TODO: if a device is removed from the list of devices then update / remove the sensor from the notification
  
  const handleSave = () => {
    const iot_data = {
      account: props.account,
      accountId: props.account,
      assignedDevices: assignedDevices,
      assignedUsers: assignedUsers,
      deviceType: props.deviceType,
      maxThresh: maxThresh,
      maxThresh2: maxThresh2,
      minThresh: minThresh,
      minThresh2: minThresh2,
      networkId: props.networkId,
      networkName: props.networkName,
      notificationId: props.id,
      message: message,
      notificationName: notificationName,
      notificationStatus: notificationStatus,
      notificationType: props.deviceType,
      returnToNormalMessage: returnToNormalMessage,
      sendReturnToNormalMessage: sendReturnToNormalMessage,
      freezeAlarmThresh: props.freezeAlarmThresh,
      freezeAlarmStatus: props.freezeAlarmStatus
    }

    // topic structure: cmd/account-id/network-id/notificationId/sensortype/monnit/NotificationGroup
    const topic = cmdSensorNotificationTopic;
    var params = {
      topic: topic,
      payload: JSON.stringify(iot_data),
      qos: 0,
    };

    const graphql_data = {
      account: props.account,
      accountId: props.account,
      assignedDevices: assignedDevices,
      assignedUsers: assignedUsers,
      deviceType: props.deviceType,
      maxThresh: maxThresh,
      maxThresh2: maxThresh2,
      minThresh: minThresh,
      minThresh2: minThresh2,
      networkId: props.networkId,
      networkName: props.networkName,
      notificationId: props.id,
      message: message,
      notificationName: notificationName,
      notificationStatus: notificationStatus,
      notificationType: props.deviceType,
      returnToNormalMessage: returnToNormalMessage,
      scaleValue1: scaleValue1,
      sendReturnToNormalMessage: sendReturnToNormalMessage,
      freezeAlarmThresh: props.freezeAlarmThresh,
      freezeAlarmStatus: props.freezeAlarmStatus
    }

    UpdateNotification(graphql_data)
    .then(
      iotdata.publish(params, function (err, data) {
        if (err) console.log(err, err.stack);
        else {
            alert("Notification Settings Saved!");
          }
        })
      );
  }

  // TODO: fix the redundancies here, would like to use the handleSave only to simplify.
  const handleSwitchToggle = (event) => {
    // reset the notification states on notification toggle / updates GUI appearance based on flags
    // const checked = event.target.checked
    // const toggleStatus = checked ? "armed" : "disarmed";
    
    setNotificationStatus(event.target.checked ? "armed" : "disarmed");
    console.log("Checked", notificationStatus);
  }

  // set the status icon based on the state of the notification
  var notificationStatusIcon=<CheckCircleIcon htmlColor="green" />;
  // check sensors for active notifications, then set status here.
  if(notificationStatus === "disarmed") {
    notificationStatusIcon=<CancelIcon htmlColor="gray" />;
  }
  else if(notificationStatus === "alerting") {
    notificationStatusIcon=<ErrorIcon htmlColor="red" />;
  }
  else if(notificationStatus === "acknowledged") {
    notificationStatusIcon=<PauseCircleIcon htmlColor="orange" />;
  }

  return (
    <Accordion.Item key={props.id} eventKey={props.id}>
      <Container fluid style={{paddingLeft: "0px"}}>
        <Switch 
          onChange={handleSwitchToggle} 
          checked = {(notificationStatus !== "disarmed")} 
          className="dynamic-class-name"
        />
        <b style={{textAlign:"left"}}>{notificationName}</b>&nbsp;Network: {props.networkName}
        <Accordion.Header onClick={ handleLoadNotificationHistory }>
          <Col style={{padding:"0px"}}>
            <Row className="align-items-center">
              <Col xs="1" lg="auto" style={{minWidth:"10px"}}>
                <Row><Container fluid>{notificationStatusIcon}</Container></Row>
              </Col>
              <Col xs="3" lg="1" >
                <img title={props.version} 
                  className="sensor-image-column" 
                  src={(props.deviceType&&NotificationIcon(props.deviceType)) || "/Images/SensorImages/smart-barn-hex-icon.png" } 
                  alt="Sensor notification icon" 
                />
              </Col>
              <Col><Container fluid style={{textAlign: "left"}}>Last sent: {lastSentDate}</Container></Col>
            </Row>
          </Col>
        </Accordion.Header>
      </Container>
      <Accordion.Body style={{padding:"0px"}}>
      { loadNotificationHistory === true && <Tabs
          id="controlled-tab"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
          className="mb-3"
        >
          <Tab eventKey="history" title="History">
            <NotificationHistory id={ props.id } tabKey={ tabKey }/> 
          </Tab>
          <Tab eventKey="edit" title="Edit">
            <Container style={{paddingLeft: 0, paddingRight: 0}} >
              <NotificationEditTab 
                id={ props.id } 
                notificationName={ notificationName }
                setName={ setNotificationName }
                message={ message } 
                deviceType={ props.deviceType }
                maxThresh={ maxThresh }
                maxThresh2={ maxThresh2 }
                minThresh={ minThresh }
                minThresh2={ minThresh2 }
                scaleValue1={ scaleValue1 }
                scaleValue2={ props.scaleValue2 }
                setScaleValue1={ setScaleValue1 }
                sendReturnToNormalMessage={ sendReturnToNormalMessage }
                returnToNormalMessage={ returnToNormalMessage }
                setMessage={ setMessage }
                setMaxThresh={ setMaxThresh }
                setMaxThresh2={ setMaxThresh2 }
                setMinThresh={ setMinThresh }
                setMinThresh2={ setMinThresh2 }
                setSendReturnToNormalMessage={ setSendReturnToNormalMessage }
                setReturnToNormalMessage={ setReturnToNormalMessage}
              />
            </Container>
          </Tab>
          <Tab eventKey="devices" title="Devices">
            <NotificationDevicesTab
              allSensors={ props.allSensors }
              allGateways={ props.allGateways } 
              assignedDevices={ assignedDevices } 
              setAssignedDevices={ setAssignedDevices }
              deviceType = { props.deviceType }
              tabKey={ tabKey }
            />
          </Tab>
          <Tab eventKey="users" title="Users">
            <Container style={{paddingBottom:5, paddingLeft: 0, paddingRight: 0}}>
              {tabKey === "users" ? <NotificationUsersTab 
                      allUsers={ props.allUsers }
                      assignedUsers= { assignedUsers }
                      handleSetAssignedUsers={ setAssignedUsers }
                    /> : <></>}
            </Container>
          </Tab>
         
        </Tabs> }
        { tabKey === "history" ? <></> : 
          <><Button onClick={ handleSave }>Save</Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <DeleteIcon htmlColor="black" />Delete</Button>
          </> }
      </Accordion.Body>
    </Accordion.Item>
  );
}

function NotificationIcon(deviceType) {
  switch(deviceType) {
    case "gatewayInactivity": 
      return("/Images/SensorImages/Smart Barn gateway.png");
    case "Dry_Contact":
      return("/Images/SensorImages/dry_contact.png");
    case "CurrentZeroToTwentyAmp":
      return("/Images/SensorImages/current0to20.png");
    case "Open_Closed":  
      return("/Images/SensorImages/door.png");
    case "Humidity":
      return("Images/SensorImages/Humidity-blue.png");
    case "Temperature": 
      return("/Images/SensorImages/TEMPERATURE-BOX.png");
    case "Low_Temperature":
      return("Images/SensorImages/low-temp-box.png");
    case "timedVoltage":
    case "timedDryContact":
    case "timedOpenClosed":
      return("/Images/SensorImages/timed-notification-box.png");
    case "Filtered_Pulse_Counter_1_Input":
      return("Images/SensorImages/PULSE-COUNTER-BOX.png");
    case "Pulse_Counter_1_Input":
      return("Images/SensorImages/PULSE-COUNTER-BOX.png");
    case "Pressure300PSI":
      return("/Images/SensorImages/pressure.png");
    case "AC_Voltage_500":
    case "Voltage_Meter_500":
      return("Images/SensorImages/voltage_meter.png");
    case "SmartBarnDairySensor": 
      return ("/Images/SensorImages/TEMPERATURE-BOX.png");
    default:
      return ("/Images/SensorImages/smart-barn-hex-icon.png");
  }
}
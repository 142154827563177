import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import PostAddIcon from '@mui/icons-material/PostAdd';
import { IOTData } from "../services.js";
import DecodeSensorId from '../Utilities/DecodeSensorId.js';
const iotdata = IOTData();

export default function AddDeviceModal(props, form) {
  // const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = useState(false);
  
  const handleShow = () => setShow(true);
  
  /* handle sensor ID */
  const [deviceId, setDeviceId] = useState("");
  const deviceIdChange = (event) => setDeviceId(event.target.value);
  
  /* handle device code change */
  const [deviceCode, setDeviceCode] = useState("");
  const deviceCodeChange = (event) => setDeviceCode(event.target.value.toUpperCase());
  
  // TODO: selected network not propogating from parent component.
  const [networkId, setNetworkId] = useState(props.selectedNetwork);
  const [networkName, setNetworkName] = useState(props.selectedNetworkName);
  const cmdTopic = "cmd/devices/transferDevices/monnit";

  // NEW BUT NOT TESTED:
  const handleClose = () => {
    setShow(false);
    // setDeviceType("Temperature");
    setDeviceId("");
    setDeviceCode("");
    // setModemSerialNum("");
  }

  const handleSetNetwork = (e) => {
    setNetworkId(e.target.value);
    const sel = document.getElementById("select-network");
    setNetworkName(sel.options[sel.selectedIndex].text);
  }
  
  // const [validated, setValidated] = useState(false);
  // const [error, setError] = useState(null);
  // const [result, setResult] = useState(null);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(deviceCode !== DecodeSensorId(parseInt(deviceId))) {
      
      alert("Incorrect code entered!");
    }
    else {
      const iot_data = {
        devices: [deviceId],
        accountId: props.accountId,
        networkId: networkId,
        networkName: networkName
      };
      // console.log("IOT DATA: " + JSON.stringify(iot_data));
      var params = {
        topic: cmdTopic,
        payload: JSON.stringify(iot_data),
        qos: 0,
      };
      console.log(params);
      iotdata.publish(params, function (err, data) {
        if (err) console.log(err, err.stack);
        else {
          // console.log("response", data);
          // console.log("Sent data ", iot_data);
          alert("Device added!");
          // window.location.reload();
        }
      });
      handleClose();
    }
    
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <PostAddIcon />
        {props.buttonText}
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{props.buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={ props.id } onSubmit={ (e) => handleSubmit(e) }>
            {/* <AccountSelect setAccount={ handleSetAccount } />   */}
            <Form.Group className="mb-3" controlId="formNetwork">
            <Form.Label>Network</Form.Label>
            <Form.Select id="select-network" aria-label="select network" value={ networkId } onChange={ (e) => handleSetNetwork(e)} >
              {props.allNetworks.map(element => (<option key={element.id} value={element.id}>{element.name}</option>))}
            </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Device ID:</Form.Label>
                <Form.Control type="number" value={deviceId} onChange={deviceIdChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Device Code:</Form.Label>
                <Form.Control maxLength={ 6 } type="text" value={ deviceCode } onChange={deviceCodeChange} required />
            </Form.Group>
          </Form>
        </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form={props.id}>Register Device</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function NotificationEditHumidityTab(props) {

  return (
    <>
      <Row>
        <h4>Thresholds</h4>
        <Col>
          <Form.Label>min humidity %:</Form.Label>
          <Form.Control 
            type="number" 
            defaultValue={ props.minThresh2 || 0 } 
            min={ 0 }
            max={ props.maxThresh2 }
            onChange={ (e) => props.setMinThresh2(e.target.value) }
            />
        </Col>
        <Col>
          <Form.Label>max humidity %:</Form.Label>
          <Form.Control 
            type="number" 
            defaultValue={ props.maxThresh2 || 100 }
            min={ props.minThresh2 }
            max={ 100 }
            onChange={ (e) => props.setMaxThresh2(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>min temperature:</Form.Label>
          <Form.Control 
            type="number" 
            defaultValue={ props.scaleValue1 === "C" ? props.minThresh.toFixed() : ((props.minThresh*1.8)+32).toFixed() } 
            min={ -40 }
            max={props.scaleValue1 === "C" ? props.maxThresh : ((props.maxThresh*1.8)+32)}
            onChange={(e) => props.setMinThresh(props.scaleValue1 === "C" ? e.target.value : ((e.target.value-32)/1.8)) }
            />
        </Col>
        <Col>
          <Form.Label>max temperature:</Form.Label>
          <Form.Control 
          type="number" 
            defaultValue={props.scaleValue1 === "C" ? props.maxThresh.toFixed() : ((props.maxThresh*1.8)+32).toFixed() }
            min={props.scaleValue1 === "C" ? props.minThresh : ((props.minThresh*1.8)+32)}
            max={props.scaleValue1 === "C" ? 70 : 158 }
            onChange={ (e) => props.setMaxThresh(props.scaleValue1 === "C" ? e.target.value : ((e.target.value-32)/1.8))}
            />
        </Col>
      </Row>
      </>
  );
}
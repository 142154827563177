export default function CalculateBatteryPercent(batteryLevel) {
  var percentValue;
  
  if (batteryLevel <= 2.20) {
    percentValue = 0;
  } else if (batteryLevel > 2.20 && batteryLevel <= 2.55) {
    percentValue = linearInterpolation(batteryLevel, 2.20, 0, 2.55, 10);
  } else if (batteryLevel > 2.55 && batteryLevel <= 2.60) {
    percentValue = linearInterpolation(batteryLevel, 2.55, 10, 2.60, 25);
  } else if (batteryLevel > 2.60 && batteryLevel <= 2.80) {
    percentValue = linearInterpolation(batteryLevel, 2.60, 25, 2.80, 50);
  } else if (batteryLevel > 2.80 && batteryLevel <= 2.90) {
    percentValue = linearInterpolation(batteryLevel, 2.80, 50, 2.90, 75);
  } else {
    percentValue = batteryLevel > 2.9 && batteryLevel <= 3 ? linearInterpolation(batteryLevel, 2.90, 75, 3, 100.00) : 100;
  }
  return percentValue.toFixed(0);
}

function linearInterpolation(value, lowX, lowY, highX, highY) {
  var slope = (highY - lowY) / (highX - lowX);
  var intercept = slope * lowX - lowY;
  return slope * value - intercept;
}
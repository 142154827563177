import React from 'react'
import './Charts.css'

// date-fns
import 'chartjs-adapter-date-fns';
import endOfDay from 'date-fns/endOfDay'
import CurrentZeroToTwentyAmpChart from './CurrentZeroToTwentyAmpChart';
import TemperatureChart from './TemperatureChart';
import PulseChart from './PulseChart';
import HumidityChart from './HumidityChart';

// Charts JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
  TimeScale,
  Decimation
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import Container from 'react-bootstrap/Container';

// register chartsjs functions and plugins
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
  annotationPlugin,
  TimeScale,
  Decimation
);

export default function SensorChart(props) {
  if(props.version.includes("CurrentZeroToTwentyAmp")){
    return <CurrentZeroToTwentyAmpChart {...props} />;
  }
  if(props.version.includes("Temperature")){
    return <TemperatureChart {...props} />;
  }
  if(props.version.includes("Pulse")){
    return <PulseChart {...props} />;
  }
  if(props.version.includes("Humidity")){
    return <HumidityChart {...props} />;
  }
  // TODO: the other sensor types (primarily Humidity and Voltage).

  const formatData = (data, dataValues) => {
    // null check.
    if(!data){
      return data;
    }

    switch(props.version){
      case "Humidity":
        return props.scaleValue1.startsWith('F') ? (parseFloat(JSON.parse(dataValues).Temperature * 9.0/5.0) + 32.0).toFixed(1) : data;
      case "Dry_Contact":
      case "Open_Closed":
        return data === 1 ? props.scaleValue1 : props.scaleValue2;
      case "DC_Voltage_5V":
        return (data * props.scaleValue1 + props.scaleValue2*1).toFixed(1)
      default:
        return parseFloat(data);
    }
  }

  const datapoints = props.messages.map(x => (
    { x: new Date(x.timestamp*1000), y: ( formatData(x.data, x.dataValues) ) }
    )
  );

  const data = {
    datasets: [
      
      {
        datalabels: {
          labels: {
            title: null
          }
        },
        id: 1,
        label: (props.version ==="Dry_Contact" || props.version === "Open_Closed") ? props.scaleValue2 + "/" + props.scaleValue1 : props.version === 'Humidity' ? 'Temperature' : props.version + " " + props.scaleValue1,
        type: 'line',
        backgroundColor: 'rgb(116, 170, 67)',
        borderColor: 'rgb(116, 170, 67)',
        data: datapoints,
        pointRadius: 0,
        borderWidth: 2,
        animation: false, // set here and not in options? weird...
      },
    ]
  };

  if(props.version === 'Humidity') {
    const humidityDataPoints = props.messages.map(data => ({x: new Date(data.timestamp*1000), y: (data.dataValues !== null)&&JSON.parse(data.dataValues).Humidity }));
    data.datasets.push(
      {
        datalabels: {
          labels: {
            title: null
          }
        },
        id: 2,
        label: 'Humidity',
        type: 'line',
        backgroundColor: 'blue',
        borderColor: 'blue',
        data: humidityDataPoints,
        pointRadius: 0,
        borderWidth: 2,
        animation: false, // set here and not in options? weird...
        // yAxisID: 'y1'
      }
    );
  }
  const yMin = (props.version ==="Dry_Contact" || props.version === "Open_Closed") ? !props.minThresh : (props.version === "Temperature" || props.version === "Humidity") && !props.scaleValue1.startsWith('C') ? (parseFloat(props.minThresh * 9.0/5.0) + 32.0).toFixed(1) : props.minThresh;
  const yMax = (props.version ==="Dry_Contact" || props.version === "Open_Closed") ? !props.maxThresh : (props.version === "Temperature" || props.version === "Humidity") && !props.scaleValue1.startsWith('C') ? (parseFloat(props.maxThresh * 9.0/5.0) + 32.0).toFixed(1) : props.maxThresh;
  const options = {
    animation: false,
    parsing: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // decimation: decimation,
      tooltip: {
        enabled: true,
      },
      interaction: {
        mode: 'nearest'
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: yMin ,
            yMax: yMin,
            borderColor: 'red',
            borderWidth: 2,
          },
          line2: {
            type: 'line',
            yMin: yMax,
            yMax: yMax,
            borderColor: 'red',
            borderWidth: 2,
          }
        }
      }
    },
    scales: {
      x: {
        adjustScaleRange: false,
        min: props.startDate,
        max: endOfDay(props.endDate),
        type: 'time',
        time: { unit: 'day' }
      },
      y: {
        // suggestedMin: props.version === "Temperature" && props.scaleValue1.startsWith('F') ? (parseInt(props.minThresh * 9.0/5.0) + 32.0).toFixed(1) : props.version === "Temperature" ? parseInt(props.minThresh) : undefined,
        type: (props.version ==="Dry_Contact" || props.version === "Open_Closed") ? 'category' : 'linear',
        labels: (props.version ==="Dry_Contact" || props.version === "Open_Closed") ? [props.scaleValue2, props.scaleValue1] : null,
        suggestedMax: props.version === 'Humidity' ? 100 : undefined
      }
    }
  }

  return(
    <Container fluid className='sensor-charts' >
      <Line data = {
          data
        } 
        options = {
            options 
        }
        key={ props.id }
        />
    </Container>
  );
}
import React, {useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { UpdateAccountUser } from '../Database.jsx';
import UserInfoForm from './UserInfoForm.jsx';

export default function AccountUserEditModal(props, form) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [validated, setValidated] = useState(false);
  const [cellPhoneProvider, setCellPhoneProvider] = useState(props.cellPhoneProvider);
  const [cellPhoneProviderData, setCellPhoneProviderData] = useState(props.cellPhoneProviderData);
  const [useEmailToText, setUseEmailToText] = useState(props.useEmailToText || false);
  const [emailToTextAddress, setEmailToTextAddress] = useState(props.emailToTextAddress || "");
  const handleClose = () => props.setShowEditModal(false);

  const handleSubmit = (event) => {
    let formdata = event.target;
    const emailAddress = formdata.formEmail.value;
    // welp this is all useless bc of our stupid user creation script...
    const accountData = {
      "username": props.userId,
      "email": emailAddress,
      "userId": props.userId,
      "firstName": formdata.formFirstName.value,
      "lastName": formdata.formLastName.value,
      "permissions": formdata.formPermissions.value,
      "accountId": localStorage.getItem("currentAccount"),
      "accounts": localStorage.getItem("currentAccount") + "|", /**deprecate ASAP */
      "parentAccountId": "c4bd7b20-3bab-11ea-a975-0523f21e6d1f",
      "accountName": localStorage.getItem("currentCompanyName"),
      // "timezone": formdata.formTimezone.value, //TODO: URGENT get timezone from accountData
      "notes": formdata.formNotes.value,
      "dateCreated": Date.now(),
      "cellPhone": formdata.formCellPhone.value,
      "cellPhoneProvider": formdata.formCellPhoneProvider.value,
      "cellPhoneProviderData": cellPhoneProviderData,
      "emailToTextAddress": emailToTextAddress,
      "useEmailToText": useEmailToText,
      "landLine": formdata.formLandLine.value
    }
    // console.log("EVENT " +  JSON.stringify(accountData));
    event.preventDefault();
    UpdateAccountUser(accountData)
      .then(
        (result) => {
          setSuccess(result);
          alert("User updated successfully!");
          window.location.reload();
          handleClose();
        },
        (error) => {
          setError(error);
          alert("Error updating user: ", error);
          // window.location.reload();
        }
      );
    setValidated(true);
  };


  return (
    <>
      <Modal show={props.showEditModal} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>{props.buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserInfoForm 
            handleSubmit={handleSubmit} 
            id={props.id} accountName={props.accountName} 
            firstName={props.firstName} 
            lastName={props.lastName} 
            email={props.email} 
            notes={props.notes}
            cellPhone={props.cellPhone}
            cellPhoneProvider={cellPhoneProvider}
            setCellPhoneProvider={setCellPhoneProvider}
            cellPhoneProviderData={cellPhoneProviderData}
            setCellPhoneProviderData={setCellPhoneProviderData}
            landLine={props.landLine}
            useEmailToText={useEmailToText}
            emailToTextAddress={emailToTextAddress}
            setEmailToTextAddress={setEmailToTextAddress}
            setUseEmailToText={setUseEmailToText}
            />
          </Modal.Body> {/* pass in handleSubmit to the form as a callback */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" form={props.id}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}